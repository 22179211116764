/*!
 * Calcite Web - Calcite Design Components in CSS, JS and HTML
 * @version v1.2.5
 * @license Apache-2.0
 * @copyright 2018 Esri
 * @link https://github.com/Esri/calcite-web
 */
html {
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  min-height: 100%;
  font-size: 100%;
  overflow-y: auto;
}

body {
  min-height: 100vh;
  margin: 0;
}

article, aside, details, figcaption, figure, footer, header, hgroup, nav, section, summary {
  display: block;
}

audio, canvas, video {
  display: inline-block;
}

audio:not([controls]) {
  height: 0;
  display: none;
}

[hidden] {
  display: none;
}

svg:not(:root) {
  overflow: hidden;
}

video, img {
  max-width: 100%;
  height: auto;
}

.ie main {
  display: block;
}

.ie select {
  padding-right: 8px;
}

.clearfix:after {
  content: "";
  clear: both;
  display: table;
}

.overflow-auto {
  overflow: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-scroll {
  overflow: scroll;
}

.inline-block {
  display: inline-block;
}

.column-1, .column-2, .column-3, .column-4, .column-5, .column-6, .column-7, .column-8, .column-9, .column-10, .column-11, .column-12, .column-13, .column-14, .column-15, .column-16, .column-17, .column-18, .column-19, .column-20, .column-21, .column-22, .column-23, .column-24 {
  box-sizing: border-box;
  float: left;
  width: 1440px;
  max-width: 100%;
  padding-left: .5rem;
  padding-right: .5rem;
}

html[dir="rtl"] .column-1, html[dir="rtl"] .column-2, html[dir="rtl"] .column-3, html[dir="rtl"] .column-4, html[dir="rtl"] .column-5, html[dir="rtl"] .column-6, html[dir="rtl"] .column-7, html[dir="rtl"] .column-8, html[dir="rtl"] .column-9, html[dir="rtl"] .column-10, html[dir="rtl"] .column-11, html[dir="rtl"] .column-12, html[dir="rtl"] .column-13, html[dir="rtl"] .column-14, html[dir="rtl"] .column-15, html[dir="rtl"] .column-16, html[dir="rtl"] .column-17, html[dir="rtl"] .column-18, html[dir="rtl"] .column-19, html[dir="rtl"] .column-20, html[dir="rtl"] .column-21, html[dir="rtl"] .column-22, html[dir="rtl"] .column-23, html[dir="rtl"] .column-24 {
  float: right;
}

html:not([dir="rtl"]) [class*="column-"] [class*="column-"]:first-of-type {
  margin-left: -.5rem;
}

html[dir="rtl"] [class*="column-"] [class*="column-"]:first-of-type, html:not([dir="rtl"]) [class*="column-"] [class*="column-"]:last-of-type {
  margin-right: -.5rem;
}

html[dir="rtl"] [class*="column-"] [class*="column-"]:last-of-type {
  margin-left: -.5rem;
}

[class*="column-"]:last-of-type:after {
  content: "";
  clear: both;
  display: table;
}

@media screen {
  .grid-container {
    box-sizing: border-box;
    width: 1440px;
    max-width: 96vw;
    margin-left: auto;
    margin-right: auto;
    padding-left: 0;
    padding-right: 0;
  }

  .grid-container:after {
    content: "";
    clear: both;
    display: table;
  }

  html:not([dir="rtl"]) .first-column {
    margin-left: -.5rem;
  }

  html[dir="rtl"] .first-column, html:not([dir="rtl"]) .last-column {
    margin-right: -.5rem;
  }

  html[dir="rtl"] .last-column {
    margin-left: -.5rem;
  }
}

.column-1 {
  width: 60px;
  max-width: 4vw;
}

.column-1 > .column-1 {
  margin-left: -.5rem;
  margin-right: -.5rem;
}

.column-2 {
  width: 120px;
  max-width: 8vw;
}

.column-2 > .column-2 {
  margin-left: -.5rem;
  margin-right: -.5rem;
}

.column-3 {
  width: 180px;
  max-width: 12vw;
}

.column-3 > .column-3 {
  margin-left: -.5rem;
  margin-right: -.5rem;
}

.column-4 {
  width: 240px;
  max-width: 16vw;
}

.column-4 > .column-4 {
  margin-left: -.5rem;
  margin-right: -.5rem;
}

.column-5 {
  width: 300px;
  max-width: 20vw;
}

.column-5 > .column-5 {
  margin-left: -.5rem;
  margin-right: -.5rem;
}

.column-6 {
  width: 360px;
  max-width: 24vw;
}

.column-6 > .column-6 {
  margin-left: -.5rem;
  margin-right: -.5rem;
}

.column-7 {
  width: 420px;
  max-width: 28vw;
}

.column-7 > .column-7 {
  margin-left: -.5rem;
  margin-right: -.5rem;
}

.column-8 {
  width: 480px;
  max-width: 32vw;
}

.column-8 > .column-8 {
  margin-left: -.5rem;
  margin-right: -.5rem;
}

.column-9 {
  width: 540px;
  max-width: 36vw;
}

.column-9 > .column-9 {
  margin-left: -.5rem;
  margin-right: -.5rem;
}

.column-10 {
  width: 600px;
  max-width: 40vw;
}

.column-10 > .column-10 {
  margin-left: -.5rem;
  margin-right: -.5rem;
}

.column-11 {
  width: 660px;
  max-width: 44vw;
}

.column-11 > .column-11 {
  margin-left: -.5rem;
  margin-right: -.5rem;
}

.column-12 {
  width: 720px;
  max-width: 48vw;
}

.column-12 > .column-12 {
  margin-left: -.5rem;
  margin-right: -.5rem;
}

.column-13 {
  width: 780px;
  max-width: 52vw;
}

.column-13 > .column-13 {
  margin-left: -.5rem;
  margin-right: -.5rem;
}

.column-14 {
  width: 840px;
  max-width: 56vw;
}

.column-14 > .column-14 {
  margin-left: -.5rem;
  margin-right: -.5rem;
}

.column-15 {
  width: 900px;
  max-width: 60vw;
}

.column-15 > .column-15 {
  margin-left: -.5rem;
  margin-right: -.5rem;
}

.column-16 {
  width: 960px;
  max-width: 64vw;
}

.column-16 > .column-16 {
  margin-left: -.5rem;
  margin-right: -.5rem;
}

.column-17 {
  width: 1020px;
  max-width: 68vw;
}

.column-17 > .column-17 {
  margin-left: -.5rem;
  margin-right: -.5rem;
}

.column-18 {
  width: 1080px;
  max-width: 72vw;
}

.column-18 > .column-18 {
  margin-left: -.5rem;
  margin-right: -.5rem;
}

.column-19 {
  width: 1140px;
  max-width: 76vw;
}

.column-19 > .column-19 {
  margin-left: -.5rem;
  margin-right: -.5rem;
}

.column-20 {
  width: 1200px;
  max-width: 80vw;
}

.column-20 > .column-20 {
  margin-left: -.5rem;
  margin-right: -.5rem;
}

.column-21 {
  width: 1260px;
  max-width: 84vw;
}

.column-21 > .column-21 {
  margin-left: -.5rem;
  margin-right: -.5rem;
}

.column-22 {
  width: 1320px;
  max-width: 88vw;
}

.column-22 > .column-22 {
  margin-left: -.5rem;
  margin-right: -.5rem;
}

.column-23 {
  width: 1380px;
  max-width: 92vw;
}

.column-23 > .column-23 {
  margin-left: -.5rem;
  margin-right: -.5rem;
}

.column-24 {
  width: 1440px;
  max-width: 96vw;
}

.column-24 > .column-24 {
  margin-left: -.5rem;
  margin-right: -.5rem;
}

@media screen and (max-width: 859px) {
  .column-1 {
    width: 120px;
    max-width: 8vw;
  }

  .column-2 {
    width: 240px;
    max-width: 16vw;
  }

  .column-3 {
    width: 360px;
    max-width: 24vw;
  }

  .column-4 {
    width: 480px;
    max-width: 32vw;
  }

  .column-5 {
    width: 600px;
    max-width: 40vw;
  }

  .column-6 {
    width: 720px;
    max-width: 48vw;
  }

  .column-7 {
    width: 840px;
    max-width: 56vw;
  }

  .column-8 {
    width: 960px;
    max-width: 64vw;
  }

  .column-9 {
    width: 1080px;
    max-width: 72vw;
  }

  .column-10 {
    width: 1200px;
    max-width: 80vw;
  }

  .column-11 {
    width: 1320px;
    max-width: 88vw;
  }

  .column-12, .column-13, .column-14, .column-15, .column-16, .column-17, .column-18, .column-19, .column-20, .column-21, .column-22, .column-23, .column-24 {
    width: 1440px;
    max-width: 96vw;
  }

  .tablet-column-1 {
    width: 120px;
    max-width: 8vw;
  }

  .tablet-column-2 {
    width: 240px;
    max-width: 16vw;
  }

  .tablet-column-3 {
    width: 360px;
    max-width: 24vw;
  }

  .tablet-column-4 {
    width: 480px;
    max-width: 32vw;
  }

  .tablet-column-5 {
    width: 600px;
    max-width: 40vw;
  }

  .tablet-column-6 {
    width: 720px;
    max-width: 48vw;
  }

  .tablet-column-7 {
    width: 840px;
    max-width: 56vw;
  }

  .tablet-column-8 {
    width: 960px;
    max-width: 64vw;
  }

  .tablet-column-9 {
    width: 1080px;
    max-width: 72vw;
  }

  .tablet-column-10 {
    width: 1200px;
    max-width: 80vw;
  }

  .tablet-column-11 {
    width: 1320px;
    max-width: 88vw;
  }

  .tablet-column-12 {
    width: 1440px;
    max-width: 96vw;
  }

  html:not([dir="rtl"]) .tablet-first-column {
    margin-left: -.5rem;
  }

  html[dir="rtl"] .tablet-first-column, html:not([dir="rtl"]) .tablet-last-column {
    margin-right: -.5rem;
  }

  html[dir="rtl"] .tablet-last-column {
    margin-left: -.5rem;
  }
}

@media screen and (max-width: 479px) {
  .column-1 {
    width: 240px;
    max-width: 16vw;
  }

  .column-2 {
    width: 480px;
    max-width: 32vw;
  }

  .column-3 {
    width: 720px;
    max-width: 48vw;
  }

  .column-4 {
    width: 960px;
    max-width: 64vw;
  }

  .column-5 {
    width: 1200px;
    max-width: 80vw;
  }

  .column-6, .column-7, .column-8, .column-9, .column-10, .column-11, .column-12, .column-13, .column-14, .column-15, .column-16, .column-17, .column-18, .column-19, .column-20, .column-21, .column-22, .column-23, .column-24 {
    width: 1440px;
    max-width: 96vw;
  }

  .tablet-column-1 {
    width: 240px;
    max-width: 16vw;
  }

  .tablet-column-2 {
    width: 480px;
    max-width: 32vw;
  }

  .tablet-column-3 {
    width: 720px;
    max-width: 48vw;
  }

  .tablet-column-4 {
    width: 960px;
    max-width: 64vw;
  }

  .tablet-column-5 {
    width: 1200px;
    max-width: 80vw;
  }

  .tablet-column-6, .tablet-column-7, .tablet-column-8, .tablet-column-9, .tablet-column-10, .tablet-column-11, .tablet-column-12, .tablet-column-13, .tablet-column-14, .tablet-column-15, .tablet-column-16, .tablet-column-17, .tablet-column-18, .tablet-column-19, .tablet-column-20, .tablet-column-21, .tablet-column-22, .tablet-column-23, .tablet-column-24 {
    width: 1440px;
    max-width: 96vw;
  }

  .phone-column-1 {
    width: 240px;
    max-width: 16vw;
  }

  .phone-column-2 {
    width: 480px;
    max-width: 32vw;
  }

  .phone-column-3 {
    width: 720px;
    max-width: 48vw;
  }

  .phone-column-4 {
    width: 960px;
    max-width: 64vw;
  }

  .phone-column-5 {
    width: 1200px;
    max-width: 80vw;
  }

  .phone-column-6 {
    width: 1440px;
    max-width: 96vw;
  }

  .phone-first-column {
    margin-left: -.5rem;
  }

  html[dir="rtl"] .phone-first-column {
    margin-left: 0;
    margin-right: -.5rem;
  }

  .phone-last-column {
    margin-right: -.5rem;
  }

  html[dir="rtl"] .phone-last-column {
    margin-left: -.5rem;
    margin-right: 0;
  }
}

.center-column {
  float: none;
  margin-left: auto !important;
  margin-right: auto !important;
}

@media screen and (min-width: 860px) and (max-width: 1500px) {
  html:not([dir="rtl"]) .pre-0, html:not([dir="rtl"]) [class*="column-"] .pre-0[class*="column-"]:first-of-type {
    margin-left: 0;
  }

  html[dir="rtl"] [class*="column-"] .pre-0[class*="column-"]:first-of-type, html[dir="rtl"] .pre-0 {
    margin-right: 0;
  }

  html:not([dir="rtl"]) .first-column.pre-0, html:not([dir="rtl"]) [class*="column-"] .first-column.pre-0[class*="column-"]:first-of-type {
    margin-left: -.5rem;
  }

  html[dir="rtl"] [class*="column-"] .first-column.pre-0[class*="column-"]:first-of-type, html[dir="rtl"] .first-column.pre-0 {
    margin-right: -.5rem;
  }

  html:not([dir="rtl"]) .post-0, html:not([dir="rtl"]) [class*="column-"] .post-0[class*="column-"]:first-of-type, html[dir="rtl"] [class*="column-"] .post-0[class*="column-"]:first-of-type {
    margin-right: 0;
  }

  html[dir="rtl"] .post-0 {
    margin-left: 0;
  }

  html:not([dir="rtl"]) [class*="column-"] [class*="column-"].pre-0:first-of-type, html:not([dir="rtl"]) [class*="column-"] [class*="column-"] [class*="column-"].pre-0:first-of-type[class*="column-"]:first-of-type {
    margin-left: -.5rem;
  }

  html[dir="rtl"] [class*="column-"] [class*="column-"] [class*="column-"].pre-0:first-of-type[class*="column-"]:first-of-type, html[dir="rtl"] [class*="column-"] [class*="column-"].pre-0:first-of-type {
    margin-right: -.5rem;
  }

  html:not([dir="rtl"]) .pre-1, html:not([dir="rtl"]) [class*="column-"] .pre-1[class*="column-"]:first-of-type {
    margin-left: 4vw;
  }

  html[dir="rtl"] [class*="column-"] .pre-1[class*="column-"]:first-of-type, html[dir="rtl"] .pre-1 {
    margin-right: 4vw;
  }

  html:not([dir="rtl"]) .first-column.pre-1, html:not([dir="rtl"]) [class*="column-"] .first-column.pre-1[class*="column-"]:first-of-type {
    margin-left: calc(4vw - .5rem);
  }

  html[dir="rtl"] [class*="column-"] .first-column.pre-1[class*="column-"]:first-of-type, html[dir="rtl"] .first-column.pre-1 {
    margin-right: calc(4vw - .5rem);
  }

  html:not([dir="rtl"]) .post-1, html:not([dir="rtl"]) [class*="column-"] .post-1[class*="column-"]:first-of-type, html[dir="rtl"] [class*="column-"] .post-1[class*="column-"]:first-of-type {
    margin-right: 4vw;
  }

  html[dir="rtl"] .post-1 {
    margin-left: 4vw;
  }

  html:not([dir="rtl"]) [class*="column-"] [class*="column-"].pre-1:first-of-type, html:not([dir="rtl"]) [class*="column-"] [class*="column-"] [class*="column-"].pre-1:first-of-type[class*="column-"]:first-of-type {
    margin-left: calc(4vw - .5rem);
  }

  html[dir="rtl"] [class*="column-"] [class*="column-"] [class*="column-"].pre-1:first-of-type[class*="column-"]:first-of-type, html[dir="rtl"] [class*="column-"] [class*="column-"].pre-1:first-of-type {
    margin-right: calc(4vw - .5rem);
  }

  html:not([dir="rtl"]) .pre-2, html:not([dir="rtl"]) [class*="column-"] .pre-2[class*="column-"]:first-of-type {
    margin-left: 8vw;
  }

  html[dir="rtl"] [class*="column-"] .pre-2[class*="column-"]:first-of-type, html[dir="rtl"] .pre-2 {
    margin-right: 8vw;
  }

  html:not([dir="rtl"]) .first-column.pre-2, html:not([dir="rtl"]) [class*="column-"] .first-column.pre-2[class*="column-"]:first-of-type {
    margin-left: calc(8vw - .5rem);
  }

  html[dir="rtl"] [class*="column-"] .first-column.pre-2[class*="column-"]:first-of-type, html[dir="rtl"] .first-column.pre-2 {
    margin-right: calc(8vw - .5rem);
  }

  html:not([dir="rtl"]) .post-2, html:not([dir="rtl"]) [class*="column-"] .post-2[class*="column-"]:first-of-type, html[dir="rtl"] [class*="column-"] .post-2[class*="column-"]:first-of-type {
    margin-right: 8vw;
  }

  html[dir="rtl"] .post-2 {
    margin-left: 8vw;
  }

  html:not([dir="rtl"]) [class*="column-"] [class*="column-"].pre-2:first-of-type, html:not([dir="rtl"]) [class*="column-"] [class*="column-"] [class*="column-"].pre-2:first-of-type[class*="column-"]:first-of-type {
    margin-left: calc(8vw - .5rem);
  }

  html[dir="rtl"] [class*="column-"] [class*="column-"] [class*="column-"].pre-2:first-of-type[class*="column-"]:first-of-type, html[dir="rtl"] [class*="column-"] [class*="column-"].pre-2:first-of-type {
    margin-right: calc(8vw - .5rem);
  }

  html:not([dir="rtl"]) .pre-3, html:not([dir="rtl"]) [class*="column-"] .pre-3[class*="column-"]:first-of-type {
    margin-left: 12vw;
  }

  html[dir="rtl"] [class*="column-"] .pre-3[class*="column-"]:first-of-type, html[dir="rtl"] .pre-3 {
    margin-right: 12vw;
  }

  html:not([dir="rtl"]) .first-column.pre-3, html:not([dir="rtl"]) [class*="column-"] .first-column.pre-3[class*="column-"]:first-of-type {
    margin-left: calc(12vw - .5rem);
  }

  html[dir="rtl"] [class*="column-"] .first-column.pre-3[class*="column-"]:first-of-type, html[dir="rtl"] .first-column.pre-3 {
    margin-right: calc(12vw - .5rem);
  }

  html:not([dir="rtl"]) .post-3, html:not([dir="rtl"]) [class*="column-"] .post-3[class*="column-"]:first-of-type, html[dir="rtl"] [class*="column-"] .post-3[class*="column-"]:first-of-type {
    margin-right: 12vw;
  }

  html[dir="rtl"] .post-3 {
    margin-left: 12vw;
  }

  html:not([dir="rtl"]) [class*="column-"] [class*="column-"].pre-3:first-of-type, html:not([dir="rtl"]) [class*="column-"] [class*="column-"] [class*="column-"].pre-3:first-of-type[class*="column-"]:first-of-type {
    margin-left: calc(12vw - .5rem);
  }

  html[dir="rtl"] [class*="column-"] [class*="column-"] [class*="column-"].pre-3:first-of-type[class*="column-"]:first-of-type, html[dir="rtl"] [class*="column-"] [class*="column-"].pre-3:first-of-type {
    margin-right: calc(12vw - .5rem);
  }

  html:not([dir="rtl"]) .pre-4, html:not([dir="rtl"]) [class*="column-"] .pre-4[class*="column-"]:first-of-type {
    margin-left: 16vw;
  }

  html[dir="rtl"] [class*="column-"] .pre-4[class*="column-"]:first-of-type, html[dir="rtl"] .pre-4 {
    margin-right: 16vw;
  }

  html:not([dir="rtl"]) .first-column.pre-4, html:not([dir="rtl"]) [class*="column-"] .first-column.pre-4[class*="column-"]:first-of-type {
    margin-left: calc(16vw - .5rem);
  }

  html[dir="rtl"] [class*="column-"] .first-column.pre-4[class*="column-"]:first-of-type, html[dir="rtl"] .first-column.pre-4 {
    margin-right: calc(16vw - .5rem);
  }

  html:not([dir="rtl"]) .post-4, html:not([dir="rtl"]) [class*="column-"] .post-4[class*="column-"]:first-of-type, html[dir="rtl"] [class*="column-"] .post-4[class*="column-"]:first-of-type {
    margin-right: 16vw;
  }

  html[dir="rtl"] .post-4 {
    margin-left: 16vw;
  }

  html:not([dir="rtl"]) [class*="column-"] [class*="column-"].pre-4:first-of-type, html:not([dir="rtl"]) [class*="column-"] [class*="column-"] [class*="column-"].pre-4:first-of-type[class*="column-"]:first-of-type {
    margin-left: calc(16vw - .5rem);
  }

  html[dir="rtl"] [class*="column-"] [class*="column-"] [class*="column-"].pre-4:first-of-type[class*="column-"]:first-of-type, html[dir="rtl"] [class*="column-"] [class*="column-"].pre-4:first-of-type {
    margin-right: calc(16vw - .5rem);
  }

  html:not([dir="rtl"]) .pre-5, html:not([dir="rtl"]) [class*="column-"] .pre-5[class*="column-"]:first-of-type {
    margin-left: 20vw;
  }

  html[dir="rtl"] [class*="column-"] .pre-5[class*="column-"]:first-of-type, html[dir="rtl"] .pre-5 {
    margin-right: 20vw;
  }

  html:not([dir="rtl"]) .first-column.pre-5, html:not([dir="rtl"]) [class*="column-"] .first-column.pre-5[class*="column-"]:first-of-type {
    margin-left: calc(20vw - .5rem);
  }

  html[dir="rtl"] [class*="column-"] .first-column.pre-5[class*="column-"]:first-of-type, html[dir="rtl"] .first-column.pre-5 {
    margin-right: calc(20vw - .5rem);
  }

  html:not([dir="rtl"]) .post-5, html:not([dir="rtl"]) [class*="column-"] .post-5[class*="column-"]:first-of-type, html[dir="rtl"] [class*="column-"] .post-5[class*="column-"]:first-of-type {
    margin-right: 20vw;
  }

  html[dir="rtl"] .post-5 {
    margin-left: 20vw;
  }

  html:not([dir="rtl"]) [class*="column-"] [class*="column-"].pre-5:first-of-type, html:not([dir="rtl"]) [class*="column-"] [class*="column-"] [class*="column-"].pre-5:first-of-type[class*="column-"]:first-of-type {
    margin-left: calc(20vw - .5rem);
  }

  html[dir="rtl"] [class*="column-"] [class*="column-"] [class*="column-"].pre-5:first-of-type[class*="column-"]:first-of-type, html[dir="rtl"] [class*="column-"] [class*="column-"].pre-5:first-of-type {
    margin-right: calc(20vw - .5rem);
  }

  html:not([dir="rtl"]) .pre-6, html:not([dir="rtl"]) [class*="column-"] .pre-6[class*="column-"]:first-of-type {
    margin-left: 24vw;
  }

  html[dir="rtl"] [class*="column-"] .pre-6[class*="column-"]:first-of-type, html[dir="rtl"] .pre-6 {
    margin-right: 24vw;
  }

  html:not([dir="rtl"]) .first-column.pre-6, html:not([dir="rtl"]) [class*="column-"] .first-column.pre-6[class*="column-"]:first-of-type {
    margin-left: calc(24vw - .5rem);
  }

  html[dir="rtl"] [class*="column-"] .first-column.pre-6[class*="column-"]:first-of-type, html[dir="rtl"] .first-column.pre-6 {
    margin-right: calc(24vw - .5rem);
  }

  html:not([dir="rtl"]) .post-6, html:not([dir="rtl"]) [class*="column-"] .post-6[class*="column-"]:first-of-type, html[dir="rtl"] [class*="column-"] .post-6[class*="column-"]:first-of-type {
    margin-right: 24vw;
  }

  html[dir="rtl"] .post-6 {
    margin-left: 24vw;
  }

  html:not([dir="rtl"]) [class*="column-"] [class*="column-"].pre-6:first-of-type, html:not([dir="rtl"]) [class*="column-"] [class*="column-"] [class*="column-"].pre-6:first-of-type[class*="column-"]:first-of-type {
    margin-left: calc(24vw - .5rem);
  }

  html[dir="rtl"] [class*="column-"] [class*="column-"] [class*="column-"].pre-6:first-of-type[class*="column-"]:first-of-type, html[dir="rtl"] [class*="column-"] [class*="column-"].pre-6:first-of-type {
    margin-right: calc(24vw - .5rem);
  }

  html:not([dir="rtl"]) .pre-7, html:not([dir="rtl"]) [class*="column-"] .pre-7[class*="column-"]:first-of-type {
    margin-left: 28vw;
  }

  html[dir="rtl"] [class*="column-"] .pre-7[class*="column-"]:first-of-type, html[dir="rtl"] .pre-7 {
    margin-right: 28vw;
  }

  html:not([dir="rtl"]) .first-column.pre-7, html:not([dir="rtl"]) [class*="column-"] .first-column.pre-7[class*="column-"]:first-of-type {
    margin-left: calc(28vw - .5rem);
  }

  html[dir="rtl"] [class*="column-"] .first-column.pre-7[class*="column-"]:first-of-type, html[dir="rtl"] .first-column.pre-7 {
    margin-right: calc(28vw - .5rem);
  }

  html:not([dir="rtl"]) .post-7, html:not([dir="rtl"]) [class*="column-"] .post-7[class*="column-"]:first-of-type, html[dir="rtl"] [class*="column-"] .post-7[class*="column-"]:first-of-type {
    margin-right: 28vw;
  }

  html[dir="rtl"] .post-7 {
    margin-left: 28vw;
  }

  html:not([dir="rtl"]) [class*="column-"] [class*="column-"].pre-7:first-of-type, html:not([dir="rtl"]) [class*="column-"] [class*="column-"] [class*="column-"].pre-7:first-of-type[class*="column-"]:first-of-type {
    margin-left: calc(28vw - .5rem);
  }

  html[dir="rtl"] [class*="column-"] [class*="column-"] [class*="column-"].pre-7:first-of-type[class*="column-"]:first-of-type, html[dir="rtl"] [class*="column-"] [class*="column-"].pre-7:first-of-type {
    margin-right: calc(28vw - .5rem);
  }

  html:not([dir="rtl"]) .pre-8, html:not([dir="rtl"]) [class*="column-"] .pre-8[class*="column-"]:first-of-type {
    margin-left: 32vw;
  }

  html[dir="rtl"] [class*="column-"] .pre-8[class*="column-"]:first-of-type, html[dir="rtl"] .pre-8 {
    margin-right: 32vw;
  }

  html:not([dir="rtl"]) .first-column.pre-8, html:not([dir="rtl"]) [class*="column-"] .first-column.pre-8[class*="column-"]:first-of-type {
    margin-left: calc(32vw - .5rem);
  }

  html[dir="rtl"] [class*="column-"] .first-column.pre-8[class*="column-"]:first-of-type, html[dir="rtl"] .first-column.pre-8 {
    margin-right: calc(32vw - .5rem);
  }

  html:not([dir="rtl"]) .post-8, html:not([dir="rtl"]) [class*="column-"] .post-8[class*="column-"]:first-of-type, html[dir="rtl"] [class*="column-"] .post-8[class*="column-"]:first-of-type {
    margin-right: 32vw;
  }

  html[dir="rtl"] .post-8 {
    margin-left: 32vw;
  }

  html:not([dir="rtl"]) [class*="column-"] [class*="column-"].pre-8:first-of-type, html:not([dir="rtl"]) [class*="column-"] [class*="column-"] [class*="column-"].pre-8:first-of-type[class*="column-"]:first-of-type {
    margin-left: calc(32vw - .5rem);
  }

  html[dir="rtl"] [class*="column-"] [class*="column-"] [class*="column-"].pre-8:first-of-type[class*="column-"]:first-of-type, html[dir="rtl"] [class*="column-"] [class*="column-"].pre-8:first-of-type {
    margin-right: calc(32vw - .5rem);
  }

  html:not([dir="rtl"]) .pre-9, html:not([dir="rtl"]) [class*="column-"] .pre-9[class*="column-"]:first-of-type {
    margin-left: 36vw;
  }

  html[dir="rtl"] [class*="column-"] .pre-9[class*="column-"]:first-of-type, html[dir="rtl"] .pre-9 {
    margin-right: 36vw;
  }

  html:not([dir="rtl"]) .first-column.pre-9, html:not([dir="rtl"]) [class*="column-"] .first-column.pre-9[class*="column-"]:first-of-type {
    margin-left: calc(36vw - .5rem);
  }

  html[dir="rtl"] [class*="column-"] .first-column.pre-9[class*="column-"]:first-of-type, html[dir="rtl"] .first-column.pre-9 {
    margin-right: calc(36vw - .5rem);
  }

  html:not([dir="rtl"]) .post-9, html:not([dir="rtl"]) [class*="column-"] .post-9[class*="column-"]:first-of-type, html[dir="rtl"] [class*="column-"] .post-9[class*="column-"]:first-of-type {
    margin-right: 36vw;
  }

  html[dir="rtl"] .post-9 {
    margin-left: 36vw;
  }

  html:not([dir="rtl"]) [class*="column-"] [class*="column-"].pre-9:first-of-type, html:not([dir="rtl"]) [class*="column-"] [class*="column-"] [class*="column-"].pre-9:first-of-type[class*="column-"]:first-of-type {
    margin-left: calc(36vw - .5rem);
  }

  html[dir="rtl"] [class*="column-"] [class*="column-"] [class*="column-"].pre-9:first-of-type[class*="column-"]:first-of-type, html[dir="rtl"] [class*="column-"] [class*="column-"].pre-9:first-of-type {
    margin-right: calc(36vw - .5rem);
  }

  html:not([dir="rtl"]) .pre-10, html:not([dir="rtl"]) [class*="column-"] .pre-10[class*="column-"]:first-of-type {
    margin-left: 40vw;
  }

  html[dir="rtl"] [class*="column-"] .pre-10[class*="column-"]:first-of-type, html[dir="rtl"] .pre-10 {
    margin-right: 40vw;
  }

  html:not([dir="rtl"]) .first-column.pre-10, html:not([dir="rtl"]) [class*="column-"] .first-column.pre-10[class*="column-"]:first-of-type {
    margin-left: calc(40vw - .5rem);
  }

  html[dir="rtl"] [class*="column-"] .first-column.pre-10[class*="column-"]:first-of-type, html[dir="rtl"] .first-column.pre-10 {
    margin-right: calc(40vw - .5rem);
  }

  html:not([dir="rtl"]) .post-10, html:not([dir="rtl"]) [class*="column-"] .post-10[class*="column-"]:first-of-type, html[dir="rtl"] [class*="column-"] .post-10[class*="column-"]:first-of-type {
    margin-right: 40vw;
  }

  html[dir="rtl"] .post-10 {
    margin-left: 40vw;
  }

  html:not([dir="rtl"]) [class*="column-"] [class*="column-"].pre-10:first-of-type, html:not([dir="rtl"]) [class*="column-"] [class*="column-"] [class*="column-"].pre-10:first-of-type[class*="column-"]:first-of-type {
    margin-left: calc(40vw - .5rem);
  }

  html[dir="rtl"] [class*="column-"] [class*="column-"] [class*="column-"].pre-10:first-of-type[class*="column-"]:first-of-type, html[dir="rtl"] [class*="column-"] [class*="column-"].pre-10:first-of-type {
    margin-right: calc(40vw - .5rem);
  }

  html:not([dir="rtl"]) .pre-11, html:not([dir="rtl"]) [class*="column-"] .pre-11[class*="column-"]:first-of-type {
    margin-left: 44vw;
  }

  html[dir="rtl"] [class*="column-"] .pre-11[class*="column-"]:first-of-type, html[dir="rtl"] .pre-11 {
    margin-right: 44vw;
  }

  html:not([dir="rtl"]) .first-column.pre-11, html:not([dir="rtl"]) [class*="column-"] .first-column.pre-11[class*="column-"]:first-of-type {
    margin-left: calc(44vw - .5rem);
  }

  html[dir="rtl"] [class*="column-"] .first-column.pre-11[class*="column-"]:first-of-type, html[dir="rtl"] .first-column.pre-11 {
    margin-right: calc(44vw - .5rem);
  }

  html:not([dir="rtl"]) .post-11, html:not([dir="rtl"]) [class*="column-"] .post-11[class*="column-"]:first-of-type, html[dir="rtl"] [class*="column-"] .post-11[class*="column-"]:first-of-type {
    margin-right: 44vw;
  }

  html[dir="rtl"] .post-11 {
    margin-left: 44vw;
  }

  html:not([dir="rtl"]) [class*="column-"] [class*="column-"].pre-11:first-of-type, html:not([dir="rtl"]) [class*="column-"] [class*="column-"] [class*="column-"].pre-11:first-of-type[class*="column-"]:first-of-type {
    margin-left: calc(44vw - .5rem);
  }

  html[dir="rtl"] [class*="column-"] [class*="column-"] [class*="column-"].pre-11:first-of-type[class*="column-"]:first-of-type, html[dir="rtl"] [class*="column-"] [class*="column-"].pre-11:first-of-type {
    margin-right: calc(44vw - .5rem);
  }

  html:not([dir="rtl"]) .pre-12, html:not([dir="rtl"]) [class*="column-"] .pre-12[class*="column-"]:first-of-type {
    margin-left: 48vw;
  }

  html[dir="rtl"] [class*="column-"] .pre-12[class*="column-"]:first-of-type, html[dir="rtl"] .pre-12 {
    margin-right: 48vw;
  }

  html:not([dir="rtl"]) .first-column.pre-12, html:not([dir="rtl"]) [class*="column-"] .first-column.pre-12[class*="column-"]:first-of-type {
    margin-left: calc(48vw - .5rem);
  }

  html[dir="rtl"] [class*="column-"] .first-column.pre-12[class*="column-"]:first-of-type, html[dir="rtl"] .first-column.pre-12 {
    margin-right: calc(48vw - .5rem);
  }

  html:not([dir="rtl"]) .post-12, html:not([dir="rtl"]) [class*="column-"] .post-12[class*="column-"]:first-of-type, html[dir="rtl"] [class*="column-"] .post-12[class*="column-"]:first-of-type {
    margin-right: 48vw;
  }

  html[dir="rtl"] .post-12 {
    margin-left: 48vw;
  }

  html:not([dir="rtl"]) [class*="column-"] [class*="column-"].pre-12:first-of-type, html:not([dir="rtl"]) [class*="column-"] [class*="column-"] [class*="column-"].pre-12:first-of-type[class*="column-"]:first-of-type {
    margin-left: calc(48vw - .5rem);
  }

  html[dir="rtl"] [class*="column-"] [class*="column-"] [class*="column-"].pre-12:first-of-type[class*="column-"]:first-of-type, html[dir="rtl"] [class*="column-"] [class*="column-"].pre-12:first-of-type {
    margin-right: calc(48vw - .5rem);
  }

  html:not([dir="rtl"]) .pre-13, html:not([dir="rtl"]) [class*="column-"] .pre-13[class*="column-"]:first-of-type {
    margin-left: 52vw;
  }

  html[dir="rtl"] [class*="column-"] .pre-13[class*="column-"]:first-of-type, html[dir="rtl"] .pre-13 {
    margin-right: 52vw;
  }

  html:not([dir="rtl"]) .first-column.pre-13, html:not([dir="rtl"]) [class*="column-"] .first-column.pre-13[class*="column-"]:first-of-type {
    margin-left: calc(52vw - .5rem);
  }

  html[dir="rtl"] [class*="column-"] .first-column.pre-13[class*="column-"]:first-of-type, html[dir="rtl"] .first-column.pre-13 {
    margin-right: calc(52vw - .5rem);
  }

  html:not([dir="rtl"]) .post-13, html:not([dir="rtl"]) [class*="column-"] .post-13[class*="column-"]:first-of-type, html[dir="rtl"] [class*="column-"] .post-13[class*="column-"]:first-of-type {
    margin-right: 52vw;
  }

  html[dir="rtl"] .post-13 {
    margin-left: 52vw;
  }

  html:not([dir="rtl"]) [class*="column-"] [class*="column-"].pre-13:first-of-type, html:not([dir="rtl"]) [class*="column-"] [class*="column-"] [class*="column-"].pre-13:first-of-type[class*="column-"]:first-of-type {
    margin-left: calc(52vw - .5rem);
  }

  html[dir="rtl"] [class*="column-"] [class*="column-"] [class*="column-"].pre-13:first-of-type[class*="column-"]:first-of-type, html[dir="rtl"] [class*="column-"] [class*="column-"].pre-13:first-of-type {
    margin-right: calc(52vw - .5rem);
  }

  html:not([dir="rtl"]) .pre-14, html:not([dir="rtl"]) [class*="column-"] .pre-14[class*="column-"]:first-of-type {
    margin-left: 56vw;
  }

  html[dir="rtl"] [class*="column-"] .pre-14[class*="column-"]:first-of-type, html[dir="rtl"] .pre-14 {
    margin-right: 56vw;
  }

  html:not([dir="rtl"]) .first-column.pre-14, html:not([dir="rtl"]) [class*="column-"] .first-column.pre-14[class*="column-"]:first-of-type {
    margin-left: calc(56vw - .5rem);
  }

  html[dir="rtl"] [class*="column-"] .first-column.pre-14[class*="column-"]:first-of-type, html[dir="rtl"] .first-column.pre-14 {
    margin-right: calc(56vw - .5rem);
  }

  html:not([dir="rtl"]) .post-14, html:not([dir="rtl"]) [class*="column-"] .post-14[class*="column-"]:first-of-type, html[dir="rtl"] [class*="column-"] .post-14[class*="column-"]:first-of-type {
    margin-right: 56vw;
  }

  html[dir="rtl"] .post-14 {
    margin-left: 56vw;
  }

  html:not([dir="rtl"]) [class*="column-"] [class*="column-"].pre-14:first-of-type, html:not([dir="rtl"]) [class*="column-"] [class*="column-"] [class*="column-"].pre-14:first-of-type[class*="column-"]:first-of-type {
    margin-left: calc(56vw - .5rem);
  }

  html[dir="rtl"] [class*="column-"] [class*="column-"] [class*="column-"].pre-14:first-of-type[class*="column-"]:first-of-type, html[dir="rtl"] [class*="column-"] [class*="column-"].pre-14:first-of-type {
    margin-right: calc(56vw - .5rem);
  }

  html:not([dir="rtl"]) .pre-15, html:not([dir="rtl"]) [class*="column-"] .pre-15[class*="column-"]:first-of-type {
    margin-left: 60vw;
  }

  html[dir="rtl"] [class*="column-"] .pre-15[class*="column-"]:first-of-type, html[dir="rtl"] .pre-15 {
    margin-right: 60vw;
  }

  html:not([dir="rtl"]) .first-column.pre-15, html:not([dir="rtl"]) [class*="column-"] .first-column.pre-15[class*="column-"]:first-of-type {
    margin-left: calc(60vw - .5rem);
  }

  html[dir="rtl"] [class*="column-"] .first-column.pre-15[class*="column-"]:first-of-type, html[dir="rtl"] .first-column.pre-15 {
    margin-right: calc(60vw - .5rem);
  }

  html:not([dir="rtl"]) .post-15, html:not([dir="rtl"]) [class*="column-"] .post-15[class*="column-"]:first-of-type, html[dir="rtl"] [class*="column-"] .post-15[class*="column-"]:first-of-type {
    margin-right: 60vw;
  }

  html[dir="rtl"] .post-15 {
    margin-left: 60vw;
  }

  html:not([dir="rtl"]) [class*="column-"] [class*="column-"].pre-15:first-of-type, html:not([dir="rtl"]) [class*="column-"] [class*="column-"] [class*="column-"].pre-15:first-of-type[class*="column-"]:first-of-type {
    margin-left: calc(60vw - .5rem);
  }

  html[dir="rtl"] [class*="column-"] [class*="column-"] [class*="column-"].pre-15:first-of-type[class*="column-"]:first-of-type, html[dir="rtl"] [class*="column-"] [class*="column-"].pre-15:first-of-type {
    margin-right: calc(60vw - .5rem);
  }

  html:not([dir="rtl"]) .pre-16, html:not([dir="rtl"]) [class*="column-"] .pre-16[class*="column-"]:first-of-type {
    margin-left: 64vw;
  }

  html[dir="rtl"] [class*="column-"] .pre-16[class*="column-"]:first-of-type, html[dir="rtl"] .pre-16 {
    margin-right: 64vw;
  }

  html:not([dir="rtl"]) .first-column.pre-16, html:not([dir="rtl"]) [class*="column-"] .first-column.pre-16[class*="column-"]:first-of-type {
    margin-left: calc(64vw - .5rem);
  }

  html[dir="rtl"] [class*="column-"] .first-column.pre-16[class*="column-"]:first-of-type, html[dir="rtl"] .first-column.pre-16 {
    margin-right: calc(64vw - .5rem);
  }

  html:not([dir="rtl"]) .post-16, html:not([dir="rtl"]) [class*="column-"] .post-16[class*="column-"]:first-of-type, html[dir="rtl"] [class*="column-"] .post-16[class*="column-"]:first-of-type {
    margin-right: 64vw;
  }

  html[dir="rtl"] .post-16 {
    margin-left: 64vw;
  }

  html:not([dir="rtl"]) [class*="column-"] [class*="column-"].pre-16:first-of-type, html:not([dir="rtl"]) [class*="column-"] [class*="column-"] [class*="column-"].pre-16:first-of-type[class*="column-"]:first-of-type {
    margin-left: calc(64vw - .5rem);
  }

  html[dir="rtl"] [class*="column-"] [class*="column-"] [class*="column-"].pre-16:first-of-type[class*="column-"]:first-of-type, html[dir="rtl"] [class*="column-"] [class*="column-"].pre-16:first-of-type {
    margin-right: calc(64vw - .5rem);
  }

  html:not([dir="rtl"]) .pre-17, html:not([dir="rtl"]) [class*="column-"] .pre-17[class*="column-"]:first-of-type {
    margin-left: 68vw;
  }

  html[dir="rtl"] [class*="column-"] .pre-17[class*="column-"]:first-of-type, html[dir="rtl"] .pre-17 {
    margin-right: 68vw;
  }

  html:not([dir="rtl"]) .first-column.pre-17, html:not([dir="rtl"]) [class*="column-"] .first-column.pre-17[class*="column-"]:first-of-type {
    margin-left: calc(68vw - .5rem);
  }

  html[dir="rtl"] [class*="column-"] .first-column.pre-17[class*="column-"]:first-of-type, html[dir="rtl"] .first-column.pre-17 {
    margin-right: calc(68vw - .5rem);
  }

  html:not([dir="rtl"]) .post-17, html:not([dir="rtl"]) [class*="column-"] .post-17[class*="column-"]:first-of-type, html[dir="rtl"] [class*="column-"] .post-17[class*="column-"]:first-of-type {
    margin-right: 68vw;
  }

  html[dir="rtl"] .post-17 {
    margin-left: 68vw;
  }

  html:not([dir="rtl"]) [class*="column-"] [class*="column-"].pre-17:first-of-type, html:not([dir="rtl"]) [class*="column-"] [class*="column-"] [class*="column-"].pre-17:first-of-type[class*="column-"]:first-of-type {
    margin-left: calc(68vw - .5rem);
  }

  html[dir="rtl"] [class*="column-"] [class*="column-"] [class*="column-"].pre-17:first-of-type[class*="column-"]:first-of-type, html[dir="rtl"] [class*="column-"] [class*="column-"].pre-17:first-of-type {
    margin-right: calc(68vw - .5rem);
  }

  html:not([dir="rtl"]) .pre-18, html:not([dir="rtl"]) [class*="column-"] .pre-18[class*="column-"]:first-of-type {
    margin-left: 72vw;
  }

  html[dir="rtl"] [class*="column-"] .pre-18[class*="column-"]:first-of-type, html[dir="rtl"] .pre-18 {
    margin-right: 72vw;
  }

  html:not([dir="rtl"]) .first-column.pre-18, html:not([dir="rtl"]) [class*="column-"] .first-column.pre-18[class*="column-"]:first-of-type {
    margin-left: calc(72vw - .5rem);
  }

  html[dir="rtl"] [class*="column-"] .first-column.pre-18[class*="column-"]:first-of-type, html[dir="rtl"] .first-column.pre-18 {
    margin-right: calc(72vw - .5rem);
  }

  html:not([dir="rtl"]) .post-18, html:not([dir="rtl"]) [class*="column-"] .post-18[class*="column-"]:first-of-type, html[dir="rtl"] [class*="column-"] .post-18[class*="column-"]:first-of-type {
    margin-right: 72vw;
  }

  html[dir="rtl"] .post-18 {
    margin-left: 72vw;
  }

  html:not([dir="rtl"]) [class*="column-"] [class*="column-"].pre-18:first-of-type, html:not([dir="rtl"]) [class*="column-"] [class*="column-"] [class*="column-"].pre-18:first-of-type[class*="column-"]:first-of-type {
    margin-left: calc(72vw - .5rem);
  }

  html[dir="rtl"] [class*="column-"] [class*="column-"] [class*="column-"].pre-18:first-of-type[class*="column-"]:first-of-type, html[dir="rtl"] [class*="column-"] [class*="column-"].pre-18:first-of-type {
    margin-right: calc(72vw - .5rem);
  }

  html:not([dir="rtl"]) .pre-19, html:not([dir="rtl"]) [class*="column-"] .pre-19[class*="column-"]:first-of-type {
    margin-left: 76vw;
  }

  html[dir="rtl"] [class*="column-"] .pre-19[class*="column-"]:first-of-type, html[dir="rtl"] .pre-19 {
    margin-right: 76vw;
  }

  html:not([dir="rtl"]) .first-column.pre-19, html:not([dir="rtl"]) [class*="column-"] .first-column.pre-19[class*="column-"]:first-of-type {
    margin-left: calc(76vw - .5rem);
  }

  html[dir="rtl"] [class*="column-"] .first-column.pre-19[class*="column-"]:first-of-type, html[dir="rtl"] .first-column.pre-19 {
    margin-right: calc(76vw - .5rem);
  }

  html:not([dir="rtl"]) .post-19, html:not([dir="rtl"]) [class*="column-"] .post-19[class*="column-"]:first-of-type, html[dir="rtl"] [class*="column-"] .post-19[class*="column-"]:first-of-type {
    margin-right: 76vw;
  }

  html[dir="rtl"] .post-19 {
    margin-left: 76vw;
  }

  html:not([dir="rtl"]) [class*="column-"] [class*="column-"].pre-19:first-of-type, html:not([dir="rtl"]) [class*="column-"] [class*="column-"] [class*="column-"].pre-19:first-of-type[class*="column-"]:first-of-type {
    margin-left: calc(76vw - .5rem);
  }

  html[dir="rtl"] [class*="column-"] [class*="column-"] [class*="column-"].pre-19:first-of-type[class*="column-"]:first-of-type, html[dir="rtl"] [class*="column-"] [class*="column-"].pre-19:first-of-type {
    margin-right: calc(76vw - .5rem);
  }

  html:not([dir="rtl"]) .pre-20, html:not([dir="rtl"]) [class*="column-"] .pre-20[class*="column-"]:first-of-type {
    margin-left: 80vw;
  }

  html[dir="rtl"] [class*="column-"] .pre-20[class*="column-"]:first-of-type, html[dir="rtl"] .pre-20 {
    margin-right: 80vw;
  }

  html:not([dir="rtl"]) .first-column.pre-20, html:not([dir="rtl"]) [class*="column-"] .first-column.pre-20[class*="column-"]:first-of-type {
    margin-left: calc(80vw - .5rem);
  }

  html[dir="rtl"] [class*="column-"] .first-column.pre-20[class*="column-"]:first-of-type, html[dir="rtl"] .first-column.pre-20 {
    margin-right: calc(80vw - .5rem);
  }

  html:not([dir="rtl"]) .post-20, html:not([dir="rtl"]) [class*="column-"] .post-20[class*="column-"]:first-of-type, html[dir="rtl"] [class*="column-"] .post-20[class*="column-"]:first-of-type {
    margin-right: 80vw;
  }

  html[dir="rtl"] .post-20 {
    margin-left: 80vw;
  }

  html:not([dir="rtl"]) [class*="column-"] [class*="column-"].pre-20:first-of-type, html:not([dir="rtl"]) [class*="column-"] [class*="column-"] [class*="column-"].pre-20:first-of-type[class*="column-"]:first-of-type {
    margin-left: calc(80vw - .5rem);
  }

  html[dir="rtl"] [class*="column-"] [class*="column-"] [class*="column-"].pre-20:first-of-type[class*="column-"]:first-of-type, html[dir="rtl"] [class*="column-"] [class*="column-"].pre-20:first-of-type {
    margin-right: calc(80vw - .5rem);
  }

  html:not([dir="rtl"]) .pre-21, html:not([dir="rtl"]) [class*="column-"] .pre-21[class*="column-"]:first-of-type {
    margin-left: 84vw;
  }

  html[dir="rtl"] [class*="column-"] .pre-21[class*="column-"]:first-of-type, html[dir="rtl"] .pre-21 {
    margin-right: 84vw;
  }

  html:not([dir="rtl"]) .first-column.pre-21, html:not([dir="rtl"]) [class*="column-"] .first-column.pre-21[class*="column-"]:first-of-type {
    margin-left: calc(84vw - .5rem);
  }

  html[dir="rtl"] [class*="column-"] .first-column.pre-21[class*="column-"]:first-of-type, html[dir="rtl"] .first-column.pre-21 {
    margin-right: calc(84vw - .5rem);
  }

  html:not([dir="rtl"]) .post-21, html:not([dir="rtl"]) [class*="column-"] .post-21[class*="column-"]:first-of-type, html[dir="rtl"] [class*="column-"] .post-21[class*="column-"]:first-of-type {
    margin-right: 84vw;
  }

  html[dir="rtl"] .post-21 {
    margin-left: 84vw;
  }

  html:not([dir="rtl"]) [class*="column-"] [class*="column-"].pre-21:first-of-type, html:not([dir="rtl"]) [class*="column-"] [class*="column-"] [class*="column-"].pre-21:first-of-type[class*="column-"]:first-of-type {
    margin-left: calc(84vw - .5rem);
  }

  html[dir="rtl"] [class*="column-"] [class*="column-"] [class*="column-"].pre-21:first-of-type[class*="column-"]:first-of-type, html[dir="rtl"] [class*="column-"] [class*="column-"].pre-21:first-of-type {
    margin-right: calc(84vw - .5rem);
  }

  html:not([dir="rtl"]) .pre-22, html:not([dir="rtl"]) [class*="column-"] .pre-22[class*="column-"]:first-of-type {
    margin-left: 88vw;
  }

  html[dir="rtl"] [class*="column-"] .pre-22[class*="column-"]:first-of-type, html[dir="rtl"] .pre-22 {
    margin-right: 88vw;
  }

  html:not([dir="rtl"]) .first-column.pre-22, html:not([dir="rtl"]) [class*="column-"] .first-column.pre-22[class*="column-"]:first-of-type {
    margin-left: calc(88vw - .5rem);
  }

  html[dir="rtl"] [class*="column-"] .first-column.pre-22[class*="column-"]:first-of-type, html[dir="rtl"] .first-column.pre-22 {
    margin-right: calc(88vw - .5rem);
  }

  html:not([dir="rtl"]) .post-22, html:not([dir="rtl"]) [class*="column-"] .post-22[class*="column-"]:first-of-type, html[dir="rtl"] [class*="column-"] .post-22[class*="column-"]:first-of-type {
    margin-right: 88vw;
  }

  html[dir="rtl"] .post-22 {
    margin-left: 88vw;
  }

  html:not([dir="rtl"]) [class*="column-"] [class*="column-"].pre-22:first-of-type, html:not([dir="rtl"]) [class*="column-"] [class*="column-"] [class*="column-"].pre-22:first-of-type[class*="column-"]:first-of-type {
    margin-left: calc(88vw - .5rem);
  }

  html[dir="rtl"] [class*="column-"] [class*="column-"] [class*="column-"].pre-22:first-of-type[class*="column-"]:first-of-type, html[dir="rtl"] [class*="column-"] [class*="column-"].pre-22:first-of-type {
    margin-right: calc(88vw - .5rem);
  }

  html:not([dir="rtl"]) .pre-23, html:not([dir="rtl"]) [class*="column-"] .pre-23[class*="column-"]:first-of-type {
    margin-left: 92vw;
  }

  html[dir="rtl"] [class*="column-"] .pre-23[class*="column-"]:first-of-type, html[dir="rtl"] .pre-23 {
    margin-right: 92vw;
  }

  html:not([dir="rtl"]) .first-column.pre-23, html:not([dir="rtl"]) [class*="column-"] .first-column.pre-23[class*="column-"]:first-of-type {
    margin-left: calc(92vw - .5rem);
  }

  html[dir="rtl"] [class*="column-"] .first-column.pre-23[class*="column-"]:first-of-type, html[dir="rtl"] .first-column.pre-23 {
    margin-right: calc(92vw - .5rem);
  }

  html:not([dir="rtl"]) .post-23, html:not([dir="rtl"]) [class*="column-"] .post-23[class*="column-"]:first-of-type, html[dir="rtl"] [class*="column-"] .post-23[class*="column-"]:first-of-type {
    margin-right: 92vw;
  }

  html[dir="rtl"] .post-23 {
    margin-left: 92vw;
  }

  html:not([dir="rtl"]) [class*="column-"] [class*="column-"].pre-23:first-of-type, html:not([dir="rtl"]) [class*="column-"] [class*="column-"] [class*="column-"].pre-23:first-of-type[class*="column-"]:first-of-type {
    margin-left: calc(92vw - .5rem);
  }

  html[dir="rtl"] [class*="column-"] [class*="column-"] [class*="column-"].pre-23:first-of-type[class*="column-"]:first-of-type, html[dir="rtl"] [class*="column-"] [class*="column-"].pre-23:first-of-type {
    margin-right: calc(92vw - .5rem);
  }

  html:not([dir="rtl"]) .pre-24, html:not([dir="rtl"]) [class*="column-"] .pre-24[class*="column-"]:first-of-type {
    margin-left: 96vw;
  }

  html[dir="rtl"] [class*="column-"] .pre-24[class*="column-"]:first-of-type, html[dir="rtl"] .pre-24 {
    margin-right: 96vw;
  }

  html:not([dir="rtl"]) .first-column.pre-24, html:not([dir="rtl"]) [class*="column-"] .first-column.pre-24[class*="column-"]:first-of-type {
    margin-left: calc(96vw - .5rem);
  }

  html[dir="rtl"] [class*="column-"] .first-column.pre-24[class*="column-"]:first-of-type, html[dir="rtl"] .first-column.pre-24 {
    margin-right: calc(96vw - .5rem);
  }

  html:not([dir="rtl"]) .post-24, html:not([dir="rtl"]) [class*="column-"] .post-24[class*="column-"]:first-of-type, html[dir="rtl"] [class*="column-"] .post-24[class*="column-"]:first-of-type {
    margin-right: 96vw;
  }

  html[dir="rtl"] .post-24 {
    margin-left: 96vw;
  }

  html:not([dir="rtl"]) [class*="column-"] [class*="column-"].pre-24:first-of-type, html:not([dir="rtl"]) [class*="column-"] [class*="column-"] [class*="column-"].pre-24:first-of-type[class*="column-"]:first-of-type {
    margin-left: calc(96vw - .5rem);
  }

  html[dir="rtl"] [class*="column-"] [class*="column-"] [class*="column-"].pre-24:first-of-type[class*="column-"]:first-of-type, html[dir="rtl"] [class*="column-"] [class*="column-"].pre-24:first-of-type {
    margin-right: calc(96vw - .5rem);
  }
}

@media screen and (max-width: 859px) {
  html:not([dir="rtl"]) .tablet-pre-0, html:not([dir="rtl"]) [class*="column-"] .tablet-pre-0[class*="column-"]:first-of-type {
    margin-left: 0;
  }

  html[dir="rtl"] [class*="column-"] .tablet-pre-0[class*="column-"]:first-of-type, html[dir="rtl"] .tablet-pre-0 {
    margin-right: 0;
  }

  html:not([dir="rtl"]) .tablet-first-column.tablet-pre-0, html:not([dir="rtl"]) [class*="column-"] .tablet-first-column.tablet-pre-0[class*="column-"]:first-of-type {
    margin-left: -.5rem;
  }

  html[dir="rtl"] [class*="column-"] .tablet-first-column.tablet-pre-0[class*="column-"]:first-of-type, html[dir="rtl"] .tablet-first-column.tablet-pre-0 {
    margin-right: -.5rem;
  }

  html:not([dir="rtl"]) .tablet-post-0, html:not([dir="rtl"]) [class*="column-"] .tablet-post-0[class*="column-"]:first-of-type, html[dir="rtl"] [class*="column-"] .tablet-post-0[class*="column-"]:first-of-type {
    margin-right: 0;
  }

  html[dir="rtl"] .tablet-post-0 {
    margin-left: 0;
  }

  html:not([dir="rtl"]) .tablet-pre-1, html:not([dir="rtl"]) [class*="column-"] .tablet-pre-1[class*="column-"]:first-of-type {
    margin-left: 8vw;
  }

  html[dir="rtl"] [class*="column-"] .tablet-pre-1[class*="column-"]:first-of-type, html[dir="rtl"] .tablet-pre-1 {
    margin-right: 8vw;
  }

  html:not([dir="rtl"]) .tablet-first-column.tablet-pre-1, html:not([dir="rtl"]) [class*="column-"] .tablet-first-column.tablet-pre-1[class*="column-"]:first-of-type {
    margin-left: calc(8vw - .5rem);
  }

  html[dir="rtl"] [class*="column-"] .tablet-first-column.tablet-pre-1[class*="column-"]:first-of-type, html[dir="rtl"] .tablet-first-column.tablet-pre-1 {
    margin-right: calc(8vw - .5rem);
  }

  html:not([dir="rtl"]) .tablet-post-1, html:not([dir="rtl"]) [class*="column-"] .tablet-post-1[class*="column-"]:first-of-type, html[dir="rtl"] [class*="column-"] .tablet-post-1[class*="column-"]:first-of-type {
    margin-right: 8vw;
  }

  html[dir="rtl"] .tablet-post-1 {
    margin-left: 8vw;
  }

  html:not([dir="rtl"]) .tablet-pre-2, html:not([dir="rtl"]) [class*="column-"] .tablet-pre-2[class*="column-"]:first-of-type {
    margin-left: 16vw;
  }

  html[dir="rtl"] [class*="column-"] .tablet-pre-2[class*="column-"]:first-of-type, html[dir="rtl"] .tablet-pre-2 {
    margin-right: 16vw;
  }

  html:not([dir="rtl"]) .tablet-first-column.tablet-pre-2, html:not([dir="rtl"]) [class*="column-"] .tablet-first-column.tablet-pre-2[class*="column-"]:first-of-type {
    margin-left: calc(16vw - .5rem);
  }

  html[dir="rtl"] [class*="column-"] .tablet-first-column.tablet-pre-2[class*="column-"]:first-of-type, html[dir="rtl"] .tablet-first-column.tablet-pre-2 {
    margin-right: calc(16vw - .5rem);
  }

  html:not([dir="rtl"]) .tablet-post-2, html:not([dir="rtl"]) [class*="column-"] .tablet-post-2[class*="column-"]:first-of-type, html[dir="rtl"] [class*="column-"] .tablet-post-2[class*="column-"]:first-of-type {
    margin-right: 16vw;
  }

  html[dir="rtl"] .tablet-post-2 {
    margin-left: 16vw;
  }

  html:not([dir="rtl"]) .tablet-pre-3, html:not([dir="rtl"]) [class*="column-"] .tablet-pre-3[class*="column-"]:first-of-type {
    margin-left: 24vw;
  }

  html[dir="rtl"] [class*="column-"] .tablet-pre-3[class*="column-"]:first-of-type, html[dir="rtl"] .tablet-pre-3 {
    margin-right: 24vw;
  }

  html:not([dir="rtl"]) .tablet-first-column.tablet-pre-3, html:not([dir="rtl"]) [class*="column-"] .tablet-first-column.tablet-pre-3[class*="column-"]:first-of-type {
    margin-left: calc(24vw - .5rem);
  }

  html[dir="rtl"] [class*="column-"] .tablet-first-column.tablet-pre-3[class*="column-"]:first-of-type, html[dir="rtl"] .tablet-first-column.tablet-pre-3 {
    margin-right: calc(24vw - .5rem);
  }

  html:not([dir="rtl"]) .tablet-post-3, html:not([dir="rtl"]) [class*="column-"] .tablet-post-3[class*="column-"]:first-of-type, html[dir="rtl"] [class*="column-"] .tablet-post-3[class*="column-"]:first-of-type {
    margin-right: 24vw;
  }

  html[dir="rtl"] .tablet-post-3 {
    margin-left: 24vw;
  }

  html:not([dir="rtl"]) .tablet-pre-4, html:not([dir="rtl"]) [class*="column-"] .tablet-pre-4[class*="column-"]:first-of-type {
    margin-left: 32vw;
  }

  html[dir="rtl"] [class*="column-"] .tablet-pre-4[class*="column-"]:first-of-type, html[dir="rtl"] .tablet-pre-4 {
    margin-right: 32vw;
  }

  html:not([dir="rtl"]) .tablet-first-column.tablet-pre-4, html:not([dir="rtl"]) [class*="column-"] .tablet-first-column.tablet-pre-4[class*="column-"]:first-of-type {
    margin-left: calc(32vw - .5rem);
  }

  html[dir="rtl"] [class*="column-"] .tablet-first-column.tablet-pre-4[class*="column-"]:first-of-type, html[dir="rtl"] .tablet-first-column.tablet-pre-4 {
    margin-right: calc(32vw - .5rem);
  }

  html:not([dir="rtl"]) .tablet-post-4, html:not([dir="rtl"]) [class*="column-"] .tablet-post-4[class*="column-"]:first-of-type, html[dir="rtl"] [class*="column-"] .tablet-post-4[class*="column-"]:first-of-type {
    margin-right: 32vw;
  }

  html[dir="rtl"] .tablet-post-4 {
    margin-left: 32vw;
  }

  html:not([dir="rtl"]) .tablet-pre-5, html:not([dir="rtl"]) [class*="column-"] .tablet-pre-5[class*="column-"]:first-of-type {
    margin-left: 40vw;
  }

  html[dir="rtl"] [class*="column-"] .tablet-pre-5[class*="column-"]:first-of-type, html[dir="rtl"] .tablet-pre-5 {
    margin-right: 40vw;
  }

  html:not([dir="rtl"]) .tablet-first-column.tablet-pre-5, html:not([dir="rtl"]) [class*="column-"] .tablet-first-column.tablet-pre-5[class*="column-"]:first-of-type {
    margin-left: calc(40vw - .5rem);
  }

  html[dir="rtl"] [class*="column-"] .tablet-first-column.tablet-pre-5[class*="column-"]:first-of-type, html[dir="rtl"] .tablet-first-column.tablet-pre-5 {
    margin-right: calc(40vw - .5rem);
  }

  html:not([dir="rtl"]) .tablet-post-5, html:not([dir="rtl"]) [class*="column-"] .tablet-post-5[class*="column-"]:first-of-type, html[dir="rtl"] [class*="column-"] .tablet-post-5[class*="column-"]:first-of-type {
    margin-right: 40vw;
  }

  html[dir="rtl"] .tablet-post-5 {
    margin-left: 40vw;
  }

  html:not([dir="rtl"]) .tablet-pre-6, html:not([dir="rtl"]) [class*="column-"] .tablet-pre-6[class*="column-"]:first-of-type {
    margin-left: 48vw;
  }

  html[dir="rtl"] [class*="column-"] .tablet-pre-6[class*="column-"]:first-of-type, html[dir="rtl"] .tablet-pre-6 {
    margin-right: 48vw;
  }

  html:not([dir="rtl"]) .tablet-first-column.tablet-pre-6, html:not([dir="rtl"]) [class*="column-"] .tablet-first-column.tablet-pre-6[class*="column-"]:first-of-type {
    margin-left: calc(48vw - .5rem);
  }

  html[dir="rtl"] [class*="column-"] .tablet-first-column.tablet-pre-6[class*="column-"]:first-of-type, html[dir="rtl"] .tablet-first-column.tablet-pre-6 {
    margin-right: calc(48vw - .5rem);
  }

  html:not([dir="rtl"]) .tablet-post-6, html:not([dir="rtl"]) [class*="column-"] .tablet-post-6[class*="column-"]:first-of-type, html[dir="rtl"] [class*="column-"] .tablet-post-6[class*="column-"]:first-of-type {
    margin-right: 48vw;
  }

  html[dir="rtl"] .tablet-post-6 {
    margin-left: 48vw;
  }

  html:not([dir="rtl"]) .tablet-pre-7, html:not([dir="rtl"]) [class*="column-"] .tablet-pre-7[class*="column-"]:first-of-type {
    margin-left: 56vw;
  }

  html[dir="rtl"] [class*="column-"] .tablet-pre-7[class*="column-"]:first-of-type, html[dir="rtl"] .tablet-pre-7 {
    margin-right: 56vw;
  }

  html:not([dir="rtl"]) .tablet-first-column.tablet-pre-7, html:not([dir="rtl"]) [class*="column-"] .tablet-first-column.tablet-pre-7[class*="column-"]:first-of-type {
    margin-left: calc(56vw - .5rem);
  }

  html[dir="rtl"] [class*="column-"] .tablet-first-column.tablet-pre-7[class*="column-"]:first-of-type, html[dir="rtl"] .tablet-first-column.tablet-pre-7 {
    margin-right: calc(56vw - .5rem);
  }

  html:not([dir="rtl"]) .tablet-post-7, html:not([dir="rtl"]) [class*="column-"] .tablet-post-7[class*="column-"]:first-of-type, html[dir="rtl"] [class*="column-"] .tablet-post-7[class*="column-"]:first-of-type {
    margin-right: 56vw;
  }

  html[dir="rtl"] .tablet-post-7 {
    margin-left: 56vw;
  }

  html:not([dir="rtl"]) .tablet-pre-8, html:not([dir="rtl"]) [class*="column-"] .tablet-pre-8[class*="column-"]:first-of-type {
    margin-left: 64vw;
  }

  html[dir="rtl"] [class*="column-"] .tablet-pre-8[class*="column-"]:first-of-type, html[dir="rtl"] .tablet-pre-8 {
    margin-right: 64vw;
  }

  html:not([dir="rtl"]) .tablet-first-column.tablet-pre-8, html:not([dir="rtl"]) [class*="column-"] .tablet-first-column.tablet-pre-8[class*="column-"]:first-of-type {
    margin-left: calc(64vw - .5rem);
  }

  html[dir="rtl"] [class*="column-"] .tablet-first-column.tablet-pre-8[class*="column-"]:first-of-type, html[dir="rtl"] .tablet-first-column.tablet-pre-8 {
    margin-right: calc(64vw - .5rem);
  }

  html:not([dir="rtl"]) .tablet-post-8, html:not([dir="rtl"]) [class*="column-"] .tablet-post-8[class*="column-"]:first-of-type, html[dir="rtl"] [class*="column-"] .tablet-post-8[class*="column-"]:first-of-type {
    margin-right: 64vw;
  }

  html[dir="rtl"] .tablet-post-8 {
    margin-left: 64vw;
  }

  html:not([dir="rtl"]) .tablet-pre-9, html:not([dir="rtl"]) [class*="column-"] .tablet-pre-9[class*="column-"]:first-of-type {
    margin-left: 72vw;
  }

  html[dir="rtl"] [class*="column-"] .tablet-pre-9[class*="column-"]:first-of-type, html[dir="rtl"] .tablet-pre-9 {
    margin-right: 72vw;
  }

  html:not([dir="rtl"]) .tablet-first-column.tablet-pre-9, html:not([dir="rtl"]) [class*="column-"] .tablet-first-column.tablet-pre-9[class*="column-"]:first-of-type {
    margin-left: calc(72vw - .5rem);
  }

  html[dir="rtl"] [class*="column-"] .tablet-first-column.tablet-pre-9[class*="column-"]:first-of-type, html[dir="rtl"] .tablet-first-column.tablet-pre-9 {
    margin-right: calc(72vw - .5rem);
  }

  html:not([dir="rtl"]) .tablet-post-9, html:not([dir="rtl"]) [class*="column-"] .tablet-post-9[class*="column-"]:first-of-type, html[dir="rtl"] [class*="column-"] .tablet-post-9[class*="column-"]:first-of-type {
    margin-right: 72vw;
  }

  html[dir="rtl"] .tablet-post-9 {
    margin-left: 72vw;
  }

  html:not([dir="rtl"]) .tablet-pre-10, html:not([dir="rtl"]) [class*="column-"] .tablet-pre-10[class*="column-"]:first-of-type {
    margin-left: 80vw;
  }

  html[dir="rtl"] [class*="column-"] .tablet-pre-10[class*="column-"]:first-of-type, html[dir="rtl"] .tablet-pre-10 {
    margin-right: 80vw;
  }

  html:not([dir="rtl"]) .tablet-first-column.tablet-pre-10, html:not([dir="rtl"]) [class*="column-"] .tablet-first-column.tablet-pre-10[class*="column-"]:first-of-type {
    margin-left: calc(80vw - .5rem);
  }

  html[dir="rtl"] [class*="column-"] .tablet-first-column.tablet-pre-10[class*="column-"]:first-of-type, html[dir="rtl"] .tablet-first-column.tablet-pre-10 {
    margin-right: calc(80vw - .5rem);
  }

  html:not([dir="rtl"]) .tablet-post-10, html:not([dir="rtl"]) [class*="column-"] .tablet-post-10[class*="column-"]:first-of-type, html[dir="rtl"] [class*="column-"] .tablet-post-10[class*="column-"]:first-of-type {
    margin-right: 80vw;
  }

  html[dir="rtl"] .tablet-post-10 {
    margin-left: 80vw;
  }

  html:not([dir="rtl"]) .tablet-pre-11, html:not([dir="rtl"]) [class*="column-"] .tablet-pre-11[class*="column-"]:first-of-type {
    margin-left: 88vw;
  }

  html[dir="rtl"] [class*="column-"] .tablet-pre-11[class*="column-"]:first-of-type, html[dir="rtl"] .tablet-pre-11 {
    margin-right: 88vw;
  }

  html:not([dir="rtl"]) .tablet-first-column.tablet-pre-11, html:not([dir="rtl"]) [class*="column-"] .tablet-first-column.tablet-pre-11[class*="column-"]:first-of-type {
    margin-left: calc(88vw - .5rem);
  }

  html[dir="rtl"] [class*="column-"] .tablet-first-column.tablet-pre-11[class*="column-"]:first-of-type, html[dir="rtl"] .tablet-first-column.tablet-pre-11 {
    margin-right: calc(88vw - .5rem);
  }

  html:not([dir="rtl"]) .tablet-post-11, html:not([dir="rtl"]) [class*="column-"] .tablet-post-11[class*="column-"]:first-of-type, html[dir="rtl"] [class*="column-"] .tablet-post-11[class*="column-"]:first-of-type {
    margin-right: 88vw;
  }

  html[dir="rtl"] .tablet-post-11 {
    margin-left: 88vw;
  }

  html:not([dir="rtl"]) .tablet-pre-12, html:not([dir="rtl"]) [class*="column-"] .tablet-pre-12[class*="column-"]:first-of-type {
    margin-left: 96vw;
  }

  html[dir="rtl"] [class*="column-"] .tablet-pre-12[class*="column-"]:first-of-type, html[dir="rtl"] .tablet-pre-12 {
    margin-right: 96vw;
  }

  html:not([dir="rtl"]) .tablet-first-column.tablet-pre-12, html:not([dir="rtl"]) [class*="column-"] .tablet-first-column.tablet-pre-12[class*="column-"]:first-of-type {
    margin-left: calc(96vw - .5rem);
  }

  html[dir="rtl"] [class*="column-"] .tablet-first-column.tablet-pre-12[class*="column-"]:first-of-type, html[dir="rtl"] .tablet-first-column.tablet-pre-12 {
    margin-right: calc(96vw - .5rem);
  }

  html:not([dir="rtl"]) .tablet-post-12, html:not([dir="rtl"]) [class*="column-"] .tablet-post-12[class*="column-"]:first-of-type, html[dir="rtl"] [class*="column-"] .tablet-post-12[class*="column-"]:first-of-type {
    margin-right: 96vw;
  }

  html[dir="rtl"] .tablet-post-12 {
    margin-left: 96vw;
  }
}

@media screen and (max-width: 479px) {
  html:not([dir="rtl"]) .phone-pre-0, html:not([dir="rtl"]) [class*="column-"] .phone-pre-0[class*="column-"]:first-of-type {
    margin-left: 0;
  }

  html[dir="rtl"] [class*="column-"] .phone-pre-0[class*="column-"]:first-of-type, html[dir="rtl"] .phone-pre-0 {
    margin-right: 0;
  }

  html:not([dir="rtl"]) .phone-first-column.phone-pre-0, html:not([dir="rtl"]) [class*="column-"] .phone-first-column.phone-pre-0[class*="column-"]:first-of-type {
    margin-left: -.5rem;
  }

  html[dir="rtl"] [class*="column-"] .phone-first-column.phone-pre-0[class*="column-"]:first-of-type, html[dir="rtl"] .phone-first-column.phone-pre-0 {
    margin-right: -.5rem;
  }

  html:not([dir="rtl"]) .phone-post-0, html:not([dir="rtl"]) [class*="column-"] .phone-post-0[class*="column-"]:first-of-type, html[dir="rtl"] [class*="column-"] .phone-post-0[class*="column-"]:first-of-type {
    margin-right: 0;
  }

  html[dir="rtl"] .phone-post-0 {
    margin-left: 0;
  }

  html:not([dir="rtl"]) .phone-pre-1, html:not([dir="rtl"]) [class*="column-"] .phone-pre-1[class*="column-"]:first-of-type {
    margin-left: 16vw;
  }

  html[dir="rtl"] [class*="column-"] .phone-pre-1[class*="column-"]:first-of-type, html[dir="rtl"] .phone-pre-1 {
    margin-right: 16vw;
  }

  html:not([dir="rtl"]) .phone-first-column.phone-pre-1, html:not([dir="rtl"]) [class*="column-"] .phone-first-column.phone-pre-1[class*="column-"]:first-of-type {
    margin-left: calc(16vw - .5rem);
  }

  html[dir="rtl"] [class*="column-"] .phone-first-column.phone-pre-1[class*="column-"]:first-of-type, html[dir="rtl"] .phone-first-column.phone-pre-1 {
    margin-right: calc(16vw - .5rem);
  }

  html:not([dir="rtl"]) .phone-post-1, html:not([dir="rtl"]) [class*="column-"] .phone-post-1[class*="column-"]:first-of-type, html[dir="rtl"] [class*="column-"] .phone-post-1[class*="column-"]:first-of-type {
    margin-right: 16vw;
  }

  html[dir="rtl"] .phone-post-1 {
    margin-left: 16vw;
  }

  html:not([dir="rtl"]) .phone-pre-2, html:not([dir="rtl"]) [class*="column-"] .phone-pre-2[class*="column-"]:first-of-type {
    margin-left: 32vw;
  }

  html[dir="rtl"] [class*="column-"] .phone-pre-2[class*="column-"]:first-of-type, html[dir="rtl"] .phone-pre-2 {
    margin-right: 32vw;
  }

  html:not([dir="rtl"]) .phone-first-column.phone-pre-2, html:not([dir="rtl"]) [class*="column-"] .phone-first-column.phone-pre-2[class*="column-"]:first-of-type {
    margin-left: calc(32vw - .5rem);
  }

  html[dir="rtl"] [class*="column-"] .phone-first-column.phone-pre-2[class*="column-"]:first-of-type, html[dir="rtl"] .phone-first-column.phone-pre-2 {
    margin-right: calc(32vw - .5rem);
  }

  html:not([dir="rtl"]) .phone-post-2, html:not([dir="rtl"]) [class*="column-"] .phone-post-2[class*="column-"]:first-of-type, html[dir="rtl"] [class*="column-"] .phone-post-2[class*="column-"]:first-of-type {
    margin-right: 32vw;
  }

  html[dir="rtl"] .phone-post-2 {
    margin-left: 32vw;
  }

  html:not([dir="rtl"]) .phone-pre-3, html:not([dir="rtl"]) [class*="column-"] .phone-pre-3[class*="column-"]:first-of-type {
    margin-left: 48vw;
  }

  html[dir="rtl"] [class*="column-"] .phone-pre-3[class*="column-"]:first-of-type, html[dir="rtl"] .phone-pre-3 {
    margin-right: 48vw;
  }

  html:not([dir="rtl"]) .phone-first-column.phone-pre-3, html:not([dir="rtl"]) [class*="column-"] .phone-first-column.phone-pre-3[class*="column-"]:first-of-type {
    margin-left: calc(48vw - .5rem);
  }

  html[dir="rtl"] [class*="column-"] .phone-first-column.phone-pre-3[class*="column-"]:first-of-type, html[dir="rtl"] .phone-first-column.phone-pre-3 {
    margin-right: calc(48vw - .5rem);
  }

  html:not([dir="rtl"]) .phone-post-3, html:not([dir="rtl"]) [class*="column-"] .phone-post-3[class*="column-"]:first-of-type, html[dir="rtl"] [class*="column-"] .phone-post-3[class*="column-"]:first-of-type {
    margin-right: 48vw;
  }

  html[dir="rtl"] .phone-post-3 {
    margin-left: 48vw;
  }

  html:not([dir="rtl"]) .phone-pre-4, html:not([dir="rtl"]) [class*="column-"] .phone-pre-4[class*="column-"]:first-of-type {
    margin-left: 64vw;
  }

  html[dir="rtl"] [class*="column-"] .phone-pre-4[class*="column-"]:first-of-type, html[dir="rtl"] .phone-pre-4 {
    margin-right: 64vw;
  }

  html:not([dir="rtl"]) .phone-first-column.phone-pre-4, html:not([dir="rtl"]) [class*="column-"] .phone-first-column.phone-pre-4[class*="column-"]:first-of-type {
    margin-left: calc(64vw - .5rem);
  }

  html[dir="rtl"] [class*="column-"] .phone-first-column.phone-pre-4[class*="column-"]:first-of-type, html[dir="rtl"] .phone-first-column.phone-pre-4 {
    margin-right: calc(64vw - .5rem);
  }

  html:not([dir="rtl"]) .phone-post-4, html:not([dir="rtl"]) [class*="column-"] .phone-post-4[class*="column-"]:first-of-type, html[dir="rtl"] [class*="column-"] .phone-post-4[class*="column-"]:first-of-type {
    margin-right: 64vw;
  }

  html[dir="rtl"] .phone-post-4 {
    margin-left: 64vw;
  }

  html:not([dir="rtl"]) .phone-pre-5, html:not([dir="rtl"]) [class*="column-"] .phone-pre-5[class*="column-"]:first-of-type {
    margin-left: 80vw;
  }

  html[dir="rtl"] [class*="column-"] .phone-pre-5[class*="column-"]:first-of-type, html[dir="rtl"] .phone-pre-5 {
    margin-right: 80vw;
  }

  html:not([dir="rtl"]) .phone-first-column.phone-pre-5, html:not([dir="rtl"]) [class*="column-"] .phone-first-column.phone-pre-5[class*="column-"]:first-of-type {
    margin-left: calc(80vw - .5rem);
  }

  html[dir="rtl"] [class*="column-"] .phone-first-column.phone-pre-5[class*="column-"]:first-of-type, html[dir="rtl"] .phone-first-column.phone-pre-5 {
    margin-right: calc(80vw - .5rem);
  }

  html:not([dir="rtl"]) .phone-post-5, html:not([dir="rtl"]) [class*="column-"] .phone-post-5[class*="column-"]:first-of-type, html[dir="rtl"] [class*="column-"] .phone-post-5[class*="column-"]:first-of-type {
    margin-right: 80vw;
  }

  html[dir="rtl"] .phone-post-5 {
    margin-left: 80vw;
  }

  html:not([dir="rtl"]) .phone-pre-6, html:not([dir="rtl"]) [class*="column-"] .phone-pre-6[class*="column-"]:first-of-type {
    margin-left: 96vw;
  }

  html[dir="rtl"] [class*="column-"] .phone-pre-6[class*="column-"]:first-of-type, html[dir="rtl"] .phone-pre-6 {
    margin-right: 96vw;
  }

  html:not([dir="rtl"]) .phone-first-column.phone-pre-6, html:not([dir="rtl"]) [class*="column-"] .phone-first-column.phone-pre-6[class*="column-"]:first-of-type {
    margin-left: calc(96vw - .5rem);
  }

  html[dir="rtl"] [class*="column-"] .phone-first-column.phone-pre-6[class*="column-"]:first-of-type, html[dir="rtl"] .phone-first-column.phone-pre-6 {
    margin-right: calc(96vw - .5rem);
  }

  html:not([dir="rtl"]) .phone-post-6, html:not([dir="rtl"]) [class*="column-"] .phone-post-6[class*="column-"]:first-of-type, html[dir="rtl"] [class*="column-"] .phone-post-6[class*="column-"]:first-of-type {
    margin-right: 96vw;
  }

  html[dir="rtl"] .phone-post-6 {
    margin-left: 96vw;
  }
}

@media screen and (min-width: 1501px) {
  html:not([dir="rtl"]) .pre-0 {
    margin-left: -1px;
  }

  html[dir="rtl"] .pre-0, html:not([dir="rtl"]) .post-0 {
    margin-right: -1px;
  }

  html[dir="rtl"] .post-0 {
    margin-left: -1px;
  }

  html:not([dir="rtl"]) [class*="column-"] [class*="column-"].pre-0:first-of-type {
    margin-left: calc(-1px - .5rem);
  }

  html[dir="rtl"] [class*="column-"] [class*="column-"].pre-0:first-of-type, html:not([dir="rtl"]) [class*="column-"] [class*="column-"].post-0:last-of-type {
    margin-right: calc(-1px - .5rem);
  }

  html[dir="rtl"] [class*="column-"] [class*="column-"].post-0:last-of-type {
    margin-left: calc(-1px - .5rem);
  }

  html:not([dir="rtl"]) .pre-1 {
    margin-left: 59px;
  }

  html[dir="rtl"] .pre-1, html:not([dir="rtl"]) .post-1 {
    margin-right: 59px;
  }

  html[dir="rtl"] .post-1 {
    margin-left: 59px;
  }

  html:not([dir="rtl"]) [class*="column-"] [class*="column-"].pre-1:first-of-type {
    margin-left: calc(59px - .5rem);
  }

  html[dir="rtl"] [class*="column-"] [class*="column-"].pre-1:first-of-type, html:not([dir="rtl"]) [class*="column-"] [class*="column-"].post-1:last-of-type {
    margin-right: calc(59px - .5rem);
  }

  html[dir="rtl"] [class*="column-"] [class*="column-"].post-1:last-of-type {
    margin-left: calc(59px - .5rem);
  }

  html:not([dir="rtl"]) .pre-2 {
    margin-left: 119px;
  }

  html[dir="rtl"] .pre-2, html:not([dir="rtl"]) .post-2 {
    margin-right: 119px;
  }

  html[dir="rtl"] .post-2 {
    margin-left: 119px;
  }

  html:not([dir="rtl"]) [class*="column-"] [class*="column-"].pre-2:first-of-type {
    margin-left: calc(119px - .5rem);
  }

  html[dir="rtl"] [class*="column-"] [class*="column-"].pre-2:first-of-type, html:not([dir="rtl"]) [class*="column-"] [class*="column-"].post-2:last-of-type {
    margin-right: calc(119px - .5rem);
  }

  html[dir="rtl"] [class*="column-"] [class*="column-"].post-2:last-of-type {
    margin-left: calc(119px - .5rem);
  }

  html:not([dir="rtl"]) .pre-3 {
    margin-left: 179px;
  }

  html[dir="rtl"] .pre-3, html:not([dir="rtl"]) .post-3 {
    margin-right: 179px;
  }

  html[dir="rtl"] .post-3 {
    margin-left: 179px;
  }

  html:not([dir="rtl"]) [class*="column-"] [class*="column-"].pre-3:first-of-type {
    margin-left: calc(179px - .5rem);
  }

  html[dir="rtl"] [class*="column-"] [class*="column-"].pre-3:first-of-type, html:not([dir="rtl"]) [class*="column-"] [class*="column-"].post-3:last-of-type {
    margin-right: calc(179px - .5rem);
  }

  html[dir="rtl"] [class*="column-"] [class*="column-"].post-3:last-of-type {
    margin-left: calc(179px - .5rem);
  }

  html:not([dir="rtl"]) .pre-4 {
    margin-left: 239px;
  }

  html[dir="rtl"] .pre-4, html:not([dir="rtl"]) .post-4 {
    margin-right: 239px;
  }

  html[dir="rtl"] .post-4 {
    margin-left: 239px;
  }

  html:not([dir="rtl"]) [class*="column-"] [class*="column-"].pre-4:first-of-type {
    margin-left: calc(239px - .5rem);
  }

  html[dir="rtl"] [class*="column-"] [class*="column-"].pre-4:first-of-type, html:not([dir="rtl"]) [class*="column-"] [class*="column-"].post-4:last-of-type {
    margin-right: calc(239px - .5rem);
  }

  html[dir="rtl"] [class*="column-"] [class*="column-"].post-4:last-of-type {
    margin-left: calc(239px - .5rem);
  }

  html:not([dir="rtl"]) .pre-5 {
    margin-left: 299px;
  }

  html[dir="rtl"] .pre-5, html:not([dir="rtl"]) .post-5 {
    margin-right: 299px;
  }

  html[dir="rtl"] .post-5 {
    margin-left: 299px;
  }

  html:not([dir="rtl"]) [class*="column-"] [class*="column-"].pre-5:first-of-type {
    margin-left: calc(299px - .5rem);
  }

  html[dir="rtl"] [class*="column-"] [class*="column-"].pre-5:first-of-type, html:not([dir="rtl"]) [class*="column-"] [class*="column-"].post-5:last-of-type {
    margin-right: calc(299px - .5rem);
  }

  html[dir="rtl"] [class*="column-"] [class*="column-"].post-5:last-of-type {
    margin-left: calc(299px - .5rem);
  }

  html:not([dir="rtl"]) .pre-6 {
    margin-left: 359px;
  }

  html[dir="rtl"] .pre-6, html:not([dir="rtl"]) .post-6 {
    margin-right: 359px;
  }

  html[dir="rtl"] .post-6 {
    margin-left: 359px;
  }

  html:not([dir="rtl"]) [class*="column-"] [class*="column-"].pre-6:first-of-type {
    margin-left: calc(359px - .5rem);
  }

  html[dir="rtl"] [class*="column-"] [class*="column-"].pre-6:first-of-type, html:not([dir="rtl"]) [class*="column-"] [class*="column-"].post-6:last-of-type {
    margin-right: calc(359px - .5rem);
  }

  html[dir="rtl"] [class*="column-"] [class*="column-"].post-6:last-of-type {
    margin-left: calc(359px - .5rem);
  }

  html:not([dir="rtl"]) .pre-7 {
    margin-left: 419px;
  }

  html[dir="rtl"] .pre-7, html:not([dir="rtl"]) .post-7 {
    margin-right: 419px;
  }

  html[dir="rtl"] .post-7 {
    margin-left: 419px;
  }

  html:not([dir="rtl"]) [class*="column-"] [class*="column-"].pre-7:first-of-type {
    margin-left: calc(419px - .5rem);
  }

  html[dir="rtl"] [class*="column-"] [class*="column-"].pre-7:first-of-type, html:not([dir="rtl"]) [class*="column-"] [class*="column-"].post-7:last-of-type {
    margin-right: calc(419px - .5rem);
  }

  html[dir="rtl"] [class*="column-"] [class*="column-"].post-7:last-of-type {
    margin-left: calc(419px - .5rem);
  }

  html:not([dir="rtl"]) .pre-8 {
    margin-left: 479px;
  }

  html[dir="rtl"] .pre-8, html:not([dir="rtl"]) .post-8 {
    margin-right: 479px;
  }

  html[dir="rtl"] .post-8 {
    margin-left: 479px;
  }

  html:not([dir="rtl"]) [class*="column-"] [class*="column-"].pre-8:first-of-type {
    margin-left: calc(479px - .5rem);
  }

  html[dir="rtl"] [class*="column-"] [class*="column-"].pre-8:first-of-type, html:not([dir="rtl"]) [class*="column-"] [class*="column-"].post-8:last-of-type {
    margin-right: calc(479px - .5rem);
  }

  html[dir="rtl"] [class*="column-"] [class*="column-"].post-8:last-of-type {
    margin-left: calc(479px - .5rem);
  }

  html:not([dir="rtl"]) .pre-9 {
    margin-left: 539px;
  }

  html[dir="rtl"] .pre-9, html:not([dir="rtl"]) .post-9 {
    margin-right: 539px;
  }

  html[dir="rtl"] .post-9 {
    margin-left: 539px;
  }

  html:not([dir="rtl"]) [class*="column-"] [class*="column-"].pre-9:first-of-type {
    margin-left: calc(539px - .5rem);
  }

  html[dir="rtl"] [class*="column-"] [class*="column-"].pre-9:first-of-type, html:not([dir="rtl"]) [class*="column-"] [class*="column-"].post-9:last-of-type {
    margin-right: calc(539px - .5rem);
  }

  html[dir="rtl"] [class*="column-"] [class*="column-"].post-9:last-of-type {
    margin-left: calc(539px - .5rem);
  }

  html:not([dir="rtl"]) .pre-10 {
    margin-left: 599px;
  }

  html[dir="rtl"] .pre-10, html:not([dir="rtl"]) .post-10 {
    margin-right: 599px;
  }

  html[dir="rtl"] .post-10 {
    margin-left: 599px;
  }

  html:not([dir="rtl"]) [class*="column-"] [class*="column-"].pre-10:first-of-type {
    margin-left: calc(599px - .5rem);
  }

  html[dir="rtl"] [class*="column-"] [class*="column-"].pre-10:first-of-type, html:not([dir="rtl"]) [class*="column-"] [class*="column-"].post-10:last-of-type {
    margin-right: calc(599px - .5rem);
  }

  html[dir="rtl"] [class*="column-"] [class*="column-"].post-10:last-of-type {
    margin-left: calc(599px - .5rem);
  }

  html:not([dir="rtl"]) .pre-11 {
    margin-left: 659px;
  }

  html[dir="rtl"] .pre-11, html:not([dir="rtl"]) .post-11 {
    margin-right: 659px;
  }

  html[dir="rtl"] .post-11 {
    margin-left: 659px;
  }

  html:not([dir="rtl"]) [class*="column-"] [class*="column-"].pre-11:first-of-type {
    margin-left: calc(659px - .5rem);
  }

  html[dir="rtl"] [class*="column-"] [class*="column-"].pre-11:first-of-type, html:not([dir="rtl"]) [class*="column-"] [class*="column-"].post-11:last-of-type {
    margin-right: calc(659px - .5rem);
  }

  html[dir="rtl"] [class*="column-"] [class*="column-"].post-11:last-of-type {
    margin-left: calc(659px - .5rem);
  }

  html:not([dir="rtl"]) .pre-12 {
    margin-left: 719px;
  }

  html[dir="rtl"] .pre-12, html:not([dir="rtl"]) .post-12 {
    margin-right: 719px;
  }

  html[dir="rtl"] .post-12 {
    margin-left: 719px;
  }

  html:not([dir="rtl"]) [class*="column-"] [class*="column-"].pre-12:first-of-type {
    margin-left: calc(719px - .5rem);
  }

  html[dir="rtl"] [class*="column-"] [class*="column-"].pre-12:first-of-type, html:not([dir="rtl"]) [class*="column-"] [class*="column-"].post-12:last-of-type {
    margin-right: calc(719px - .5rem);
  }

  html[dir="rtl"] [class*="column-"] [class*="column-"].post-12:last-of-type {
    margin-left: calc(719px - .5rem);
  }

  html:not([dir="rtl"]) .pre-13 {
    margin-left: 779px;
  }

  html[dir="rtl"] .pre-13, html:not([dir="rtl"]) .post-13 {
    margin-right: 779px;
  }

  html[dir="rtl"] .post-13 {
    margin-left: 779px;
  }

  html:not([dir="rtl"]) [class*="column-"] [class*="column-"].pre-13:first-of-type {
    margin-left: calc(779px - .5rem);
  }

  html[dir="rtl"] [class*="column-"] [class*="column-"].pre-13:first-of-type, html:not([dir="rtl"]) [class*="column-"] [class*="column-"].post-13:last-of-type {
    margin-right: calc(779px - .5rem);
  }

  html[dir="rtl"] [class*="column-"] [class*="column-"].post-13:last-of-type {
    margin-left: calc(779px - .5rem);
  }

  html:not([dir="rtl"]) .pre-14 {
    margin-left: 839px;
  }

  html[dir="rtl"] .pre-14, html:not([dir="rtl"]) .post-14 {
    margin-right: 839px;
  }

  html[dir="rtl"] .post-14 {
    margin-left: 839px;
  }

  html:not([dir="rtl"]) [class*="column-"] [class*="column-"].pre-14:first-of-type {
    margin-left: calc(839px - .5rem);
  }

  html[dir="rtl"] [class*="column-"] [class*="column-"].pre-14:first-of-type, html:not([dir="rtl"]) [class*="column-"] [class*="column-"].post-14:last-of-type {
    margin-right: calc(839px - .5rem);
  }

  html[dir="rtl"] [class*="column-"] [class*="column-"].post-14:last-of-type {
    margin-left: calc(839px - .5rem);
  }

  html:not([dir="rtl"]) .pre-15 {
    margin-left: 899px;
  }

  html[dir="rtl"] .pre-15, html:not([dir="rtl"]) .post-15 {
    margin-right: 899px;
  }

  html[dir="rtl"] .post-15 {
    margin-left: 899px;
  }

  html:not([dir="rtl"]) [class*="column-"] [class*="column-"].pre-15:first-of-type {
    margin-left: calc(899px - .5rem);
  }

  html[dir="rtl"] [class*="column-"] [class*="column-"].pre-15:first-of-type, html:not([dir="rtl"]) [class*="column-"] [class*="column-"].post-15:last-of-type {
    margin-right: calc(899px - .5rem);
  }

  html[dir="rtl"] [class*="column-"] [class*="column-"].post-15:last-of-type {
    margin-left: calc(899px - .5rem);
  }

  html:not([dir="rtl"]) .pre-16 {
    margin-left: 959px;
  }

  html[dir="rtl"] .pre-16, html:not([dir="rtl"]) .post-16 {
    margin-right: 959px;
  }

  html[dir="rtl"] .post-16 {
    margin-left: 959px;
  }

  html:not([dir="rtl"]) [class*="column-"] [class*="column-"].pre-16:first-of-type {
    margin-left: calc(959px - .5rem);
  }

  html[dir="rtl"] [class*="column-"] [class*="column-"].pre-16:first-of-type, html:not([dir="rtl"]) [class*="column-"] [class*="column-"].post-16:last-of-type {
    margin-right: calc(959px - .5rem);
  }

  html[dir="rtl"] [class*="column-"] [class*="column-"].post-16:last-of-type {
    margin-left: calc(959px - .5rem);
  }

  html:not([dir="rtl"]) .pre-17 {
    margin-left: 1019px;
  }

  html[dir="rtl"] .pre-17, html:not([dir="rtl"]) .post-17 {
    margin-right: 1019px;
  }

  html[dir="rtl"] .post-17 {
    margin-left: 1019px;
  }

  html:not([dir="rtl"]) [class*="column-"] [class*="column-"].pre-17:first-of-type {
    margin-left: calc(1019px - .5rem);
  }

  html[dir="rtl"] [class*="column-"] [class*="column-"].pre-17:first-of-type, html:not([dir="rtl"]) [class*="column-"] [class*="column-"].post-17:last-of-type {
    margin-right: calc(1019px - .5rem);
  }

  html[dir="rtl"] [class*="column-"] [class*="column-"].post-17:last-of-type {
    margin-left: calc(1019px - .5rem);
  }

  html:not([dir="rtl"]) .pre-18 {
    margin-left: 1079px;
  }

  html[dir="rtl"] .pre-18, html:not([dir="rtl"]) .post-18 {
    margin-right: 1079px;
  }

  html[dir="rtl"] .post-18 {
    margin-left: 1079px;
  }

  html:not([dir="rtl"]) [class*="column-"] [class*="column-"].pre-18:first-of-type {
    margin-left: calc(1079px - .5rem);
  }

  html[dir="rtl"] [class*="column-"] [class*="column-"].pre-18:first-of-type, html:not([dir="rtl"]) [class*="column-"] [class*="column-"].post-18:last-of-type {
    margin-right: calc(1079px - .5rem);
  }

  html[dir="rtl"] [class*="column-"] [class*="column-"].post-18:last-of-type {
    margin-left: calc(1079px - .5rem);
  }

  html:not([dir="rtl"]) .pre-19 {
    margin-left: 1139px;
  }

  html[dir="rtl"] .pre-19, html:not([dir="rtl"]) .post-19 {
    margin-right: 1139px;
  }

  html[dir="rtl"] .post-19 {
    margin-left: 1139px;
  }

  html:not([dir="rtl"]) [class*="column-"] [class*="column-"].pre-19:first-of-type {
    margin-left: calc(1139px - .5rem);
  }

  html[dir="rtl"] [class*="column-"] [class*="column-"].pre-19:first-of-type, html:not([dir="rtl"]) [class*="column-"] [class*="column-"].post-19:last-of-type {
    margin-right: calc(1139px - .5rem);
  }

  html[dir="rtl"] [class*="column-"] [class*="column-"].post-19:last-of-type {
    margin-left: calc(1139px - .5rem);
  }

  html:not([dir="rtl"]) .pre-20 {
    margin-left: 1199px;
  }

  html[dir="rtl"] .pre-20, html:not([dir="rtl"]) .post-20 {
    margin-right: 1199px;
  }

  html[dir="rtl"] .post-20 {
    margin-left: 1199px;
  }

  html:not([dir="rtl"]) [class*="column-"] [class*="column-"].pre-20:first-of-type {
    margin-left: calc(1199px - .5rem);
  }

  html[dir="rtl"] [class*="column-"] [class*="column-"].pre-20:first-of-type, html:not([dir="rtl"]) [class*="column-"] [class*="column-"].post-20:last-of-type {
    margin-right: calc(1199px - .5rem);
  }

  html[dir="rtl"] [class*="column-"] [class*="column-"].post-20:last-of-type {
    margin-left: calc(1199px - .5rem);
  }

  html:not([dir="rtl"]) .pre-21 {
    margin-left: 1259px;
  }

  html[dir="rtl"] .pre-21, html:not([dir="rtl"]) .post-21 {
    margin-right: 1259px;
  }

  html[dir="rtl"] .post-21 {
    margin-left: 1259px;
  }

  html:not([dir="rtl"]) [class*="column-"] [class*="column-"].pre-21:first-of-type {
    margin-left: calc(1259px - .5rem);
  }

  html[dir="rtl"] [class*="column-"] [class*="column-"].pre-21:first-of-type, html:not([dir="rtl"]) [class*="column-"] [class*="column-"].post-21:last-of-type {
    margin-right: calc(1259px - .5rem);
  }

  html[dir="rtl"] [class*="column-"] [class*="column-"].post-21:last-of-type {
    margin-left: calc(1259px - .5rem);
  }

  html:not([dir="rtl"]) .pre-22 {
    margin-left: 1319px;
  }

  html[dir="rtl"] .pre-22, html:not([dir="rtl"]) .post-22 {
    margin-right: 1319px;
  }

  html[dir="rtl"] .post-22 {
    margin-left: 1319px;
  }

  html:not([dir="rtl"]) [class*="column-"] [class*="column-"].pre-22:first-of-type {
    margin-left: calc(1319px - .5rem);
  }

  html[dir="rtl"] [class*="column-"] [class*="column-"].pre-22:first-of-type, html:not([dir="rtl"]) [class*="column-"] [class*="column-"].post-22:last-of-type {
    margin-right: calc(1319px - .5rem);
  }

  html[dir="rtl"] [class*="column-"] [class*="column-"].post-22:last-of-type {
    margin-left: calc(1319px - .5rem);
  }

  html:not([dir="rtl"]) .pre-23 {
    margin-left: 1379px;
  }

  html[dir="rtl"] .pre-23, html:not([dir="rtl"]) .post-23 {
    margin-right: 1379px;
  }

  html[dir="rtl"] .post-23 {
    margin-left: 1379px;
  }

  html:not([dir="rtl"]) [class*="column-"] [class*="column-"].pre-23:first-of-type {
    margin-left: calc(1379px - .5rem);
  }

  html[dir="rtl"] [class*="column-"] [class*="column-"].pre-23:first-of-type, html:not([dir="rtl"]) [class*="column-"] [class*="column-"].post-23:last-of-type {
    margin-right: calc(1379px - .5rem);
  }

  html[dir="rtl"] [class*="column-"] [class*="column-"].post-23:last-of-type {
    margin-left: calc(1379px - .5rem);
  }

  html:not([dir="rtl"]) .pre-24 {
    margin-left: 1439px;
  }

  html[dir="rtl"] .pre-24, html:not([dir="rtl"]) .post-24 {
    margin-right: 1439px;
  }

  html[dir="rtl"] .post-24 {
    margin-left: 1439px;
  }

  html:not([dir="rtl"]) [class*="column-"] [class*="column-"].pre-24:first-of-type {
    margin-left: calc(1439px - .5rem);
  }

  html[dir="rtl"] [class*="column-"] [class*="column-"].pre-24:first-of-type, html:not([dir="rtl"]) [class*="column-"] [class*="column-"].post-24:last-of-type {
    margin-right: calc(1439px - .5rem);
  }

  html[dir="rtl"] [class*="column-"] [class*="column-"].post-24:last-of-type {
    margin-left: calc(1439px - .5rem);
  }
}

@media screen and (min-width: 1440px) {
  .phone-show, .tablet-show, .tablet-only, .large-hide {
    display: none !important;
  }
}

@media screen and (max-width: 1439px) and (min-width: 860px) {
  .phone-show, .tablet-show, .tablet-only, .large-only, .large-show {
    display: none !important;
  }
}

@media screen and (max-width: 859px) and (min-width: 480px) {
  .tablet-hide, .phone-show, .large-only, .large-show {
    display: none !important;
  }
}

@media screen and (max-width: 479px) {
  .phone-hide, .tablet-hide, .tablet-only, .large-only, .large-show {
    display: none !important;
  }
}

.invisible {
  visibility: hidden;
}

.visible {
  visibility: visible;
}

.hide {
  display: none !important;
}

.visually-hidden, .visually-hidden-focusable {
  clip: rect(0 0 0 0);
  border: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.visually-hidden-focusable:active, .visually-hidden-focusable:focus {
  clip: auto;
  width: auto;
  height: auto;
  margin: 0;
  position: static;
  overflow: visible;
}

.left {
  float: left;
}

html[dir="rtl"] .left, .right {
  float: right;
}

html[dir="rtl"] .right {
  float: left;
}

@media screen and (min-width: 1440px) {
  .large-left {
    float: left;
  }

  html[dir="rtl"] .large-left, .large-right {
    float: right;
  }

  html[dir="rtl"] .large-right {
    float: left;
  }
}

@media screen and (max-width: 859px) and (min-width: 480px) {
  .tablet-left {
    float: left;
  }

  html[dir="rtl"] .tablet-left, .tablet-right {
    float: right;
  }

  html[dir="rtl"] .tablet-right {
    float: left;
  }
}

@media screen and (max-width: 479px) {
  .phone-left {
    float: left;
  }

  html[dir="rtl"] .phone-left, .phone-right {
    float: right;
  }

  html[dir="rtl"] .phone-right {
    float: left;
  }
}

.block-group {
  flex-wrap: wrap;
  margin-left: -.75rem;
  margin-right: -.75rem;
  display: flex;
}

.block {
  box-sizing: border-box;
  flex-grow: 0;
  flex-shrink: 1;
  min-width: 0;
  margin-left: .75rem;
  margin-right: .75rem;
}

.block-group-1-up > .block {
  flex-basis: calc(100% - 1.5rem);
  width: calc(100% - 1.5rem);
}

.block-group-2-up > .block {
  flex-basis: calc(50% - 1.5rem);
  width: calc(50% - 1.5rem);
}

.block-group-3-up > .block {
  flex-basis: calc(33.3333% - 1.5rem);
  width: calc(33.3333% - 1.5rem);
}

.block-group-4-up > .block {
  flex-basis: calc(25% - 1.5rem);
  width: calc(25% - 1.5rem);
}

.block-group-5-up > .block {
  flex-basis: calc(20% - 1.5rem);
  width: calc(20% - 1.5rem);
}

.block-group-6-up > .block {
  flex-basis: calc(16.6667% - 1.5rem);
  width: calc(16.6667% - 1.5rem);
}

.block-group-7-up > .block {
  flex-basis: calc(14.2857% - 1.5rem);
  width: calc(14.2857% - 1.5rem);
}

.block-group-8-up > .block {
  flex-basis: calc(12.5% - 1.5rem);
  width: calc(12.5% - 1.5rem);
}

@media screen and (max-width: 860px) {
  .tablet-block-group-1-up > .block {
    flex-basis: calc(100% - 1.5rem);
    width: calc(100% - 1.5rem);
  }

  .tablet-block-group-2-up > .block {
    flex-basis: calc(50% - 1.5rem);
    width: calc(50% - 1.5rem);
  }

  .tablet-block-group-3-up > .block {
    flex-basis: calc(33.3333% - 1.5rem);
    width: calc(33.3333% - 1.5rem);
  }

  .tablet-block-group-4-up > .block {
    flex-basis: calc(25% - 1.5rem);
    width: calc(25% - 1.5rem);
  }

  .tablet-block-group-5-up > .block {
    flex-basis: calc(20% - 1.5rem);
    width: calc(20% - 1.5rem);
  }

  .tablet-block-group-6-up > .block {
    flex-basis: calc(16.6667% - 1.5rem);
    width: calc(16.6667% - 1.5rem);
  }

  .tablet-block-group-7-up > .block {
    flex-basis: calc(14.2857% - 1.5rem);
    width: calc(14.2857% - 1.5rem);
  }

  .tablet-block-group-8-up > .block {
    flex-basis: calc(12.5% - 1.5rem);
    width: calc(12.5% - 1.5rem);
  }
}

@media screen and (max-width: 480px) {
  .phone-block-group-1-up > .block {
    flex-basis: calc(100% - 1.5rem);
    width: calc(100% - 1.5rem);
  }

  .phone-block-group-2-up > .block {
    flex-basis: calc(50% - 1.5rem);
    width: calc(50% - 1.5rem);
  }

  .phone-block-group-3-up > .block {
    flex-basis: calc(33.3333% - 1.5rem);
    width: calc(33.3333% - 1.5rem);
  }

  .phone-block-group-4-up > .block {
    flex-basis: calc(25% - 1.5rem);
    width: calc(25% - 1.5rem);
  }

  .phone-block-group-5-up > .block {
    flex-basis: calc(20% - 1.5rem);
    width: calc(20% - 1.5rem);
  }

  .phone-block-group-6-up > .block {
    flex-basis: calc(16.6667% - 1.5rem);
    width: calc(16.6667% - 1.5rem);
  }

  .phone-block-group-7-up > .block {
    flex-basis: calc(14.2857% - 1.5rem);
    width: calc(14.2857% - 1.5rem);
  }

  .phone-block-group-8-up > .block {
    flex-basis: calc(12.5% - 1.5rem);
    width: calc(12.5% - 1.5rem);
  }
}

.is-sticky {
  z-index: 10;
  position: fixed;
}

.is-sticky.scroll-show, .is-sticky .scroll-show {
  visibility: visible;
}

.scroll-show {
  visibility: hidden;
}

@font-face {
  font-family: Avenir Next;
  src: url("https://webapps-cdn.esri.com/CDN/fonts/v1.1.2/77156710-6a58-4606-b189-b4185e75967b.woff2") format("woff2"), url("https://webapps-cdn.esri.com/CDN/fonts/v1.1.2/3d5260a1-e4cd-4567-80ed-69d23c40355f.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: Avenir Next;
  src: url("https://webapps-cdn.esri.com/CDN/fonts/v1.1.2/77caabd3-1877-4634-85c8-8e398a093b99.woff2") format("woff2"), url("https://webapps-cdn.esri.com/CDN/fonts/v1.1.2/e388ac99-8c6a-4451-8690-1d15b4d45adb.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: Avenir Next;
  src: url("https://webapps-cdn.esri.com/CDN/fonts/v1.1.2/014f2daa-c310-4a36-b9fd-79a8e0c48d44.woff2") format("woff2"), url("https://webapps-cdn.esri.com/CDN/fonts/v1.1.2/12b00842-ec20-4c7f-aa72-802fb00f6cc4.woff") format("woff");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: Avenir Next;
  src: url("https://webapps-cdn.esri.com/CDN/fonts/v1.1.2/e78b17bb-11fb-4860-8d66-4ee0d0c1e117.woff2") format("woff2"), url("https://webapps-cdn.esri.com/CDN/fonts/v1.1.2/d4ffabb3-dd7c-472a-bdfb-6700383c6354.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: Avenir Next;
  src: url("https://webapps-cdn.esri.com/CDN/fonts/v1.1.2/77156710-6a58-4606-b189-b4185e75967b-ext.woff2") format("woff2"), url("https://webapps-cdn.esri.com/CDN/fonts/v1.1.2/3d5260a1-e4cd-4567-80ed-69d23c40355f-ext.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  unicode-range: U+100-17F, U+180-24F, U+1E??, U+2B0-2FF;
}

@font-face {
  font-family: Avenir Next;
  src: url("https://webapps-cdn.esri.com/CDN/fonts/v1.1.2/77caabd3-1877-4634-85c8-8e398a093b99-ext.woff2") format("woff2"), url("https://webapps-cdn.esri.com/CDN/fonts/v1.1.2/e388ac99-8c6a-4451-8690-1d15b4d45adb-ext.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  unicode-range: U+100-17F, U+180-24F, U+1E??, U+2B0-2FF;
}

@font-face {
  font-family: Avenir Next;
  src: url("https://webapps-cdn.esri.com/CDN/fonts/v1.1.2/014f2daa-c310-4a36-b9fd-79a8e0c48d44-ext.woff2") format("woff2"), url("https://webapps-cdn.esri.com/CDN/fonts/v1.1.2/12b00842-ec20-4c7f-aa72-802fb00f6cc4-ext.woff") format("woff");
  font-weight: 400;
  font-style: italic;
  unicode-range: U+100-17F, U+180-24F, U+1E??, U+2B0-2FF;
}

@font-face {
  font-family: Avenir Next;
  src: url("https://webapps-cdn.esri.com/CDN/fonts/v1.1.2/9851da0a-2481-4687-bbeb-ed4ab170dc38-ext.woff2") format("woff2"), url("https://webapps-cdn.esri.com/CDN/fonts/v1.1.2/cebce072-9561-4c6d-8c89-f0cefec63289-ext.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  unicode-range: U+100-17F, U+180-24F, U+1E??, U+2B0-2FF;
}

@font-face {
  font-family: Avenir Next;
  src: url("https://webapps-cdn.esri.com/CDN/fonts/v1.1.2/e78b17bb-11fb-4860-8d66-4ee0d0c1e117-ext.woff2") format("woff2"), url("https://webapps-cdn.esri.com/CDN/fonts/v1.1.2/d4ffabb3-dd7c-472a-bdfb-6700383c6354-ext.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  unicode-range: U+100-17F, U+180-24F, U+1E??, U+2B0-2FF;
}

@font-face {
  font-family: Avenir Next;
  src: url("https://webapps-cdn.esri.com/CDN/fonts/v1.1.2/77156710-6a58-4606-b189-b4185e75967b-greek.woff2") format("woff2"), url("https://webapps-cdn.esri.com/CDN/fonts/v1.1.2/3d5260a1-e4cd-4567-80ed-69d23c40355f-greek.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  unicode-range: U+370-3FF;
}

@font-face {
  font-family: Avenir Next;
  src: url("https://webapps-cdn.esri.com/CDN/fonts/v1.1.2/77caabd3-1877-4634-85c8-8e398a093b99-greek.woff2") format("woff2"), url("https://webapps-cdn.esri.com/CDN/fonts/v1.1.2/e388ac99-8c6a-4451-8690-1d15b4d45adb-greek.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  unicode-range: U+370-3FF;
}

@font-face {
  font-family: Avenir Next;
  src: url("https://webapps-cdn.esri.com/CDN/fonts/v1.1.2/014f2daa-c310-4a36-b9fd-79a8e0c48d44-greek.woff2") format("woff2"), url("https://webapps-cdn.esri.com/CDN/fonts/v1.1.2/12b00842-ec20-4c7f-aa72-802fb00f6cc4-greek.woff") format("woff");
  font-weight: 400;
  font-style: italic;
  unicode-range: U+370-3FF;
}

@font-face {
  font-family: Avenir Next;
  src: url("https://webapps-cdn.esri.com/CDN/fonts/v1.1.2/e78b17bb-11fb-4860-8d66-4ee0d0c1e117-greek.woff2") format("woff2"), url("https://webapps-cdn.esri.com/CDN/fonts/v1.1.2/d4ffabb3-dd7c-472a-bdfb-6700383c6354-greek.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  unicode-range: U+370-3FF;
}

@font-face {
  font-family: Avenir Next;
  src: url("https://webapps-cdn.esri.com/CDN/fonts/v1.1.2/174d458a-81e0-4174-9473-35e3bf0a613c.woff2") format("woff2"), url("https://webapps-cdn.esri.com/CDN/fonts/v1.1.2/57a79aa3-9b06-4ba7-a9a4-2b766d826ecf.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  unicode-range: U+4??;
}

@font-face {
  font-family: Avenir Next;
  src: url("https://webapps-cdn.esri.com/CDN/fonts/v1.1.2/7db1f672-3a8f-4d19-9c49-7f61aed450b5.woff2") format("woff2"), url("https://webapps-cdn.esri.com/CDN/fonts/v1.1.2/4ab86b35-c0c2-42b5-98ad-4b6eba66b197.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  unicode-range: U+4??;
}

@font-face {
  font-family: Avenir Next;
  src: url("https://webapps-cdn.esri.com/CDN/fonts/v1.1.2/b17468ea-cf53-4635-984b-4d930a68ed4d.woff2") format("woff2"), url("https://webapps-cdn.esri.com/CDN/fonts/v1.1.2/4d1d0d0d-9ea6-4117-901f-8b32ca1ab936.woff") format("woff");
  font-weight: 400;
  font-style: italic;
  unicode-range: U+4??;
}

@font-face {
  font-family: Avenir Next;
  src: url("https://webapps-cdn.esri.com/CDN/fonts/v1.1.2/40d36b4a-60c6-460a-bf43-4c948c23563e.woff2") format("woff2"), url("https://webapps-cdn.esri.com/CDN/fonts/v1.1.2/45b78f45-e639-4836-8612-e0892e120f14.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  unicode-range: U+4??;
}

@font-face {
  font-family: Avenir Next;
  src: url("https://webapps-cdn.esri.com/CDN/fonts/v1.1.2/281f890c-8412-4ee3-84ed-8b5d062d2ab8.woff2") format("woff2"), url("https://webapps-cdn.esri.com/CDN/fonts/v1.1.2/5729f02e-f6b0-4f35-8ee5-c2cffa65fa76.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  unicode-range: U+10A0-10FF;
}

@font-face {
  font-family: Avenir Next;
  src: url("https://webapps-cdn.esri.com/CDN/fonts/v1.1.2/281f890c-8412-4ee3-84ed-8b5d062d2ab8.woff2") format("woff2"), url("https://webapps-cdn.esri.com/CDN/fonts/v1.1.2/5729f02e-f6b0-4f35-8ee5-c2cffa65fa76.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  unicode-range: U+10A0-10FF;
}

@font-face {
  font-family: Avenir Next;
  src: url("https://webapps-cdn.esri.com/CDN/fonts/v1.1.2/2200dfff-da50-40b0-bc12-5e4b872a1998.woff2") format("woff2"), url("https://webapps-cdn.esri.com/CDN/fonts/v1.1.2/dc10b3bd-5076-4df5-a5f5-e5961f4a6938.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  unicode-range: U+10A0-10FF;
}

@font-face {
  font-family: Avenir Next;
  src: url("https://webapps-cdn.esri.com/CDN/fonts/v1.1.2/2a1ae9a5-b6b5-405c-b660-bbdf1b356952.woff2") format("woff2"), url("https://webapps-cdn.esri.com/CDN/fonts/v1.1.2/a8aeea1b-1a9d-45b7-8ad9-7c71824599e2.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  unicode-range: U+6??, U+FB50-FDFF, U+FE70-FEFF;
}

@font-face {
  font-family: Avenir Next;
  src: url("https://webapps-cdn.esri.com/CDN/fonts/v1.1.2/6ea5fa46-5311-450b-8744-288a30c55348.woff2") format("woff2"), url("https://webapps-cdn.esri.com/CDN/fonts/v1.1.2/d9e4040d-32ff-4a1c-ac04-927a781da1f5.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  unicode-range: U+6??, U+FB50-FDFF, U+FE70-FEFF;
}

@font-face {
  font-family: Avenir Next;
  src: url("https://webapps-cdn.esri.com/CDN/fonts/v1.1.2/97694c53-4e94-4f9e-969b-a148adfcdcfd.woff2") format("woff2"), url("https://webapps-cdn.esri.com/CDN/fonts/v1.1.2/8b01637a-f445-4f10-92ea-b84a355f7690.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  unicode-range: U+6??, U+FB50-FDFF, U+FE70-FEFF;
}

@font-face {
  font-family: Avenir Next;
  src: url("https://webapps-cdn.esri.com/CDN/fonts/v1.1.2/31da4b04-f98a-4b5f-b545-a31d26da99e5.woff2") format("woff2"), url("https://webapps-cdn.esri.com/CDN/fonts/v1.1.2/d98fb015-7ef6-404f-a58a-5c9242d79770.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  unicode-range: U+590-5FF, U+FB00-FB4F;
}

@font-face {
  font-family: Avenir Next;
  src: url("https://webapps-cdn.esri.com/CDN/fonts/v1.1.2/31da4b04-f98a-4b5f-b545-a31d26da99e5.woff2") format("woff2"), url("https://webapps-cdn.esri.com/CDN/fonts/v1.1.2/d98fb015-7ef6-404f-a58a-5c9242d79770.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  unicode-range: U+590-5FF, U+FB00-FB4F;
}

@font-face {
  font-family: Avenir Next;
  src: url("https://webapps-cdn.esri.com/CDN/fonts/v1.1.2/32a2c5cf-6736-44a6-a276-49ba7e030944.woff2") format("woff2"), url("https://webapps-cdn.esri.com/CDN/fonts/v1.1.2/fa71df11-7b19-4baf-8ff7-3537dea718f0.woff") format("woff");
  font-weight: 400;
  font-style: italic;
  unicode-range: U+590-5FF, U+FB00-FB4F;
}

@font-face {
  font-family: Avenir Next;
  src: url("https://webapps-cdn.esri.com/CDN/fonts/v1.1.2/a9eaf4d3-6427-42df-9306-3ea1270f7b1a.woff2") format("woff2"), url("https://webapps-cdn.esri.com/CDN/fonts/v1.1.2/f4a085c3-1c64-4fc0-a598-26f3e658c2b0.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  unicode-range: U+590-5FF, U+FB00-FB4F;
}

@font-face {
  font-family: Avenir Next;
  src: url("https://webapps-cdn.esri.com/CDN/fonts/v1.1.2/94aa531e-7746-4df0-bb6e-349891f2eda5.woff2") format("woff2"), url("https://webapps-cdn.esri.com/CDN/fonts/v1.1.2/121524c1-8d82-4155-bfb3-fd2f15f09e93.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  unicode-range: U+900-97F;
}

@font-face {
  font-family: Avenir Next;
  src: url("https://webapps-cdn.esri.com/CDN/fonts/v1.1.2/3ae1e25e-3aa6-4061-a016-a079159f9d65.woff2") format("woff2"), url("https://webapps-cdn.esri.com/CDN/fonts/v1.1.2/f1799750-0952-403f-8108-b2402eed0f62.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  unicode-range: U+900-97F;
}

@font-face {
  font-family: Avenir Next;
  src: url("https://webapps-cdn.esri.com/CDN/fonts/v1.1.2/41331c3c-3759-4462-8695-33c9a21b6a5b.woff2") format("woff2"), url("https://webapps-cdn.esri.com/CDN/fonts/v1.1.2/31e0c094-e345-4a54-a797-d5f1a5885572.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  unicode-range: U+900-97F;
}

@font-face {
  font-family: SST Vietnamese;
  src: url("https://webapps-cdn.esri.com/CDN/fonts/v1.1.2/c4cc9032-7eee-4a6e-ae8b-f384b1349bcf.woff2") format("woff2"), url("https://webapps-cdn.esri.com/CDN/fonts/v1.1.2/1b3078ef-2971-4c95-b6ca-13ab528758cb.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: SST Vietnamese;
  src: url("https://webapps-cdn.esri.com/CDN/fonts/v1.1.2/c1905e2e-a1cb-49de-9bb0-ce3c5ffc85ae.woff2") format("woff2"), url("https://webapps-cdn.esri.com/CDN/fonts/v1.1.2/341bcc5e-7ac0-44ff-819d-5887892eab1b.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: SST Vietnamese;
  src: url("https://webapps-cdn.esri.com/CDN/fonts/v1.1.2/4daa2125-53c6-4da8-9614-8a1049eaccc2.woff2") format("woff2"), url("https://webapps-cdn.esri.com/CDN/fonts/v1.1.2/0763eab1-d6ed-4c73-afb0-895f930df099.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

.avenir-light {
  font-style: normal;
  font-weight: 300;
}

.avenir-light-italic {
  font-style: italic;
  font-weight: 300;
}

.avenir-regular {
  font-style: normal;
  font-weight: 400;
}

.avenir-italic {
  font-style: italic;
  font-weight: 400;
}

.avenir-demi {
  font-style: normal;
  font-weight: 700;
}

.avenir-demi-italic {
  font-style: italic;
  font-weight: 700;
}

.avenir-bold {
  font-style: normal;
  font-weight: 700;
}

.avenir-bold-italic {
  font-style: italic;
  font-weight: 700;
}

.code-face, .code-face .block {
  letter-spacing: 0;
  font-family: Consolas, Andale Mono, Lucida Console, Monaco, monospace;
  font-style: normal;
  font-weight: 400;
}

.code-face b, .code-face strong, .code-face .block b, .code-face .block strong {
  font-weight: 400;
}

.code-italic, .code-italic .block {
  letter-spacing: 0;
  font-family: Consolas, Andale Mono, Lucida Console, Monaco, monospace;
  font-style: italic;
  font-weight: 400;
}

.code-italic b, .code-italic strong, .code-italic .block b, .code-italic .block strong {
  font-weight: 400;
}

html {
  font-size: 16px;
}

body {
  letter-spacing: 0;
  color: #4c4c4c;
  -webkit-font-smoothing: subpixel-antialiased;
  -webkit-font-feature-settings: "kern";
  -moz-font-feature-settings: "kern";
  font-feature-settings: "kern";
  font-kerning: normal;
  text-rendering: optimizelegibility;
  -moz-font-feature-settings: "liga= 1", "calt=0";
  -moz-font-feature-settings: "liga" 1, "calt" 0;
  -webkit-font-feature-settings: "liga" 1, "calt" 0;
  -ms-font-feature-settings: "liga= 1", "calt=0";
  -o-font-feature-settings: "liga" 1, "calt" 0;
  font-feature-settings: "liga" 1, "calt" 0;
  background-color: #fff;
  font-family: Avenir Next W01, Avenir Next W00, Avenir Next, Avenir, Helvetica Neue, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
}

form, select, button, input {
  letter-spacing: 0;
  font-family: inherit;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0 0 .75rem;
  font-weight: 400;
}

h1 {
  font-size: 2.40307rem;
  line-height: 1.35;
}

@media screen and (max-width: 859px) {
  h1 {
    font-size: 1.99974rem;
  }
}

@media screen and (max-width: 479px) {
  h1 {
    font-size: 1.66016rem;
  }
}

h2 {
  font-size: 1.9994rem;
  line-height: 1.35;
}

@media screen and (max-width: 859px) {
  h2 {
    font-size: 1.7689rem;
  }
}

@media screen and (max-width: 479px) {
  h2 {
    font-size: 1.5625rem;
  }
}

h3 {
  font-size: 1.69949rem;
  line-height: 1.3875;
}

@media screen and (max-width: 859px) {
  h3 {
    font-size: 1.50357rem;
  }
}

@media screen and (max-width: 479px) {
  h3 {
    font-size: 1.32812rem;
  }
}

h4 {
  font-size: 1.414rem;
  line-height: 1.5;
}

@media screen and (max-width: 859px) {
  h4 {
    font-size: 1.33rem;
  }
}

@media screen and (max-width: 479px) {
  h4 {
    font-size: 1.25rem;
  }
}

h5 {
  font-size: 1.2019rem;
  line-height: 1.5;
}

@media screen and (max-width: 859px) {
  h5 {
    font-size: 1.1305rem;
  }
}

@media screen and (max-width: 479px) {
  h5 {
    font-size: 1.0625rem;
  }
}

h6 {
  font-size: 1rem;
  line-height: 1.5;
}

code, kbd, pre, samp {
  font-family: Consolas, Andale Mono, Lucida Console, Monaco, monospace;
}

code {
  color: #595959;
  white-space: normal;
  word-break: break-word;
  background-color: #f8f8f8;
  border: 1px solid #efefef;
  border-radius: 3px;
  padding: .25em;
  font-size: .875em;
}

pre {
  white-space: pre;
  white-space: pre-wrap;
  word-wrap: break-word;
  max-width: 100%;
  margin-bottom: 1.5rem;
}

pre code {
  word-wrap: normal;
  white-space: pre;
  padding: 1.25rem 1rem 1rem;
  display: block;
  position: relative;
  overflow: auto;
}

p, dl, blockquote {
  margin-top: 0;
  margin-bottom: 1.5rem;
}

p:empty {
  margin: 0;
}

blockquote {
  color: #6e6e6e;
  border-left: 3px solid #ccc;
  margin: 1.5rem 0;
  font-style: normal;
  font-weight: 400;
}

html:not([dir="rtl"]) blockquote {
  padding-left: 1rem;
}

html[dir="rtl"] blockquote {
  border-left: none;
  border-right: 3px solid #ccc;
  padding-right: 1rem;
}

blockquote, blockquote p {
  font-size: 1.2019rem;
  line-height: 1.5;
}

@media screen and (max-width: 859px) {
  blockquote, blockquote p {
    font-size: 1.1305rem;
  }
}

@media screen and (max-width: 479px) {
  blockquote, blockquote p {
    font-size: 1.0625rem;
  }
}

a {
  color: #0079c1;
  text-decoration: none;
}

a:hover {
  color: #005e95;
  text-decoration: underline;
}

ul, ol {
  font-size: .9375rem;
  line-height: 1.5;
}

li {
  margin-top: .25em;
}

dl {
  margin-bottom: 1.5rem;
  padding: 0;
}

dt {
  margin-top: .75rem;
  font-style: normal;
  font-weight: 700;
}

dt:first-child {
  margin-top: 0;
}

dd {
  margin-left: .75rem;
}

html[dir="rtl"] dd {
  margin-right: .75rem;
}

small, sup, sub {
  font-size: .70721em;
}

sub, sup {
  vertical-align: baseline;
  line-height: 0;
  position: relative;
}

sup {
  top: -.5em;
}

sub {
  bottom: -.25em;
}

mark {
  padding: 0 .1em .1em;
}

hr {
  border: none;
  border-top: 1px solid #ccc;
  margin: 2.25rem 0;
}

figure {
  margin: 0 0 1.5rem;
}

figure img {
  max-width: 100%;
}

figcaption {
  color: #828282;
  margin: .375rem 0;
  font-size: .875rem;
  font-style: italic;
  line-height: 1.5;
}

table {
  margin-bottom: 1.5rem;
}

html[lang="vi"] body, html.vi body, [lang="vi"] {
  font-family: SST Vietnamese, Lucida Grande, Arial, Verdana, Geneva, sans-serif;
}

.icon-ui-flush:before, .icon-ui-flush:after {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

@font-face {
  font-family: calcite-ui;
  src: url("calcite-ui.4edcf662.eot#iefix") format("embedded-opentype"), url("calcite-ui.5cb576cf.woff") format("woff"), url("calcite-ui.e5f3ad00.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

[class^="icon-ui-"]:before, [class*=" icon-ui-"]:before {
  speak: none;
  font-variant: normal;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-right: .5rem;
  font-family: calcite-ui;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  display: inline-block;
}

html[dir="rtl"] [class^="icon-ui-"]:before, html[dir="rtl"] [class*=" icon-ui-"]:before {
  padding-left: .75rem;
  padding-right: 0;
}

.icon-ui-small:before {
  font-size: .75rem;
  line-height: 1.5;
}

.icon-ui-add-attachment:before {
  content: "";
}

.icon-ui-announcement:before {
  content: "";
}

.icon-ui-applications:before {
  content: "";
}

.icon-ui-arrow-down-circled:before {
  content: "";
}

.icon-ui-arrow-left-circled:before {
  content: "";
}

.icon-ui-arrow-right-circled:before {
  content: "";
}

.icon-ui-arrow-up-circled:before {
  content: "";
}

.icon-ui-attachment:before {
  content: "";
}

.icon-ui-authorize:before {
  content: "";
}

.icon-ui-basemap:before {
  content: "";
}

.icon-ui-beginning:before {
  content: "";
}

.icon-ui-blank-map-pin:before {
  content: "";
}

.icon-ui-browser:before {
  content: "";
}

.icon-ui-calendar:before {
  content: "";
}

.icon-ui-chart:before {
  content: "";
}

.icon-ui-chat:before {
  content: "";
}

.icon-ui-check-mark:before {
  content: "";
}

.icon-ui-checkbox-checked:before {
  content: "";
}

.icon-ui-checkbox-unchecked:before {
  content: "";
}

.icon-ui-close-circled:before {
  content: "";
}

.icon-ui-close:before {
  content: "";
}

.icon-ui-collapse:before {
  content: "";
}

.icon-ui-collection:before {
  content: "";
}

.icon-ui-comment:before {
  content: "";
}

.icon-ui-compass:before {
  content: "";
}

.icon-ui-configure-popup:before {
  content: "";
}

.icon-ui-contact:before {
  content: "";
}

.icon-ui-dashboard:before {
  content: "";
}

.icon-ui-deny:before {
  content: "";
}

.icon-ui-description:before {
  content: "";
}

.icon-ui-directions:before {
  content: "";
}

.icon-ui-directions2:before {
  content: "";
}

.icon-ui-dock-bottom:before {
  content: "";
}

.icon-ui-dock-left:before {
  content: "";
}

.icon-ui-dock-right:before {
  content: "";
}

.icon-ui-documentation:before {
  content: "";
}

.icon-ui-down-arrow-circled:before {
  content: "";
}

.icon-ui-down-arrow:before {
  content: "";
}

.icon-ui-down:before {
  content: "";
}

.icon-ui-download:before {
  content: "";
}

.icon-ui-drag-horizontal:before {
  content: "";
}

.icon-ui-drag-vertical:before {
  content: "";
}

.icon-ui-duplicate:before {
  content: "";
}

.icon-ui-edit:before {
  content: "";
}

.icon-ui-end:before {
  content: "";
}

.icon-ui-environment-settings:before {
  content: "";
}

.icon-ui-erase:before {
  content: "";
}

.icon-ui-error:before {
  content: "";
}

.icon-ui-error2:before {
  content: "";
}

.icon-ui-expand:before {
  content: "";
}

.icon-ui-experimental:before {
  content: "";
}

.icon-ui-favorites:before {
  content: "";
}

.icon-ui-feature-layer:before {
  content: "";
}

.icon-ui-filter:before {
  content: "";
}

.icon-ui-forward:before {
  content: "";
}

.icon-ui-globe:before {
  content: "";
}

.icon-ui-grant:before {
  content: "";
}

.icon-ui-group:before {
  content: "";
}

.icon-ui-handle-horizontal:before {
  content: "";
}

.icon-ui-handle-vertical:before {
  content: "";
}

.icon-ui-home:before {
  content: "";
}

.icon-ui-key:before {
  content: "";
}

.icon-ui-labels:before {
  content: "";
}

.icon-ui-layer-list:before {
  content: "";
}

.icon-ui-layers:before {
  content: "";
}

.icon-ui-left-arrow-circled:before {
  content: "";
}

.icon-ui-left-arrow:before {
  content: "";
}

.icon-ui-left-triangle-arrow:before {
  content: "";
}

.icon-ui-left:before {
  content: "";
}

.icon-ui-line-chart:before {
  content: "";
}

.icon-ui-link-external:before {
  content: "";
}

.icon-ui-link:before {
  content: "";
}

.icon-ui-loading-indicator:before {
  content: "";
}

.icon-ui-locate:before {
  content: "";
}

.icon-ui-locked:before {
  content: "";
}

.icon-ui-map-pin:before {
  content: "";
}

.icon-ui-maps:before {
  content: "";
}

.icon-ui-marketplace:before {
  content: "";
}

.icon-ui-maximize:before {
  content: "";
}

.icon-ui-media:before {
  content: "";
}

.icon-ui-media2:before {
  content: "";
}

.icon-ui-menu:before {
  content: "";
}

.icon-ui-minimize:before {
  content: "";
}

.icon-ui-minus-circled:before {
  content: "";
}

.icon-ui-minus:before {
  content: "";
}

.icon-ui-mobile:before {
  content: "";
}

.icon-ui-navigation:before {
  content: "";
}

.icon-ui-notice-round:before {
  content: "";
}

.icon-ui-notice-triangle:before {
  content: "";
}

.icon-ui-organization:before {
  content: "";
}

.icon-ui-overview-arrow-bottom-left:before {
  content: "";
}

.icon-ui-overview-arrow-bottom-right:before {
  content: "";
}

.icon-ui-overview-arrow-top-left:before {
  content: "";
}

.icon-ui-overview-arrow-top-right:before {
  content: "";
}

.icon-ui-pan:before {
  content: "";
}

.icon-ui-pause:before {
  content: "";
}

.icon-ui-phone:before {
  content: "";
}

.icon-ui-pie-chart:before {
  content: "";
}

.icon-ui-play:before {
  content: "";
}

.icon-ui-plus-circled:before {
  content: "";
}

.icon-ui-plus:before {
  content: "";
}

.icon-ui-printer:before {
  content: "";
}

.icon-ui-question:before {
  content: "";
}

.icon-ui-radio-checked:before {
  content: "";
}

.icon-ui-radio-unchecked:before {
  content: "";
}

.icon-ui-refresh:before {
  content: "";
}

.icon-ui-resend-invitation:before {
  content: "";
}

.icon-ui-reverse:before {
  content: "";
}

.icon-ui-review:before {
  content: "";
}

.icon-ui-right-arrow-circled:before {
  content: "";
}

.icon-ui-right-arrow:before {
  content: "";
}

.icon-ui-right-triangle-arrow:before {
  content: "";
}

.icon-ui-right:before {
  content: "";
}

.icon-ui-rotate:before {
  content: "";
}

.icon-ui-save:before {
  content: "";
}

.icon-ui-search:before {
  content: "";
}

.icon-ui-settings:before {
  content: "";
}

.icon-ui-settings2:before {
  content: "";
}

.icon-ui-share:before {
  content: "";
}

.icon-ui-sign-out:before {
  content: "";
}

.icon-ui-support:before {
  content: "";
}

.icon-ui-table:before {
  content: "";
}

.icon-ui-tag:before {
  content: "";
}

.icon-ui-time-clock:before {
  content: "";
}

.icon-ui-trash:before {
  content: "";
}

.icon-ui-unlocked:before {
  content: "";
}

.icon-ui-up-arrow-circled:before {
  content: "";
}

.icon-ui-up-arrow:before {
  content: "";
}

.icon-ui-up-down-arrows:before {
  content: "";
}

.icon-ui-up:before {
  content: "";
}

.icon-ui-upload:before {
  content: "";
}

.icon-ui-user:before {
  content: "";
}

.icon-ui-zoom-in-fixed:before {
  content: "";
}

.icon-ui-zoom-in-magnifying-glass:before {
  content: "";
}

.icon-ui-zoom-out-fixed:before {
  content: "";
}

.icon-ui-zoom-out-magnifying-glass:before {
  content: "";
}

.svg-icon {
  fill: currentColor;
  pointer-events: none;
  vertical-align: middle;
  width: 1em;
  height: 1em;
  padding-right: .15em;
  display: inline-block;
}

.svg-icon-yellow {
  fill: #fad817;
}

.svg-icon-green {
  fill: #5a9359;
}

.svg-icon-blue {
  fill: #0079c1;
}

.svg-icon-light-blue {
  fill: #56a5d8;
}

.svg-icon-dark-blue {
  fill: #005e95;
}

.svg-icon-purple {
  fill: #9081bc;
}

.svg-icon-orange {
  fill: #d17e21;
}

.svg-icon-gray {
  fill: #959595;
}

.svg-icon-red {
  fill: #de2900;
}

.esri-icon-yellow:before, .icon-ui-yellow:before {
  color: #fad817;
}

.esri-icon-green:before, .icon-ui-green:before {
  color: #5a9359;
}

.esri-icon-blue:before, .icon-ui-blue:before {
  color: #0079c1;
}

.esri-icon-light-blue:before, .icon-ui-light-blue:before {
  color: #56a5d8;
}

.esri-icon-dark-blue:before, .icon-ui-dark-blue:before {
  color: #005e95;
}

.esri-icon-purple:before, .icon-ui-purple:before {
  color: #9081bc;
}

.esri-icon-orange:before, .icon-ui-orange:before {
  color: #d17e21;
}

.esri-icon-gray:before, .icon-ui-gray:before {
  color: #959595;
}

.esri-icon-red:before, .icon-ui-red:before {
  color: #de2900;
}

[class*="icon-social-"] {
  vertical-align: middle;
  background-color: #00000073;
  background-repeat: no-repeat;
  width: 30px;
  height: 30px;
  transition: background-color .2s linear;
  display: inline-block;
}

[class*="icon-social-"]:hover {
  transition: background-color .2s linear;
}

.icon-social-contact {
  background-image: url("contact.dc3d4e1f.svg");
}

.icon-social-contact:hover {
  background-color: #8ec449;
}

.icon-social-facebook {
  background-image: url("facebook.a6c7e848.svg");
}

.icon-social-facebook:hover {
  background-color: #3d5a96;
}

.icon-social-geonet {
  background-image: url("geonet.200e5b8e.svg");
}

.icon-social-geonet:hover {
  background-color: #127bc0;
}

.icon-social-github {
  background-image: url("github.da4ced4b.svg");
}

.icon-social-github:hover {
  background-color: #171515;
}

.icon-social-google-plus {
  background-image: url("google-plus.101a9395.svg");
}

.icon-social-google-plus:hover {
  background-color: #dd4c3c;
}

.icon-social-instagram {
  background-image: url("instagram.06300100.svg");
}

.icon-social-instagram:hover {
  background-color: #da3075;
}

.icon-social-linkedin {
  background-image: url("linkedin.0ab7809e.svg");
}

.icon-social-linkedin:hover {
  background-color: #127cb3;
}

.icon-social-pinterest {
  background-image: url("pinterest.9cc65496.svg");
}

.icon-social-pinterest:hover {
  background-color: #bb0f23;
}

.icon-social-rss {
  background-image: url("rss.c735ceef.svg");
}

.icon-social-rss:hover {
  background-color: #fd8023;
}

.icon-social-share {
  background-image: url("share.13c9437c.svg");
}

.icon-social-share:hover {
  background-color: #1ebd21;
}

.icon-social-twitter {
  background-image: url("twitter.cafd9321.svg");
}

.icon-social-twitter:hover {
  background-color: #59adeb;
}

.icon-social-youtube {
  background-image: url("youtube.b99bea11.svg");
}

.icon-social-youtube:hover {
  background-color: #cf2529;
}

.esri-logo {
  content: "";
  background-color: #0000;
  background-image: url("esri-logo.705a4f25.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  min-width: 70px;
  min-height: 30px;
  margin: .375rem;
  line-height: 0;
  display: inline-block;
}

.esri-logo-reverse {
  content: "";
  background-color: #0000;
  background-image: url("esri-logo-reversed.a86b28db.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  min-width: 70px;
  min-height: 30px;
  margin: .375rem;
  line-height: 0;
  display: inline-block;
}

.esri-logo-condensed {
  margin: -.135rem 0 -.125rem;
}

.btn {
  color: #fff;
  box-sizing: border-box;
  cursor: pointer;
  background-color: #0079c1;
  border: 1px solid #0079c1;
  border-radius: 0;
  width: auto;
  padding: .375rem 1rem;
  font-size: .9375rem;
  line-height: 1.5;
  transition: all 50ms linear;
  display: inline-block;
  position: relative;
}

.btn:hover {
  color: #fff;
  background-color: #005e95;
  border-color: #005e95;
  text-decoration: none;
}

.btn-transparent {
  color: #0079c1;
  background: none;
  border: none;
}

.btn-transparent:hover {
  color: #005e95;
  background: none;
  text-decoration: underline;
}

.btn-clear {
  color: #0079c1;
  background: #fff;
  border-color: #0079c1;
}

.btn-clear:hover {
  color: #fff;
  background: #005e95;
  border-color: #005e95;
}

.btn-clear-white {
  color: #fff;
  background: none;
  border: 1px solid #fff;
}

.btn-clear-white:hover {
  color: #959595;
  background: #fff;
  border-color: #fff;
}

.btn-small {
  padding: .28125rem .75rem;
  font-size: .875rem;
  line-height: 1.5;
}

.btn-large {
  padding: .5rem 1rem;
  font-size: 1rem;
  line-height: 1.5;
}

.btn-fill {
  text-align: center;
  width: 100%;
}

.btn-half {
  text-align: center;
  width: 50%;
}

.btn-grouped {
  float: left;
}

html[dir="rtl"] .btn-grouped {
  float: right;
}

.btn-grouped + .btn-grouped {
  margin-left: 1px;
}

html[dir="rtl"] .btn-grouped + .btn-grouped {
  margin-left: 0;
  margin-right: 1px;
}

.btn-grouped:last-of-type {
  float: none;
}

.btn-grouped.btn-half {
  margin-left: 0;
}

.btn-white {
  color: #4c4c4c;
  background: #fff;
  border: 1px solid #fff;
}

.btn-white:hover {
  color: #4c4c4c;
  background: #efefef;
  border-color: #efefef;
}

.btn-red {
  color: #de2900;
  background: none;
  border-color: #de2900;
}

.btn-red:hover {
  color: #fff;
  background: #ab3c16;
  border-color: #ab3c16;
}

.btn-green {
  background-color: #5a9359;
  border-color: #5a9359;
}

.btn-green:hover {
  background-color: #338033;
  border-color: #338033;
}

.btn-link {
  -webkit-appearance: none;
  cursor: pointer;
  color: #0079c1;
  background: none;
  border: none;
  padding: 0;
  font-size: 1rem;
  line-height: 1.5;
}

.btn-link:hover {
  text-decoration: underline;
}

.btn:disabled, .btn-disabled, button[disabled] {
  cursor: not-allowed;
  pointer-events: none;
  opacity: .3;
}

input[type="file"]::-webkit-file-upload-button {
  webkit-appearance: none;
  color: #fff;
  box-sizing: border-box;
  cursor: pointer;
  background-color: #0079c1;
  border: 1px solid #0079c1;
  border-radius: 0;
  width: auto;
  padding: .375rem 1rem;
  font-size: .9375rem;
  line-height: 1.5;
  transition: all 50ms linear;
  display: inline-block;
  position: relative;
}

::-ms-browse {
  webkit-appearance: none;
  color: #fff;
  box-sizing: border-box;
  cursor: pointer;
  background-color: #0079c1;
  border: 1px solid #0079c1;
  border-radius: 0;
  width: auto;
  padding: .375rem 1rem;
  font-size: .9375rem;
  line-height: 1.5;
  transition: all 50ms linear;
  display: inline-block;
  position: relative;
}

input[type="file"]::-webkit-file-upload-button:hover {
  color: #fff;
  background-color: #005e95;
  border-color: #005e95;
  text-decoration: none;
}

::-ms-browse:hover {
  color: #fff;
  background-color: #005e95;
  border-color: #005e95;
  text-decoration: none;
}

.label {
  white-space: nowrap;
  background-color: #efefef;
  border-radius: 3px;
  padding: .25em .5em;
  font-size: .875em;
}

.label-red {
  color: #fff;
  background-color: #de2900;
}

.label-yellow {
  color: #4c4c4c;
  background-color: #efe397;
}

.label-green {
  color: #fff;
  background-color: #338033;
}

.label-blue {
  color: #fff;
  background-color: #0079c1;
}

.dropdown {
  display: inline-block;
  position: relative;
}

.dropdown.is-active .dropdown-menu {
  display: block;
}

.dropdown-btn else {
  cursor: pointer;
  position: relative;
}

.dropdown-menu {
  z-index: 1000;
  min-width: 200px;
  display: none;
  position: absolute;
  overflow: auto;
  box-shadow: 0 0 16px #0000000d;
}

.dropdown-menu.dropdown-right {
  left: auto;
  right: 0;
}

html[dir="rtl"] .dropdown-menu.dropdown-right {
  left: 0;
  right: auto;
}

.dropdown-title {
  box-sizing: border-box;
  color: #6e6e6e;
  text-align: left;
  white-space: nowrap;
  cursor: pointer;
  cursor: auto;
  background-color: #f8f8f8;
  border: none;
  border-top: 1px solid #efefef;
  width: 100%;
  padding: .5rem;
  font-size: .875rem;
  line-height: 1.5;
  display: block;
  position: relative;
}

html[dir="rtl"] .dropdown-title {
  text-align: right;
}

.dropdown-title:hover {
  color: #6e6e6e;
  background-color: #f8f8f8;
  text-decoration: none;
}

.dropdown-title.is-active, .dropdown-title:focus {
  text-indent: -3px;
  border-left: 3px solid #0079c1;
}

html[dir="rtl"] .dropdown-title.is-active, html[dir="rtl"] .dropdown-title:focus {
  border-left: none;
  border-right: 3px solid #0079c1;
}

.dropdown-link {
  box-sizing: border-box;
  color: #6e6e6e;
  text-align: left;
  white-space: nowrap;
  cursor: pointer;
  background-color: #fff;
  border: none;
  border-top: 1px solid #efefef;
  width: 100%;
  padding: .5rem;
  font-size: .875rem;
  line-height: 1.5;
  display: block;
  position: relative;
}

html[dir="rtl"] .dropdown-link {
  text-align: right;
}

.dropdown-link:hover {
  color: #6e6e6e;
  background-color: #f8f8f8;
  text-decoration: none;
}

.dropdown-link.is-active, .dropdown-link:focus {
  text-indent: -3px;
  border-left: 3px solid #0079c1;
}

html[dir="rtl"] .dropdown-link.is-active, html[dir="rtl"] .dropdown-link:focus {
  border-left: none;
  border-right: 3px solid #0079c1;
}

.breadcrumbs {
  color: #6e6e6e;
  font-size: .875rem;
  line-height: 1.5;
}

.crumb {
  color: #6e6e6e;
  float: left;
}

html[dir="rtl"] .crumb {
  float: right;
}

.crumb:before {
  content: "/";
  color: #6e6e6e;
  padding: 0 .5rem;
  font-weight: 400;
  display: inline-block;
}

.crumb.is-active {
  font-weight: 700;
}

.breadcrumbs-white .crumb {
  color: #fff;
}

.breadcrumbs-white .crumb:hover {
  color: #efefef;
}

.breadcrumbs-white .crumb:before {
  color: #fff;
}

.crumb:first-child:before {
  display: none;
}

.tooltip {
  cursor: pointer;
  position: relative;
}

.tooltip:after {
  content: attr(aria-label);
  z-index: 1000000;
  color: #fff;
  text-align: center;
  word-wrap: break-word;
  white-space: pre;
  pointer-events: none;
  background: #000000bf;
  border-radius: 3px;
  padding: .375rem .75rem;
  font-size: .875rem;
  line-height: 1.5;
  display: none;
  position: absolute;
}

.tooltip:before {
  content: "";
  z-index: 1000001;
  color: #000000bf;
  pointer-events: none;
  border: 5px solid #0000;
  width: 0;
  height: 0;
  display: none;
  position: absolute;
}

.tooltip:hover, .tooltip:active, .tooltip:focus {
  text-decoration: none;
}

.tooltip:hover:before, .tooltip:hover:after, .tooltip:active:before, .tooltip:active:after, .tooltip:focus:before, .tooltip:focus:after {
  display: inline-block;
}

.tooltip-multiline:hover:after, .tooltip-multiline:active:after, .tooltip-multiline:focus:after {
  display: table-cell;
}

.tooltip:after {
  margin-top: 5px;
  line-height: 1.5;
  top: 100%;
  right: 50%;
}

.tooltip:before {
  border-bottom-color: #000000bf;
  margin-right: -5px;
  top: auto;
  bottom: -5px;
  right: 50%;
}

.tooltip-top:after {
  margin-bottom: 5px;
  top: auto;
  bottom: 100%;
  right: 50%;
}

.tooltip-top:before {
  border-top-color: #000000bf;
  border-bottom: 5px solid #0000;
  margin-right: -5px;
  top: -5px;
  bottom: auto;
  right: 50%;
}

.tooltip:after, .tooltip-bottom:after {
  transform: translateX(50%);
}

.tooltip-left:after {
  margin-right: 5px;
  margin-bottom: inherit;
  top: auto;
  bottom: 50%;
  left: auto;
  right: 100%;
  transform: translateY(50%);
}

html[dir="rtl"] .tooltip-left:after {
  margin-left: 5px;
  margin-right: 0;
  left: 100%;
  right: auto;
}

.tooltip-left:before {
  border-bottom: 5px solid #0000;
  border-left-color: #000000bf;
  margin-top: -5px;
  top: 50%;
  bottom: 50%;
  left: -5px;
  right: auto;
}

html[dir="rtl"] .tooltip-left:before {
  border-left-color: #0000;
  border-right-color: #000000bf;
  margin-right: 0;
  left: auto;
  right: -5px;
}

.tooltip-right:after {
  margin-left: 5px;
  top: auto;
  bottom: 50%;
  left: 100%;
  right: auto;
  transform: translateY(50%);
}

html[dir="rtl"] .tooltip-right:after {
  margin-left: 0;
  margin-right: 5px;
  left: auto;
  right: 100%;
}

.tooltip-right:before {
  border-bottom: 5px solid #0000;
  border-right-color: #000000bf;
  margin-top: -5px;
  margin-right: 0;
  top: 50%;
  bottom: 50%;
  left: auto;
  right: -5px;
}

html[dir="rtl"] .tooltip-right:before {
  border-left-color: #000000bf;
  border-right-color: #0000;
  left: -5px;
  right: auto;
}

.tooltip-multiline:after {
  word-break: break-word;
  word-wrap: normal;
  white-space: pre-line;
  border-collapse: separate;
  width: -webkit-max-content;
  max-width: 250px;
}

.tooltip-multiline.tooltip-bottom:after, .tooltip-multiline.tooltip-top:after {
  left: 50%;
  right: auto;
  transform: translateX(-50%);
}

.tooltip-multiline.tooltip-left:after, .tooltip-multiline.tooltip-right:after {
  right: 100%;
}

@media screen and (min-width: 0) {
  .tooltip-multiline:after {
    width: 250px;
  }
}

.alert {
  color: #4c4c4c;
  z-index: 100;
  background-color: #d2e9f9;
  border: 1px solid #0079c1;
  max-width: 40em;
  padding: .75rem;
  font-size: .9375rem;
  line-height: 1.5;
  display: none;
  position: relative;
  box-shadow: 0 0 16px #0000000d;
}

.alert:hover {
  color: #323232;
}

.alert.is-active {
  display: block;
}

.alert a:hover {
  color: #000000bf;
}

.alert-red {
  background-color: #f3ded7;
  border-color: #e4a793;
}

.alert-yellow {
  background-color: #f3edc7;
  border-color: #e4d154;
}

.alert-green {
  background-color: #ddeedb;
  border-color: #9bc19c;
}

.alert-close {
  -webkit-appearance: none;
  cursor: pointer;
  color: currentColor;
  background: none;
  border: none;
  padding: 0;
  font-size: 1rem;
  line-height: 1.5;
  position: absolute;
  left: auto;
  right: .75rem;
}

.alert-close:hover {
  text-decoration: underline;
}

html[dir="rtl"] .alert-close {
  left: .75rem;
  right: auto;
}

.alert-full {
  max-width: none;
}

.panel {
  background-color: #f8f8f8;
  border: 1px solid #efefef;
  padding: .99rem;
}

.panel:after {
  content: "";
  clear: both;
  display: table;
}

.panel code {
  background-color: #fff;
}

.panel-black {
  color: #fff;
  background-color: #323232;
  border: 1px solid #4c4c4c;
}

.panel-dark {
  color: #fff;
  background-color: #595959;
  border: 1px solid #4c4c4c;
}

.panel-white {
  background-color: #fff;
  border: 1px solid #efefef;
}

.panel-white code {
  background-color: #f8f8f8;
}

.panel-dark-blue {
  color: #fff;
  background-color: #004575;
  border: 1px solid #052942;
}

.panel-blue {
  color: #fff;
  background-color: #0079c1;
  border: 1px solid #005e95;
}

.panel-light-blue {
  color: #323232;
  background-color: #56a5d8;
  border: 1px solid #0079c1;
}

.panel-no-border {
  border: none;
}

.panel-no-padding {
  padding: 0;
}

.table {
  border-collapse: collapse;
  border-spacing: 0;
  text-align: left;
  background-color: #fff;
  border: 1px solid #ccc;
  width: 100%;
  font-size: .875rem;
  line-height: 1.5;
  overflow: auto;
}

html[dir="rtl"] .table {
  text-align: right;
}

.table > thead {
  background-color: #efefef;
  border-bottom: 1px solid #ccc;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
}

.table > tbody {
  width: 100%;
  overflow: auto;
}

.table > tfoot {
  background-color: #efefef;
  border-top: 1px solid #ccc;
}

.table th, .table td {
  text-align: left;
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  padding: .5rem;
  font-weight: 300;
}

html[dir="rtl"] .table th, html[dir="rtl"] .table td {
  text-align: right;
}

.table tr {
  text-align: left;
  border-bottom: 1px solid #ccc;
}

.table tr:last-child {
  border-bottom: none;
}

.table caption {
  color: #959595;
  caption-side: bottom;
  padding: .375rem .75rem;
  line-height: 1.5rem;
}

.table-blue {
  border: 1px solid #0079c1;
}

.table-blue > thead {
  color: #fff;
  background-color: #0079c1;
  border: none;
  border-bottom: 1px solid #0079c1;
}

.table-blue > thead tr:nth-child(2n) {
  color: #4c4c4c;
  background-color: #bcdaed;
}

.table-blue > tfoot {
  border-top: 1px solid #0079c1;
}

.table-blue th, .table-blue td {
  border-left: 1px solid #0079c1;
  border-right: 1px solid #0079c1;
}

.table-blue tr {
  border-bottom: 1px solid #0079c1;
}

.table-plain {
  border: none;
}

.table-plain > thead, .table-plain > tfoot, .table-plain th, .table-plain td {
  background-color: #0000;
  border: none;
}

.table-plain tr {
  border: none;
}

.table-plain tr:nth-child(2n), .table-plain tr:nth-child(2n) td {
  background-color: #0000;
}

.table-striped tr:nth-child(2n) {
  background-color: #f8f8f8;
}

.table-striped tr:nth-child(2n) a {
  color: #005e95;
}

.table-striped tr:nth-child(2n) td {
  background-color: #f8f8f8;
}

.table-striped.table-blue tr:nth-child(2n), .table-striped.table-blue tr:nth-child(2n) td {
  background-color: #d2e9f9;
}

.table-no-table {
  border-spacing: 0;
  text-align: left;
  background-color: #0000;
  border: none;
  width: auto;
  margin-bottom: 0;
  overflow: auto;
}

.table-no-table thead, .table-no-table tbody {
  background-color: #0000;
  border: none;
  width: 100%;
  font-size: 1rem;
  line-height: 1.5;
  overflow: auto;
}

.table-no-table th, .table-no-table td {
  background-color: #0000;
  border: none;
}

.table-no-table tr {
  text-align: left;
  border: none;
}

.input-search, .search-input {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0iIzZlNmU2ZSI+PHBhdGggZD0iTTE0LjY1IDEzLjc0TDkuNzMyIDguODIzQTUuMzg3IDUuMzg3IDAgMSAwIDUuNSAxMC45MDRhNS4zNTggNS4zNTggMCAwIDAgMy4yODgtMS4xNDJsNC45MiA0Ljkyek01LjUgOS4yNWEzLjc1IDMuNzUgMCAxIDEgMy43NS0zLjc1MSAzLjc1MiAzLjc1MiAwIDAgMS0zLjc1IDMuNzV6Ii8+PC9zdmc+Cg==");
  background-position: .25em;
  background-repeat: no-repeat;
  background-size: 1.06667em;
  padding-left: 1.5em;
}

html[dir="rtl"] .input-search, html[dir="rtl"] .search-input {
  background-position: right .25em center;
  padding-left: .3rem;
  padding-right: 1.5em;
}

form {
  margin: 0;
  padding: 0;
}

fieldset {
  border: none;
  margin: 0 0 1.5rem;
  padding: 0;
  display: block;
}

label {
  margin: 0 0 1.5rem;
  display: block;
  position: relative;
}

legend {
  margin-bottom: .25rem;
  display: block;
  position: relative;
}

input, select, textarea {
  box-sizing: border-box;
  color: #4c4c4c;
  vertical-align: baseline;
  outline-offset: 0;
  background-color: #fff;
  border: 1px solid #a9a9a9;
  border-radius: 0;
  outline: none;
  width: 100%;
  max-width: 100%;
  height: 2.25rem;
  margin: .25rem 0 0;
  padding: 0 .3rem;
  font-family: inherit;
  font-size: .9375rem;
  line-height: 1.25rem;
  transition: border-color .15s linear;
  display: block;
  position: relative;
  box-shadow: inset 0 1px 2px #00000013;
}

input:disabled, select:disabled, textarea:disabled {
  opacity: .3;
  cursor: default;
}

input:focus, select:focus, textarea:focus {
  border-color: #0079c1;
  box-shadow: inset 0 1px 2px #00000013, 0 0 5px #51a7e880;
}

input {
  -webkit-appearance: none;
}

textarea {
  height: auto;
  padding-top: .3rem;
}

:placeholder {
  color: #767676;
}

:input-placeholder {
  color: #767676;
}

::-webkit-input-placeholder {
  color: #767676;
}

::placeholder {
  color: #767676;
}

:-ms-input-placeholder {
  color: #767676;
}

.input-minimal {
  box-shadow: none;
  background-color: #0000;
  border-color: currentColor currentColor #ccc;
  border-top-style: none;
  border-top-width: medium;
  border-left-style: none;
  border-left-width: medium;
  border-right-style: none;
  border-right-width: medium;
}

.input-minimal:focus {
  box-shadow: none;
  border-bottom-color: #999;
}

.input-error {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNHB4IiBoZWlnaHQ9IjE2cHgiIHZpZXdCb3g9IjAgMCAxNiAxNiIgeD0iMiIgeT0iMCI+PHBhdGggZmlsbD0iI2M4NmE0YSIgZD0iTTExLjIyNSwwSDQuNjQ4TDAsNC42NXY2LjU3Nmw0LjY0OCw0LjY0OWg2LjU3Nmw0LjY1LTQuNjQ5VjQuNjVMMTEuMjI1LDB6IE05LjA3MSwxMy42MDdINi44MDR2LTIuMjY5aDIuMjY4VjEzLjYwN3ogTTkuMDcxLDkuMDcxSDYuODA0VjMuNDAyaDIuMjY4VjkuMDcxeiIvPjwvc3ZnPg==");
  background-position: 100%;
  background-repeat: no-repeat;
  border-color: #c86a4a;
  padding-right: 24px;
}

.input-error:focus {
  border-color: #c86a4a;
  box-shadow: inset 0 1px 2px #00000013, 0 0 5px #c86a4b80;
}

.input-success {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHg9IjBweCIgeT0iMHB4IiB3aWR0aD0iMjRweCIgaGVpZ2h0PSIxNnB4IiB2aWV3Qm94PSIwIDAgMjQgMTYiPjxwb2x5Z29uIGZpbGw9IiM1QTkzNTkiIHBvaW50cz0iOC4xODgsMTEuMDgyIDQuMDU5LDYuOTUyIDIuMjU4LDguNzUyIDguMTg4LDE0LjY4MyAxOS43NTQsMy4xMTkgMTcuOTU0LDEuMzE3ICIvPjwvc3ZnPg==");
  background-position: 100%;
  background-repeat: no-repeat;
  padding-right: 24px;
}

html[dir="rtl"] .input-error, html[dir="rtl"] .input-success {
  background-position: 0;
  padding-left: 24px;
  padding-right: .3rem;
}

.input-error-message {
  color: #000000bf;
  background-color: #f3ded7;
  border: 1px solid #e4a793;
  margin-top: .5rem;
  padding: .375rem;
  font-size: .875rem;
  line-height: 1.5;
  display: none;
  position: relative;
}

.input-error-message:after {
  content: "";
  pointer-events: none;
  border: 8px solid #0000;
  border-bottom-color: #f3ded7;
  width: 0;
  height: 0;
  position: absolute;
  top: -15px;
}

.input-error-message:before {
  content: "";
  pointer-events: none;
  border: 8px solid #0000;
  border-bottom-color: #e4a793;
  width: 0;
  height: 0;
  position: absolute;
  top: -16px;
}

.input-error-message:after, .input-error-message:before {
  left: 11px;
  right: auto;
}

html[dir="rtl"] .input-error-message:after, html[dir="rtl"] .input-error-message:before {
  left: auto;
  right: 11px;
}

.input-error-message.is-active, .input-error-message.is-active:before, .input-error-message.is-active:after {
  display: inline-block;
}

input[type="file"] {
  height: auto;
}

input[type="file"]:active {
  border-color: #0079c1;
  box-shadow: inset 0 1px 2px #00000013, 0 0 5px #51a7e880;
}

@supports ((-moz-appearance: none)) {
  input[type="file"] {
    padding: .75rem .375rem;
  }
}

input[type="file"]::-webkit-file-upload-button {
  color: #0079c1;
  box-sizing: border-box;
  cursor: pointer;
  -webkit-appearance: none;
  background: #fff;
  border: 1px solid #0079c1;
  border-radius: 0;
  width: auto;
  margin: .5em 0;
  padding: .28125rem .75rem;
  font-family: inherit;
  font-size: .875rem;
  line-height: 1.5;
  transition: all 50ms linear;
  display: inline-block;
  position: relative;
}

input[type="file"]::-webkit-file-upload-button:hover {
  color: #fff;
  background-color: #005e95;
  border-color: #005e95;
  text-decoration: none;
}

input[type="file"]::-webkit-file-upload-button:hover {
  color: #fff;
  background: #005e95;
  border-color: #005e95;
}

::-ms-value {
  background-color: #0000;
  border: none;
}

::-ms-browse {
  color: #0079c1;
  box-sizing: border-box;
  cursor: pointer;
  background: #fff;
  border: 1px solid #0079c1;
  border-radius: 0;
  width: auto;
  margin: .5em 0;
  padding: .28125rem .75rem;
  font-family: inherit;
  font-size: .875rem;
  line-height: 1.5;
  transition: all 50ms linear;
  display: inline-block;
  position: relative;
}

::-ms-browse:hover {
  color: #fff;
  background-color: #005e95;
  border-color: #005e95;
  text-decoration: none;
}

::-ms-browse:hover {
  color: #fff;
  background: #005e95;
  border-color: #005e95;
}

input[type="radio"], input[type="checkbox"] {
  float: left;
  box-shadow: none;
  box-sizing: content-box;
  width: 1rem;
  height: 1rem;
  margin-top: .12rem;
  margin-left: .125rem;
  margin-right: .5rem;
  line-height: 1.25rem;
}

html[dir="rtl"] input[type="radio"], html[dir="rtl"] input[type="checkbox"] {
  float: right;
}

input[type="radio"]:focus, input[type="checkbox"]:focus {
  box-shadow: none;
  outline: 1px dotted;
  outline: auto -webkit-focus-ring-color;
  border: none;
}

input[type="checkbox"] {
  -webkit-appearance: checkbox;
}

input[type="radio"] {
  -webkit-appearance: radio;
  border-radius: 50%;
}

@supports (-ms-ime-align: auto) {
  input[type="radio"], input[type="checkbox"] {
    margin-top: .125rem;
    margin-right: .5rem;
  }
}

@supports ((-moz-appearance: none)) {
  input[type="radio"], input[type="checkbox"] {
    margin-top: .05rem;
  }
}

@supports (-webkit-overflow-scrolling: touch) {
  input[type="radio"], input[type="checkbox"] {
    margin-top: .1rem;
    margin-right: 0;
  }

  input[type="radio"]:checked, input[type="checkbox"]:checked {
    background-color: #56a5d8;
    border-color: #196fa6;
  }
}

html[dir="rtl"] input[type="radio"], html[dir="rtl"] input[type="checkbox"] {
  margin-left: .5rem;
  margin-right: .125rem;
}

@supports (-ms-ime-align: auto) {
  html[dir="rtl"] input[type="radio"], html[dir="rtl"] input[type="checkbox"] {
    margin-left: .5rem;
  }
}

@supports (-webkit-overflow-scrolling: touch) {
  html[dir="rtl"] input[type="radio"], html[dir="rtl"] input[type="checkbox"] {
    margin-left: 0;
  }
}

.fieldset-radio label, .fieldset-checkbox label {
  color: #595959;
  width: auto;
  margin-bottom: .25rem;
  font-size: .9375rem;
  line-height: 1.25rem;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxNi4wLjQsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB3aWR0aD0iMTAwcHgiIGhlaWdodD0iMTAwcHgiIHZpZXdCb3g9IjAgMCAxMDAgMTAwIiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCAxMDAgMTAwIiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxnPg0KCTxwYXRoIGZpbGw9IiM1OTU5NTkiIGQ9Ik03NS43NDksMzcuNDY2YzAuNDI1LDAuNDI1LDAuNTUyLDEuMDYzLDAuMzIyLDEuNjE4Qzc1Ljg0MSwzOS42MzksNzUuMzAxLDQwLDc0LjY5OSw0MGgtNDkuNA0KCQljLTAuNiwwLTEuMTQzLTAuMzYyLTEuMzcyLTAuOTE3Yy0wLjIzLTAuNTU1LTAuMTAzLTEuMTkzLDAuMzIyLTEuNjE4bDIzLjQ0LTIzLjQ0YzEuMjc2LTEuMjc2LDMuMzQzLTEuMjc2LDQuNjIsMEw3NS43NDksMzcuNDY2DQoJCUw3NS43NDksMzcuNDY2eiBNMjQuMjUsNjIuNTM0Yy0wLjQyNi0wLjQyNS0wLjU1My0xLjA2My0wLjMyMy0xLjYxOGMwLjIzLTAuNTU1LDAuNzctMC45MTYsMS4zNy0wLjkxNkg3NC43DQoJCWMwLjYwMiwwLDEuMTQzLDAuMzU5LDEuMzczLDAuOTE2YzAuMjMsMC41NTUsMC4xMDMsMS4xOTMtMC4zMjIsMS42MThMNTIuMzEsODUuOTc3Yy0xLjI3NSwxLjI3NS0zLjM0NCwxLjI3NC00LjYyLDBMMjQuMjUsNjIuNTM0eg0KCQkiLz4NCjwvZz4NCjwvc3ZnPg0K");
  background-position: 100%;
  background-repeat: no-repeat;
  background-size: .9rem;
  width: auto;
  padding-right: 1.5rem;
}

html[dir="rtl"] select {
  background-position: 0;
  padding-left: 1.5rem;
  padding-right: .3rem;
}

select[multiple] {
  background-image: none;
  height: auto;
  padding: 0;
}

select[multiple] option {
  padding: .3rem;
}

.select-full {
  width: 100%;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  select::-ms-expand {
    display: none;
  }

  select:focus::-ms-value {
    color: #595959;
    background: none;
  }
}

.input-group {
  margin-top: .375rem;
  display: table;
}

.input-group-button {
  vertical-align: middle;
  width: 1%;
  display: table-cell;
}

.input-group-button .btn, .input-group-button button {
  height: 2.25rem;
  margin-left: -1px;
}

input.input-group-input, .input-group-input {
  vertical-align: middle;
  width: 100%;
  margin-top: 0;
  display: table-cell;
  position: relative;
}

.radio-group {
  border: none;
  margin-bottom: 1.5rem;
  padding: 0;
  font-size: .9375rem;
  line-height: 1.5;
}

.radio-group:after {
  content: "";
  clear: both;
  display: table;
}

.radio-group-title {
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.5;
}

.radio-group-input {
  opacity: 0;
  position: absolute;
}

.radio-group-input:checked + .radio-group-label {
  background-color: #d2e9f9;
  border: 1px solid #0079c1;
  margin-left: 0;
  margin-right: 0;
}

.radio-group-input:checked + .radio-group-label + .radio-group-input + .radio-group-label {
  border-left: none;
  border-right: 1px solid #ccc;
}

html[dir="rtl"] .radio-group-input:checked + .radio-group-label + .radio-group-input + .radio-group-label {
  border-left: 1px solid #ccc;
  border-right: none;
}

.radio-group-label {
  float: left;
  color: #323232;
  cursor: pointer;
  text-align: center;
  text-shadow: none;
  box-sizing: border-box;
  background-color: #fff;
  border: 1px solid #ccc;
  height: 2.25rem;
  margin-top: .25rem;
  margin-bottom: 0;
  margin-right: -1px;
  padding: .5rem 1rem;
  line-height: 1.25;
  transition: background .1s ease-in-out, border-color .1s ease-in-out;
  display: inline-block;
}

html[dir="rtl"] .radio-group-label {
  float: right;
  margin-left: -1px;
  margin-right: 0;
}

.loader-bars:before, .loader-bars:after, .loader-bars {
  background: #0079c1;
  width: .85rem;
  height: 2rem;
  animation: .8s ease-in-out infinite load;
}

.loader-bars:before, .loader-bars:after {
  content: "";
  position: absolute;
  top: 0;
}

@keyframes load {
  0%, 80%, 100% {
    opacity: .75;
    height: 2rem;
    box-shadow: 0 0 #0079c1;
  }

  40% {
    opacity: 1;
    height: 2.5rem;
    box-shadow: 0 -.5rem #0079c1;
  }
}

.loader {
  display: none;
  position: relative;
}

.loader.is-active {
  display: block;
}

.loader-bars {
  text-indent: -9999em;
  margin: auto;
  font-size: 11px;
  animation-delay: .16s;
  position: absolute;
  right: calc(50% - .425rem);
}

.loader-bars:before {
  left: -1.25rem;
}

.loader-bars:after {
  animation-delay: .32s;
  left: 1.25rem;
}

.loader-text {
  text-align: center;
  padding-top: 4rem;
}

.skip-to-content {
  opacity: 0;
  float: left;
  pointer-events: none;
  color: #fff;
  background-color: #000000bf;
  margin: 4px;
  padding: 1rem;
  transition: all .15s linear;
  position: absolute;
}

.skip-to-content:focus {
  opacity: 1;
  z-index: 999;
}

.card {
  background: #fff;
  flex-direction: column;
  display: flex;
  position: relative;
  box-shadow: 0 0 0 1px #0000001a, 0 0 16px #0000000d;
}

.card-image-wrap {
  -webkit-box-flex: 0 0 auto;
  flex: none;
  width: 100%;
  margin-bottom: 0;
  position: relative;
}

.card-image {
  width: 100%;
  display: block;
}

.card-image-caption {
  color: #4c4c4c;
  box-sizing: border-box;
  background: #fffc;
  width: 100%;
  margin: 0;
  padding: .35rem 1.25rem;
  font-size: .875rem;
  font-style: normal;
  line-height: 1.5;
  position: absolute;
  bottom: 0;
}

.card-last {
  margin-bottom: auto;
}

.card-content {
  flex-direction: column;
  -webkit-box-flex: 1 1 auto;
  word-wrap: break-word;
  flex: auto;
  padding: 1rem;
  display: flex;
}

.card-shaped {
  box-shadow: none;
  background: none;
}

.card-shaped .card-content {
  border: 1px solid #0000001a;
  padding-top: 4rem;
  box-shadow: 0 0 16px #0000000d;
}

.card-shaped .card-image-wrap {
  box-sizing: border-box;
  margin-bottom: -3rem;
  padding: 0 1.25rem;
}

.card-bar-lightest-blue {
  border-top: 3px solid #d2e9f9;
}

.card-bar-lighter-blue {
  border-top: 3px solid #bcdaed;
}

.card-bar-light-blue {
  border-top: 3px solid #56a5d8;
}

.card-bar-blue {
  border-top: 3px solid #0079c1;
}

.card-bar-dark-blue {
  border-top: 3px solid #005e95;
}

.card-bar-light-green {
  border-top: 3px solid #9bc19c;
}

.card-bar-green {
  border-top: 3px solid #5a9359;
}

.card-bar-dark-green {
  border-top: 3px solid #338033;
}

.card-bar-light-red {
  border-top: 3px solid #e4a793;
}

.card-bar-red {
  border-top: 3px solid #de2900;
}

.card-bar-dark-red {
  border-top: 3px solid #ab3c16;
}

.card-bar-light-orange {
  border-top: 3px solid #e8912e;
}

.card-bar-orange {
  border-top: 3px solid #d17e21;
}

.card-bar-dark-orange {
  border-top: 3px solid #ad6511;
}

.card-bar-light-yellow {
  border-top: 3px solid #efe397;
}

.card-bar-yellow {
  border-top: 3px solid #e4d154;
}

.card-bar-dark-yellow {
  border-top: 3px solid #d9bf2b;
}

.card-bar-light-purple {
  border-top: 3px solid #c4bcdc;
}

.card-bar-purple {
  border-top: 3px solid #9081bc;
}

.card-bar-dark-purple {
  border-top: 3px solid #7461a8;
}

.card-bar-light-brown {
  border-top: 3px solid #d2b38f;
}

.card-bar-brown {
  border-top: 3px solid #a57946;
}

.card-bar-dark-brown {
  border-top: 3px solid #8c5e2a;
}

.card-wide {
  flex-direction: row;
}

.card-wide .card-content {
  -webkit-box-flex: 1 1 75%;
  flex: 75%;
  display: block;
}

.card-wide-image-wrap {
  width: 25%;
  margin-bottom: 0;
  position: relative;
  top: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
}

.card-wide-image {
  width: auto;
  min-width: 100%;
  max-width: none;
  height: 100%;
  margin: 0;
  display: block;
  position: absolute;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes in-up {
  0% {
    transform: translate3d(0, 100%, 0);
  }

  100% {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes in-down {
  0% {
    transform: translate3d(0, -100%, 0);
  }

  100% {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes out-up {
  0% {
    transform: translate3d(0, 0, 0);
  }

  100% {
    transform: translate3d(0, 100%, 0);
  }
}

@keyframes out-down {
  0% {
    transform: translate3d(0, 0, 0);
  }

  100% {
    transform: translate3d(0, -100%, 0);
  }
}

.fade-in, .animate-fade-in {
  opacity: 0;
  animation: .75s ease-in forwards fade-in;
}

.animate-fade-out {
  animation: .75s ease-out both fade-out;
}

.animate-in-down {
  animation: .5s ease-out both in-down;
}

.animate-in-up {
  animation: .5s ease-out both in-up;
}

.animate-out-down {
  animation: .5s ease-in both out-down;
}

.animate-out-up {
  animation: .5s ease-in both out-up;
}

input[type="range"] {
  box-shadow: none;
  z-index: 1;
  cursor: pointer;
  background-color: #0000;
  border: 1px solid #0000;
  margin-top: 0;
  padding: 0;
  transition: all .25s;
}

input[type="range"]:hover::-webkit-slider-runnable-track {
  background-color: #a9a9a9;
}

input[type="range"]:hover::-webkit-slider-thumb {
  background-color: #fff;
  border-color: #007ac2;
  box-shadow: 0 0 4px 1px #cccccce6;
}

input[type="range"]:hover::-moz-range-track {
  background-color: #a9a9a9;
}

input[type="range"]:hover::-moz-range-thumb {
  background-color: #fff;
  border-color: #007ac2;
  box-shadow: 0 0 4px 1px #cccccce6;
}

input[type="range"]:hover::-ms-fill-upper {
  background-color: #a9a9a9;
}

input[type="range"]:hover::-ms-fill-lower {
  background-color: #a9a9a9;
}

input[type="range"]:hover::-ms-thumb {
  background-color: #fff;
  border-color: #007ac2;
  box-shadow: 0 0 4px 1px #cccccce6;
}

input[type="range"]:focus::-webkit-slider-runnable-track {
  background-color: #a9a9a9;
}

input[type="range"]:active::-webkit-slider-runnable-track {
  background-color: #a9a9a9;
}

input[type="range"]:focus::-webkit-slider-thumb {
  background-color: #007ac2;
  border-color: #007ac2;
  outline: none;
  box-shadow: 0 0 4px 1px #56a5d8e6;
}

input[type="range"]:active::-webkit-slider-thumb {
  background-color: #007ac2;
  border-color: #007ac2;
  outline: none;
  box-shadow: 0 0 4px 1px #56a5d8e6;
}

input[type="range"]:focus::-webkit-slider-thumb:hover {
  background-color: #196fa6;
  border-color: #007ac2;
}

input[type="range"]:active::-webkit-slider-thumb:hover {
  background-color: #196fa6;
  border-color: #007ac2;
}

input[type="range"]:focus::-moz-range-track {
  background-color: #a9a9a9;
}

input[type="range"]:active::-moz-range-track {
  background-color: #a9a9a9;
}

input[type="range"]:focus::-moz-range-thumb {
  background-color: #007ac2;
  border-color: #007ac2;
  outline: none;
  box-shadow: 0 0 4px 1px #56a5d8e6;
}

input[type="range"]:active::-moz-range-thumb {
  background-color: #007ac2;
  border-color: #007ac2;
  outline: none;
  box-shadow: 0 0 4px 1px #56a5d8e6;
}

input[type="range"]:focus::-moz-range-thumb:hover {
  background-color: #196fa6;
  border-color: #007ac2;
}

input[type="range"]:active::-moz-range-thumb:hover {
  background-color: #196fa6;
  border-color: #007ac2;
}

input[type="range"]:focus::-ms-fill-upper {
  background-color: #a9a9a9;
}

input[type="range"]:active::-ms-fill-upper {
  background-color: #a9a9a9;
}

input[type="range"]:focus::-ms-fill-lower {
  background-color: #a9a9a9;
}

input[type="range"]:active::-ms-fill-lower {
  background-color: #a9a9a9;
}

input[type="range"]:focus::-ms-thumb {
  background-color: #007ac2;
  border-color: #007ac2;
  outline: none;
  box-shadow: 0 0 4px 1px #56a5d8e6;
}

input[type="range"]:active::-ms-thumb {
  background-color: #007ac2;
  border-color: #007ac2;
  outline: none;
  box-shadow: 0 0 4px 1px #56a5d8e6;
}

input[type="range"]:focus::-ms-thumb:hover {
  background-color: #196fa6;
  border-color: #007ac2;
}

input[type="range"]:active::-ms-thumb:hover {
  background-color: #196fa6;
  border-color: #007ac2;
}

input[type="range"]::-webkit-slider-runnable-track {
  z-index: 2;
  background-color: #ccc;
  border-radius: 0;
  width: 100%;
  height: 2px;
  transition: all .25s;
}

input[type="range"]::-webkit-slider-thumb {
  cursor: pointer;
  z-index: 3;
  -webkit-appearance: none;
  background-color: #fff;
  border: 2px solid #959595;
  border-radius: 50px;
  width: 18px;
  height: 18px;
  margin-top: -8px;
  transition: all .25s;
}

input[type="range"]::-webkit-slider-thumb:hover {
  background-color: #007ac2;
  border-color: #007ac2;
  box-shadow: 0 0 4px 1px #cccccce6;
}

input[type="range"]::-webkit-slider-thumb:focus {
  background-color: #196fa6;
  border-color: #007ac2;
  box-shadow: 0 0 4px 1px #56a5d8e6;
}

input[type="range"]::-webkit-slider-thumb:active {
  background-color: #196fa6;
  border-color: #007ac2;
  box-shadow: 0 0 4px 1px #56a5d8e6;
}

input[type="range"]::-webkit-slider-thumb:focus:hover {
  background-color: #196fa6;
  border-color: #007ac2;
  box-shadow: 0 0 4px 1px #56a5d8e6;
}

input[type="range"]::-webkit-slider-thumb:active:hover {
  background-color: #196fa6;
  border-color: #007ac2;
  box-shadow: 0 0 4px 1px #56a5d8e6;
}

@supports (-webkit-overflow-scrolling: touch) {
  input[type="range"]::-webkit-slider-thumb {
    width: 20px;
    height: 20px;
    margin-top: -9px;
  }
}

input[type="range"]::-moz-range-track {
  z-index: 2;
  background-color: #ccc;
  border-radius: 0;
  width: 100%;
  height: 2px;
  transition: all .25s;
}

input[type="range"]::-moz-range-thumb {
  cursor: pointer;
  z-index: 3;
  -webkit-appearance: none;
  background-color: #fff;
  border: 2px solid #959595;
  border-radius: 50px;
  width: 14px;
  height: 14px;
  margin-top: -8px;
  transition: all .25s;
}

input[type="range"]::-moz-range-thumb:hover {
  background-color: #007ac2;
  border-color: #007ac2;
  box-shadow: 0 0 4px 1px #cccccce6;
}

input[type="range"]::-moz-range-thumb:focus {
  background-color: #196fa6;
  border-color: #007ac2;
  box-shadow: 0 0 4px 1px #56a5d8e6;
}

input[type="range"]::-moz-range-thumb:active {
  background-color: #196fa6;
  border-color: #007ac2;
  box-shadow: 0 0 4px 1px #56a5d8e6;
}

input[type="range"]::-moz-range-thumb:focus:hover {
  background-color: #196fa6;
  border-color: #007ac2;
  box-shadow: 0 0 4px 1px #56a5d8e6;
}

input[type="range"]::-moz-range-thumb:active:hover {
  background-color: #196fa6;
  border-color: #007ac2;
  box-shadow: 0 0 4px 1px #56a5d8e6;
}

input[type="range"]::-ms-fill-upper {
  background-color: #ccc;
}

input[type="range"]::-ms-fill-lower {
  background-color: #ccc;
}

input[type="range"]::-ms-track {
  z-index: 2;
  color: #0000;
  background-color: #ccc;
  border: 0 solid #0000;
  border-radius: 0;
  width: 100%;
  height: 2px;
  transition: all .25s;
}

input[type="range"]::-ms-thumb {
  cursor: pointer;
  z-index: 3;
  -webkit-appearance: none;
  background-color: #fff;
  border: 2px solid #959595;
  border-radius: 50px;
  width: 14px;
  height: 14px;
  margin-top: 0;
  transition: all .25s;
}

input[type="range"]::-ms-thumb:hover {
  background-color: #007ac2;
  border-color: #007ac2;
  box-shadow: 0 0 4px 1px #cccccce6;
}

input[type="range"]::-ms-thumb:focus {
  background-color: #196fa6;
  border-color: #007ac2;
  box-shadow: 0 0 4px 1px #56a5d8e6;
}

input[type="range"]::-ms-thumb:active {
  background-color: #196fa6;
  border-color: #007ac2;
  box-shadow: 0 0 4px 1px #56a5d8e6;
}

input[type="range"]::-ms-thumb:focus:hover {
  background-color: #196fa6;
  border-color: #007ac2;
  box-shadow: 0 0 4px 1px #56a5d8e6;
}

input[type="range"]::-ms-thumb:active:hover {
  background-color: #196fa6;
  border-color: #007ac2;
  box-shadow: 0 0 4px 1px #56a5d8e6;
}

.toggle-switch {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  tap-highlight-color: transparent;
  display: block;
  position: relative;
}

.toggle-switch-input[type] {
  clip: rect(0 0 0 0);
  border: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.toggle-switch-input[type]:hover + .toggle-switch-track {
  background-color: #e7e7e7;
  border-color: #ccc;
}

.toggle-switch-input[type]:hover + .toggle-switch-track:after {
  border-color: #3b8fc4;
  box-shadow: 0 1px 2px #59595933;
}

.toggle-switch-input[type]:active + .toggle-switch-track {
  box-shadow: 0 0 4px 2px #cccccce6;
}

.toggle-switch-input[type]:active + .toggle-switch-track:after {
  border-color: #005e95;
  left: 1px;
  right: auto;
  box-shadow: 0 3px 1px #59595933;
}

html[dir="rtl"] .toggle-switch-input[type]:active + .toggle-switch-track:after {
  left: auto;
  right: 1px;
}

.toggle-switch-input[type]:checked + .toggle-switch-track {
  background-color: #0079c1;
  border-color: #005e95;
}

.toggle-switch-input[type]:checked + .toggle-switch-track:after {
  border-color: #005e95;
  left: auto;
  right: -1px;
  box-shadow: 0 2px 1px #59595933;
}

html[dir="rtl"] .toggle-switch-input[type]:checked + .toggle-switch-track:after {
  left: -1px;
  right: auto;
}

.toggle-switch-input[type]:checked:active + .toggle-switch-track {
  box-shadow: 0 0 4px 2px #6cb4e2e6;
}

.toggle-switch-input[type]:checked:active + .toggle-switch-track:after {
  border-color: #005e95;
  left: auto;
  right: 1px;
  box-shadow: 0 3px 1px #59595933;
}

html[dir="rtl"] .toggle-switch-input[type]:checked:active + .toggle-switch-track:after {
  left: 1px;
  right: auto;
}

.toggle-switch-input[type]:focus + .toggle-switch-track {
  box-shadow: 0 0 4px 2px #cccccce6;
}

.toggle-switch-input[type]:focus + .toggle-switch-track:after {
  border-color: #3b8fc4;
}

.toggle-switch-input[type]:checked:focus + .toggle-switch-track {
  box-shadow: 0 0 4px 2px #6cb4e2e6;
}

.toggle-switch-input[type]:checked:focus + .toggle-switch-track:after {
  border-color: #005e95;
}

.toggle-switch-label {
  vertical-align: top;
  width: calc(100% - 3.5em);
  padding: 0 .1em;
}

.toggle-switch-track {
  vertical-align: top;
  background-color: #f4f4f4;
  border: 1px solid #ccc;
  border-radius: 30px;
  width: 36px;
  height: 20px;
  transition: all .25s;
  display: inline-block;
  position: relative;
  top: .05em;
}

.toggle-switch-track:after {
  content: "";
  background-color: #fff;
  border: 2px solid #959595;
  border-radius: 30px;
  width: 18px;
  height: 18px;
  transition: all .25s;
  display: block;
  position: absolute;
  top: -1px;
  left: -1px;
  right: auto;
  box-shadow: 0 1px 1px #59595933;
}

html[dir="rtl"] .toggle-switch-track:after {
  left: auto;
  right: -1px;
}

@supports (-ms-ime-align: auto) {
  .toggle-switch-track {
    top: .1em;
  }
}

@supports ((-moz-appearance: none)) {
  .toggle-switch-track {
    top: .1em;
  }
}

@supports (-webkit-overflow-scrolling: touch) {
  .toggle-switch-track {
    top: .15em;
  }
}

.toggle-switch-destructive .toggle-switch-input:hover + .toggle-switch-track:after {
  border-color: #ab3c16;
}

.toggle-switch-destructive .toggle-switch-input:active + .toggle-switch-track:after {
  border-color: #8c2907;
}

.toggle-switch-destructive .toggle-switch-input:checked:active + .toggle-switch-track {
  box-shadow: 0 0 4px 2px #c86a4ae6;
}

.toggle-switch-destructive .toggle-switch-input:checked:active + .toggle-switch-track:after {
  border-color: #8c2907;
}

.toggle-switch-destructive .toggle-switch-input:checked + .toggle-switch-track {
  background-color: #ab3c16;
  border-color: #8c2907;
}

.toggle-switch-destructive .toggle-switch-input:checked + .toggle-switch-track:after {
  border-color: #8c2907;
}

.toggle-switch-destructive .toggle-switch-input:focus + .toggle-switch-track:after {
  border-color: #ab3c16;
}

.toggle-switch-destructive .toggle-switch-input:checked:focus + .toggle-switch-track {
  box-shadow: 0 0 4px 2px #c86a4ae6;
}

.toggle-switch-destructive .toggle-switch-input:checked:focus + .toggle-switch-track:after {
  border-color: #8c2907;
}

.top-nav {
  z-index: 100;
  background-color: #fff;
  border-bottom: 1px solid #efefef;
  font-style: normal;
  font-weight: 400;
  position: relative;
}

.top-nav .esri-logo {
  margin-top: 1rem;
}

.top-nav .sign-in {
  padding-top: 1.5rem;
}

.top-nav-title {
  float: left;
  color: #4c4c4c;
  margin-right: 1.5rem;
  padding-top: 1.125rem;
  padding-bottom: 1.25rem;
  font-size: 1.2019rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
}

@media screen and (max-width: 859px) {
  .top-nav-title {
    font-size: 1.1305rem;
  }
}

@media screen and (max-width: 479px) {
  .top-nav-title {
    font-size: 1.0625rem;
  }
}

html[dir="rtl"] .top-nav-title {
  float: right;
  white-space: nowrap;
  margin-left: 1.5rem;
  margin-right: 0;
}

.top-nav-title:hover {
  text-decoration: none;
}

.top-nav-list {
  float: left;
  padding: 0;
}

html[dir="rtl"] .top-nav-list {
  float: right;
}

.top-nav-list .top-nav-link + .top-nav-link {
  margin-left: .75em;
}

html[dir="rtl"] .top-nav-list .top-nav-link + .top-nav-link {
  margin-left: 0;
  margin-right: .75em;
}

.top-nav-list.right {
  float: right;
}

html[dir="rtl"] .top-nav-list.right {
  float: left;
}

.top-nav-link {
  color: #4c4c4c;
  vertical-align: top;
  cursor: pointer;
  border: none;
  border-bottom: 4px solid #0000;
  padding: 1.1625rem 0 calc(1.1625rem - 4px);
  font-size: .9375rem;
  line-height: 1.5rem;
  display: inline-block;
}

.top-nav-link:hover, .top-nav-link:focus {
  color: #0079c1;
  border-bottom-color: #0079c1;
  text-decoration: none;
}

.top-nav-link:focus {
  outline: none;
}

.top-nav-link.is-active {
  border-bottom-color: #0079c1;
}

.top-nav-flex {
  display: flex;
}

.top-nav-flex-list {
  flex-grow: 1;
  flex-basis: 30%;
}

.top-nav-flex-title {
  text-align: center;
  flex-grow: 2;
}

.sign-in {
  float: right;
  margin-top: -.7rem;
  padding-left: .375rem;
}

html[dir="rtl"] .sign-in {
  float: left;
  padding-left: 0;
  padding-right: .375rem;
}

.user-nav-name {
  color: #4c4c4c;
  padding-right: 1.5em;
}

.user-nav-name:hover {
  text-decoration: none;
}

.user-nav-image {
  vertical-align: -.25rem;
  width: 1.2rem;
  height: 1.2rem;
}

.sub-nav {
  background-color: #efefef;
}

.sub-nav-title {
  margin-top: .75rem;
  margin-bottom: .75rem;
  font-size: 1.9994rem;
  line-height: 1.25;
  display: inline-block;
}

@media screen and (max-width: 859px) {
  .sub-nav-title {
    font-size: 1.7689rem;
  }
}

@media screen and (max-width: 479px) {
  .sub-nav-title {
    font-size: 1.5625rem;
  }
}

.sub-nav-list {
  float: left;
  width: 100%;
}

html[dir="rtl"] .sub-nav-list {
  float: right;
}

.sub-nav-link {
  float: left;
  color: #f8f8f8;
  box-sizing: border-box;
  background-color: #00000073;
  margin: 0 .25em 0 0;
  padding: .25em .75em;
  font-family: Avenir Next W01, Avenir Next W00, Avenir Next, Avenir, Helvetica Neue, sans-serif;
  font-size: .9375rem;
  line-height: 1.5;
  transition: background-color .15s linear, color .15s linear .15s;
}

html[dir="rtl"] .sub-nav-link {
  float: right;
}

.sub-nav-link:hover, .sub-nav-link:focus {
  color: #fff;
  -webkit-background-image: linear-gradient(to top, transparent 94%, #fff 96%, #fff 100%);
  -moz-background-image: linear-gradient(to top, transparent 94%, #fff 96%, #fff 100%);
  -o-background-image: linear-gradient(to top, transparent 94%, #fff 96%, #fff 100%);
  background-color: #0000004d;
  background-image: linear-gradient(to top, #0000 94%, #fff 96%, #fff 100%);
  text-decoration: none;
}

.sub-nav-link.is-active {
  color: #4c4c4c;
  background-color: #fff;
}

.third-nav {
  background-color: #fff;
  border-bottom: 1px solid #efefef;
  width: 100%;
  padding: .375rem 0;
  font-style: normal;
  font-weight: 400;
}

.third-nav-container {
  white-space: nowrap;
  margin-bottom: -15px;
  padding-bottom: 15px;
  position: relative;
  overflow-x: auto;
}

.third-nav-link {
  color: #595959;
  margin-right: 1.125rem;
  font-size: .875rem;
  line-height: 1.5;
}

html[dir="rtl"] .third-nav-link {
  white-space: nowrap;
}

.third-nav-link:last-child {
  margin-right: 2.25rem;
}

.third-nav-link.is-active {
  font-style: normal;
  font-weight: 700;
}

.side-nav, .dropdown-menu, .filter-dropdown-list {
  box-sizing: border-box;
  background-color: #fff;
  border: 1px solid #efefef;
  border-top: none;
  padding: 0;
  font-style: normal;
  font-weight: 400;
}

.side-nav-title {
  background-color: #f8f8f8;
  border-top: 1px solid #efefef;
  margin: 0;
  padding: .5rem;
  font-size: .9375rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
}

.side-nav-link {
  box-sizing: border-box;
  color: #595959;
  cursor: pointer;
  background-color: #fff;
  border-top: 1px solid #efefef;
  padding: .5rem;
  font-size: .875rem;
  line-height: 1.5;
  display: block;
  position: relative;
}

.side-nav-link:hover {
  color: #4c4c4c;
  background-color: #f8f8f8;
  text-decoration: none;
}

.side-nav-link.is-active {
  text-indent: -3px;
  border-left: 3px solid #0079c1;
}

html[dir="rtl"] .side-nav-link.is-active {
  border-left: none;
  border-right: 3px solid #0079c1;
}

body {
  flex-direction: column;
  height: 100%;
  display: flex;
}

.wrapper {
  flex: 1 0 auto;
}

.footer {
  background-color: #f8f8f8;
  flex: none;
  padding-top: 3rem;
}

.tab-nav:after {
  content: "";
  clear: both;
  display: table;
}

.tab-title {
  box-sizing: border-box;
  float: left;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  background-color: #f8f8f8;
  border: 1px solid #ccc;
  margin-right: -1px;
  padding: .375rem .75rem;
  font-size: .875rem;
  line-height: 1.5;
  transition: background, all .15s linear;
  overflow: hidden;
}

.tab-title:focus {
  outline: none;
}

.tab-title:hover, .tab-title:focus {
  -webkit-background-image: linear-gradient(to top, transparent 94%, #0079c1 96%, #0079c1 100%);
  -moz-background-image: linear-gradient(to top, transparent 94%, #0079c1 96%, #0079c1 100%);
  -o-background-image: linear-gradient(to top, transparent 94%, #0079c1 96%, #0079c1 100%);
  background-color: #fcfcfc;
  background-image: linear-gradient(to top, #0000 94%, #0079c1 96%, #0079c1 100%);
  text-decoration: none;
}

.tab-title.is-active {
  background-color: #fff;
  border-bottom: 1px solid #fff;
}

.tab-title.is-active:hover {
  background-color: #fff;
}

html[dir="rtl"] .tab-title {
  float: right;
  margin-left: -1px;
  margin-right: 0;
}

.tab-contents {
  box-sizing: border-box;
  border: 1px solid #ccc;
  margin-top: -1px;
}

.tab-section {
  box-sizing: border-box;
  background-color: #fff;
  padding: .75rem;
  display: none;
}

.tab-section.is-active {
  display: block;
}

.tab-section pre {
  margin: 0;
}

.tabs-gray .tab-title {
  background-color: #fff;
}

.tabs-gray .tab-title.is-active {
  background-color: #f8f8f8;
  border-bottom: 1px solid #f8f8f8;
}

.tabs-gray .tab-section {
  background-color: #f8f8f8;
}

.tabs-gray pre code {
  background-color: #0000;
  border: none;
  padding: 0;
}

.tabs-transparent .tab-title {
  background-color: #0000;
  border: 1px solid #0000;
  border-bottom-color: #ccc;
}

.tabs-transparent .tab-title:hover {
  border-bottom: 1px solid #ccc;
}

.tabs-transparent .tab-title.is-active {
  background-color: #0000;
  border: 1px solid #ccc;
  border-bottom-color: #fff;
  transition: backround, all .15s linear;
}

.tabs-transparent .tab-contents {
  border-bottom: none;
  border-left: none;
  border-right: none;
}

.tabs-transparent .tab-section {
  background-color: #0000;
  padding-left: 0;
  padding-right: 0;
}

.tabs-translucent .tab-title {
  color: #4c4c4c;
  background-color: #ffffffb3;
  background-image: none;
  border: none;
  border-top: 2px solid #ffffffb3;
  margin-bottom: 3px;
  margin-right: 2px;
  transition: none;
}

.tabs-translucent .tab-title:hover, .tabs-translucent .tab-title:focus {
  background-color: #fffc;
  background-image: none;
  border-top-color: #0079c1;
}

.tabs-translucent .tab-title.is-active {
  background-color: #fffc;
  background-image: none;
  border-top-color: #0079c1;
  border-bottom: 2px solid #fffc;
  margin-bottom: 0;
}

.tabs-translucent .tab-contents {
  border: none;
}

.tabs-translucent .tab-section {
  background-color: #fffc;
}

.tabs-dark .tab-title {
  color: #4c4c4c;
  background-color: #ffffffb3;
  background-image: none;
  border: none;
  border-top: 2px solid #ffffffb3;
  margin-bottom: 3px;
  margin-right: 2px;
  transition: none;
}

.tabs-dark .tab-title:hover, .tabs-dark .tab-title:focus {
  background-color: #fffc;
  background-image: none;
  border-top-color: #0079c1;
}

.tabs-dark .tab-title.is-active {
  background-color: #fffc;
  background-image: none;
  border-top-color: #0079c1;
  border-bottom: 2px solid #fffc;
  margin-bottom: 0;
}

.tabs-dark .tab-contents {
  border: none;
}

.tabs-dark .tab-section {
  background-color: #fffc;
}

.tabs-dark .tab-title {
  color: #fff;
  background-color: #000000bf;
  border-top: 2px solid #000000bf;
}

.tabs-dark .tab-title:hover, .tabs-dark .tab-title:focus {
  background-color: #000000d9;
  border-top-color: #fff;
}

.tabs-dark .tab-title.is-active {
  background-color: #000000d9;
  border-top-color: #fff;
  border-bottom: 2px solid #0000;
}

.tabs-dark .tab-section {
  color: #fff;
  background-color: #000000d9;
}

.modal-overlay {
  text-align: center;
  opacity: 0;
  visibility: hidden;
  z-index: 101;
  background: #000000bf;
  justify-content: center;
  align-items: center;
  transition: visibility 0s linear .3s, opacity .3s cubic-bezier(.215, .44, .42, .88);
  display: flex;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow-y: hidden;
}

.modal-overlay.is-active {
  visibility: visible;
  opacity: 1;
  transition-delay: 0s;
}

.modal-overlay.is-active .modal-content {
  visibility: visible;
  opacity: 1;
  transition-delay: 0s;
  transform: translate3d(0, 0, 0);
}

.modal-content {
  box-sizing: border-box;
  z-index: 102;
  float: none;
  text-align: left;
  -webkit-overflow-scrolling: touch;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: -webkit- transform .3s cubic-bezier(.215, .44, .42, .88), visibility 0s linear .3s, opacity .3s cubic-bezier(.215, .44, .42, .88);
  -moz-transition: -moz- transform .3s cubic-bezier(.215, .44, .42, .88), visibility 0s linear .3s, opacity .3s cubic-bezier(.215, .44, .42, .88);
  -o-transition: -o- transform .3s cubic-bezier(.215, .44, .42, .88), visibility 0s linear .3s, opacity .3s cubic-bezier(.215, .44, .42, .88);
  background: #fff;
  flex: 0 auto;
  max-height: 80vh;
  padding: 1.5rem;
  transition: transform .3s cubic-bezier(.215, .44, .42, .88), visibility 0s linear .3s, opacity .3s cubic-bezier(.215, .44, .42, .88);
  display: inline-block;
  overflow-y: auto;
  transform: translate3d(0, 20px, 0);
}

html[dir="rtl"] .modal-content {
  text-align: right;
  float: none;
}

.search-overlay {
  text-align: center;
  opacity: 0;
  visibility: hidden;
  z-index: 101;
  z-index: 99;
  background: #fff;
  justify-content: center;
  align-items: center;
  padding: 4.5rem 0 1.5rem;
  transition: visibility 0s linear .3s, opacity .3s cubic-bezier(.215, .44, .42, .88);
  display: flex;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: auto;
}

.search-overlay.is-active {
  visibility: visible;
  opacity: 1;
  transition-delay: 0s;
}

.search-overlay.is-active .modal-content {
  visibility: visible;
  opacity: 1;
  transition-delay: 0s;
  transform: translate3d(0, 0, 0);
}

.search-overlay.is-active {
  opacity: .98;
}

.search-overlay.is-active .search-content {
  visibility: visible;
  opacity: 1;
  transition-delay: 0s;
  transform: translate3d(0, 0, 0);
}

.search-content {
  text-align: left;
  box-sizing: border-box;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: -webkit- transform .3s cubic-bezier(.215, .44, .42, .88), visibility 0s linear .3s, opacity .3s cubic-bezier(.215, .44, .42, .88);
  -moz-transition: -moz- transform .3s cubic-bezier(.215, .44, .42, .88), visibility 0s linear .3s, opacity .3s cubic-bezier(.215, .44, .42, .88);
  -o-transition: -o- transform .3s cubic-bezier(.215, .44, .42, .88), visibility 0s linear .3s, opacity .3s cubic-bezier(.215, .44, .42, .88);
  width: 1440px;
  max-width: 96vw;
  margin-bottom: 3rem;
  padding: 0 .5rem;
  transition: transform .3s cubic-bezier(.215, .44, .42, .88), visibility 0s linear .3s, opacity .3s cubic-bezier(.215, .44, .42, .88);
  display: inline-block;
  transform: translate3d(0, 20px, 0);
}

.search-top-nav {
  color: #4c4c4c;
  vertical-align: top;
  cursor: pointer;
  background-color: #0000;
  border: none;
  padding: 1.1625rem 0 calc(1.1625rem - 4px);
  font-size: 1rem;
  line-height: 1.5;
  display: inline-block;
}

.search-top-nav:hover, .search-top-nav:focus {
  color: #0079c1;
  border-bottom-color: #0079c1;
  text-decoration: none;
}

.search-top-nav:focus {
  outline: none;
}

.search-top-nav.is-active {
  border-bottom-color: #0079c1;
}

.search-top-nav:hover {
  background-color: #0000;
}

.search-icon-open, .search-icon-close {
  display: none;
}

.search-icon-open .is-active, .search-icon-close .is-active {
  display: block;
}

.search-input {
  box-shadow: none;
  background-color: #0000;
  background-size: .45em;
  border-color: currentColor currentColor #ccc;
  border-top-style: none;
  border-top-width: medium;
  border-left-style: none;
  border-left-width: medium;
  border-right-style: none;
  border-right-width: medium;
  height: auto;
  margin-top: .25em;
  padding-left: 1em;
  font-size: 2.82715rem;
  line-height: 1.25;
}

.search-input:focus {
  box-shadow: none;
  border-bottom-color: #999;
}

.accordion {
  box-sizing: border-box;
  background-color: #fff;
  border: 1px solid #efefef;
  border-top: none;
  padding: 0;
  font-style: normal;
  font-weight: 400;
}

.accordion-section.is-active .accordion-icon .svg-icon {
  transform: rotate(0);
}

.accordion-section.is-active .accordion-content {
  display: block;
}

.accordion-title {
  cursor: pointer;
  background-color: #f8f8f8;
  border-top: 1px solid #efefef;
  margin: 0;
  padding: .5rem;
  font-size: .9375rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
}

.accordion-title:focus, .accordion-title:hover {
  background-color: #f4f4f4;
  outline: none;
  transition: all, all .15s linear;
}

.accordion-content {
  display: none;
}

.accordion-icon .svg-icon {
  transform: rotate(-90deg);
}

html[dir="rtl"] .accordion-icon .svg-icon {
  transform: rotate(90deg);
}

.drawer {
  visibility: hidden;
  opacity: 0;
  z-index: 1001;
  background: #000000bf;
  transition: visibility 0s linear .2s, opacity .2s linear;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
}

.drawer.is-active {
  visibility: visible;
  opacity: 1;
  background-color: #000000bf;
  transition-delay: 0s;
}

.drawer.is-active.drawer-left .drawer-nav {
  transform: translate3d(280px, 0, 0);
}

html[dir="rtl"] .drawer.is-active.drawer-left .drawer-nav, .drawer.is-active.drawer-right .drawer-nav {
  transform: translate3d(-280px, 0, 0);
}

html[dir="rtl"] .drawer.is-active.drawer-right .drawer-nav {
  transform: translate3d(280px, 0, 0);
}

.drawer-nav {
  -webkit-overflow-scrolling: touch;
  -webkit-transition: -webkit- transform .2s cubic-bezier(.215, .44, .42, .88);
  -moz-transition: -moz- transform .2s cubic-bezier(.215, .44, .42, .88);
  -o-transition: -o- transform .2s cubic-bezier(.215, .44, .42, .88);
  background: #fff;
  width: 280px;
  height: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
  transition: transform .2s cubic-bezier(.215, .44, .42, .88);
  position: absolute;
  top: 0;
  overflow-x: hidden;
  overflow-y: auto;
  transform: translate3d(0, 0, 0);
}

.drawer-left .drawer-nav {
  left: -280px;
}

html[dir="rtl"] .drawer-left .drawer-nav, .drawer-right .drawer-nav {
  left: 100%;
}

html[dir="rtl"] .drawer-right .drawer-nav {
  left: -280px;
}

.drawer-no-overflow {
  overflow: hidden;
}

input.filter-dropdown-input {
  width: 98%;
  margin: 1%;
}

.filter-dropdown-title {
  padding-bottom: 0;
  display: inline;
}

.filter-dropdown-action {
  float: right;
  color: #959595;
  font-size: .8125rem;
  line-height: 1.5;
  display: none;
}

html[dir="rtl"] .filter-dropdown-action {
  float: left;
}

.filter-dropdown-action.is-active {
  display: inline-block;
}

.filter-dropdown-container:after {
  content: "";
  clear: both;
  display: table;
}

.filter-dropdown-list {
  z-index: 1000;
  background-color: #f8f8f8;
  border-top: 1px solid #efefef;
  width: 100%;
  min-width: 200px;
  max-width: 100%;
  display: none;
  position: absolute;
  overflow: auto;
  box-shadow: 0 0 16px #0000000d;
}

.filter-dropdown-list.dropdown-right {
  left: auto;
  right: 0;
}

html[dir="rtl"] .filter-dropdown-list.dropdown-right {
  left: 0;
  right: auto;
}

.filter-dropdown-list.is-active {
  display: block;
}

.filter-dropdown-inner-list {
  background-color: #fff;
  border-top: 1px solid #efefef;
  max-height: 13.5rem;
  overflow: auto;
}

.filter-dropdown-link {
  box-sizing: border-box;
  color: #6e6e6e;
  border-top: 1px solid #efefef;
  padding: .5rem 2.25rem;
  font-size: .875rem;
  line-height: 1.5;
  display: block;
  position: relative;
}

.filter-dropdown-link:first-child {
  border-top: none;
}

.filter-dropdown-link:hover {
  color: #828282;
  text-decoration: none;
}

.filter-dropdown-link:hover .filter-link-check {
  opacity: 1;
  visibility: visible;
}

.filter-dropdown-link.is-active {
  color: #4c4c4c;
}

.filter-dropdown-link.is-active .filter-link-close {
  opacity: 1;
  visibility: visible;
}

.filter-dropdown-link.is-active .filter-link-check {
  fill: #5a9359;
  opacity: 1;
  visibility: visible;
}

.filter-dropdown-link.is-active:hover .filter-link-close {
  fill: #de2900;
}

.filter-link-close {
  opacity: 0;
  visibility: hidden;
  margin-top: 4px;
  position: absolute;
  left: auto;
  right: 10px;
}

html[dir="rtl"] .filter-link-close {
  left: 10px;
  right: auto;
}

.filter-link-check {
  opacity: 0;
  visibility: hidden;
  margin-top: 3px;
  position: absolute;
  left: 10px;
  right: auto;
}

html[dir="rtl"] .filter-link-check {
  left: auto;
  right: 10px;
}

.filter-dropdown-active {
  white-space: nowrap;
  box-sizing: border-box;
  color: #6e6e6e;
  background-color: #efefef;
  border-radius: 3px;
  margin-top: .25rem;
  margin-right: .25rem;
  padding: .5rem .3rem .33333rem .75rem;
  font-size: .875rem;
  line-height: 1.5;
  display: inline-block;
  position: relative;
}

html[dir="rtl"] .filter-dropdown-active {
  padding: .5rem .75rem .33333rem .3rem;
}

.filter-dropdown-remove {
  vertical-align: middle;
  display: inline-block;
  position: relative;
  top: -1px;
}

.filter-dropdown-remove .svg-icon {
  cursor: pointer;
  color: #6e6e6e;
  padding: .3rem .375rem;
  display: block;
  position: relative;
}

.filter-dropdown-remove:hover {
  text-decoration: none;
}

.filter-dropdown-remove:hover .svg-icon {
  color: #de2900;
}

.is-hidden {
  display: none !important;
}

.link-red {
  color: #de2900;
}

.link-red:hover {
  color: #ab3c16;
}

.link-green {
  color: #5a9359;
}

.link-green:hover {
  color: #338033;
}

.link-light-blue {
  color: #bcdaed;
}

.link-light-blue:hover {
  color: #56a5d8;
}

.link-blue {
  color: #0079c1;
}

.link-blue:hover, .link-dark-blue {
  color: #005e95;
}

.link-dark-blue:hover {
  color: #052942;
}

.link-white {
  color: #fff;
}

.link-white:hover {
  color: #efefef;
}

.link-light-gray {
  color: #a9a9a9;
}

.link-light-gray:hover {
  color: #959595;
}

.link-dark-gray {
  color: #828282;
}

.link-dark-gray:hover, .link-darker-gray {
  color: #6e6e6e;
}

.link-darker-gray:hover, .link-darkest-gray, .third-nav-link.is-active:hover {
  color: #595959;
}

.link-darkest-gray:hover, .third-nav-link.is-active:hover, .link-off-black {
  color: #4c4c4c;
}

.link-off-black:hover {
  color: #323232;
}

.text-red {
  color: #de2900;
}

.text-green {
  color: #5a9359;
}

.text-blue {
  color: #0079c1;
}

.text-white {
  color: #fff;
}

.text-light-gray {
  color: #a9a9a9;
}

.text-dark-gray {
  color: #828282;
}

.text-darker-gray {
  color: #6e6e6e;
}

.text-darkest-gray {
  color: #595959;
}

.text-off-black {
  color: #4c4c4c;
}

.text-rule {
  border-bottom: 1px solid #ccc;
}

.text-inline {
  display: inline;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

html[dir="rtl"] .text-left, .text-right {
  text-align: right;
}

html[dir="rtl"] .text-right {
  text-align: left;
}

.text-light {
  opacity: .66;
}

.text-ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.list-numbered {
  counter-reset: li;
  margin-left: 0;
  margin-right: 0;
  padding: 0 0 1em;
  list-style: none;
  position: relative;
}

.list-numbered > li {
  margin-top: .75em;
  margin-left: 0;
  padding: .3em .25em .25em 2.5em;
  position: relative;
}

html[dir="rtl"] .list-numbered > li {
  padding: .3em 2.5em .25em .25em;
}

.list-numbered > li:before {
  content: counter(li);
  counter-increment: li;
  color: #959595;
  text-align: right;
  border-right: 2px solid #ccc;
  width: .75em;
  height: calc(100% - .3em);
  padding: .3em .5em 0 0;
  font-size: 1.2019rem;
  font-style: normal;
  font-weight: 300;
  line-height: 1;
  position: absolute;
  top: 0;
  left: 0;
  right: auto;
}

html[dir="rtl"] .list-numbered > li:before {
  left: auto;
  right: 0;
}

@media screen and (max-width: 859px) {
  .list-numbered > li:before {
    font-size: 1.1305rem;
  }
}

@media screen and (max-width: 479px) {
  .list-numbered > li:before {
    font-size: 1.0625rem;
  }
}

html[dir="rtl"] .list-numbered > li:before {
  text-align: left;
  border-left: 2px solid #ccc;
  border-right: none;
  padding: 0 0 0 .5em;
}

.list-numbered > li:after {
  content: ".";
  clear: left;
  visibility: hidden;
  height: 0;
  line-height: 0;
  display: block;
}

.list-numbered .list-numbered {
  margin-bottom: 0;
  padding-bottom: 0;
}

.list-numbered .list-numbered > li:before {
  content: counter(li, lower-alpha);
}

.list-plain {
  margin: .375rem 0;
  padding: 0;
}

.list-plain li {
  margin-left: 0;
  list-style-type: none;
}

.font-size--3 {
  font-size: .8125rem;
  line-height: 1.5;
}

.font-size--2 {
  font-size: .875rem;
  line-height: 1.5;
}

.font-size--1 {
  font-size: .9375rem;
  line-height: 1.5;
}

.font-size-0 {
  font-size: 1rem;
  line-height: 1.5;
}

.font-size-1 {
  font-size: 1.2019rem;
  line-height: 1.5;
}

@media screen and (max-width: 859px) {
  .font-size-1 {
    font-size: 1.1305rem;
  }
}

@media screen and (max-width: 479px) {
  .font-size-1 {
    font-size: 1.0625rem;
  }
}

.font-size-2 {
  font-size: 1.414rem;
  line-height: 1.5;
}

@media screen and (max-width: 859px) {
  .font-size-2 {
    font-size: 1.33rem;
  }
}

@media screen and (max-width: 479px) {
  .font-size-2 {
    font-size: 1.25rem;
  }
}

.font-size-3 {
  font-size: 1.69949rem;
  line-height: 1.3875;
}

@media screen and (max-width: 859px) {
  .font-size-3 {
    font-size: 1.50357rem;
  }
}

@media screen and (max-width: 479px) {
  .font-size-3 {
    font-size: 1.32812rem;
  }
}

.font-size-4 {
  font-size: 1.9994rem;
  line-height: 1.35;
}

@media screen and (max-width: 859px) {
  .font-size-4 {
    font-size: 1.7689rem;
  }
}

@media screen and (max-width: 479px) {
  .font-size-4 {
    font-size: 1.5625rem;
  }
}

.font-size-5 {
  font-size: 2.40307rem;
  line-height: 1.35;
}

@media screen and (max-width: 859px) {
  .font-size-5 {
    font-size: 1.99974rem;
  }
}

@media screen and (max-width: 479px) {
  .font-size-5 {
    font-size: 1.66016rem;
  }
}

.font-size-6 {
  font-size: 2.82715rem;
  line-height: 1.3125;
}

@media screen and (max-width: 859px) {
  .font-size-6 {
    font-size: 2.35264rem;
  }
}

@media screen and (max-width: 479px) {
  .font-size-6 {
    font-size: 1.95312rem;
  }
}

.font-size-7 {
  font-size: 3.39795rem;
  line-height: 1.3125;
}

@media screen and (max-width: 859px) {
  .font-size-7 {
    font-size: 2.65966rem;
  }
}

@media screen and (max-width: 479px) {
  .font-size-7 {
    font-size: 2.0752rem;
  }
}

.font-size-8 {
  font-size: 3.99758rem;
  line-height: 1.275;
}

@media screen and (max-width: 859px) {
  .font-size-8 {
    font-size: 3.12901rem;
  }
}

@media screen and (max-width: 479px) {
  .font-size-8 {
    font-size: 2.44141rem;
  }
}

.leader-half {
  margin-top: .75rem;
}

.leader-quarter {
  margin-top: .375rem;
}

.trailer-half {
  margin-bottom: .75rem;
}

.trailer-quarter {
  margin-bottom: .375rem;
}

.padding-leader-half {
  padding-top: .75rem;
}

.padding-leader-quarter {
  padding-top: .375rem;
}

.padding-trailer-half {
  padding-bottom: .75rem;
}

.padding-trailer-quarter {
  padding-bottom: .375rem;
}

.leader-0 {
  margin-top: 0;
}

.trailer-0 {
  margin-bottom: 0;
}

.padding-leader-0 {
  padding-top: 0;
}

.padding-trailer-0 {
  padding-bottom: 0;
}

.leader-1 {
  margin-top: 1.5rem;
}

.trailer-1 {
  margin-bottom: 1.5rem;
}

.padding-leader-1 {
  padding-top: 1.5rem;
}

.padding-trailer-1 {
  padding-bottom: 1.5rem;
}

.leader-2 {
  margin-top: 3rem;
}

.trailer-2 {
  margin-bottom: 3rem;
}

.padding-leader-2 {
  padding-top: 3rem;
}

.padding-trailer-2 {
  padding-bottom: 3rem;
}

.leader-3 {
  margin-top: 4.5rem;
}

.trailer-3 {
  margin-bottom: 4.5rem;
}

.padding-leader-3 {
  padding-top: 4.5rem;
}

.padding-trailer-3 {
  padding-bottom: 4.5rem;
}

.leader-4 {
  margin-top: 6rem;
}

.trailer-4 {
  margin-bottom: 6rem;
}

.padding-leader-4 {
  padding-top: 6rem;
}

.padding-trailer-4 {
  padding-bottom: 6rem;
}

.leader-5 {
  margin-top: 7.5rem;
}

.trailer-5 {
  margin-bottom: 7.5rem;
}

.padding-leader-5 {
  padding-top: 7.5rem;
}

.padding-trailer-5 {
  padding-bottom: 7.5rem;
}

.leader-6 {
  margin-top: 9rem;
}

.trailer-6 {
  margin-bottom: 9rem;
}

.padding-leader-6 {
  padding-top: 9rem;
}

.padding-trailer-6 {
  padding-bottom: 9rem;
}

@media screen and (min-width: 1440px) {
  .large-leader-half {
    margin-top: .75rem;
  }

  .large-leader-quarter {
    margin-top: .375rem;
  }

  .large-trailer-half {
    margin-bottom: .75rem;
  }

  .large-trailer-quarter {
    margin-bottom: .375rem;
  }

  .large-padding-leader-half {
    padding-top: .75rem;
  }

  .large-padding-leader-quarter {
    padding-top: .375rem;
  }

  .large-padding-trailer-half {
    padding-bottom: .75rem;
  }

  .large-padding-trailer-quarter {
    padding-bottom: .375rem;
  }

  .large-leader-0 {
    margin-top: 0;
  }

  .large-trailer-0 {
    margin-bottom: 0;
  }

  .large-padding-leader-0 {
    padding-top: 0;
  }

  .large-padding-trailer-0 {
    padding-bottom: 0;
  }

  .large-leader-1 {
    margin-top: 1.5rem;
  }

  .large-trailer-1 {
    margin-bottom: 1.5rem;
  }

  .large-padding-leader-1 {
    padding-top: 1.5rem;
  }

  .large-padding-trailer-1 {
    padding-bottom: 1.5rem;
  }

  .large-leader-2 {
    margin-top: 3rem;
  }

  .large-trailer-2 {
    margin-bottom: 3rem;
  }

  .large-padding-leader-2 {
    padding-top: 3rem;
  }

  .large-padding-trailer-2 {
    padding-bottom: 3rem;
  }

  .large-leader-3 {
    margin-top: 4.5rem;
  }

  .large-trailer-3 {
    margin-bottom: 4.5rem;
  }

  .large-padding-leader-3 {
    padding-top: 4.5rem;
  }

  .large-padding-trailer-3 {
    padding-bottom: 4.5rem;
  }

  .large-leader-4 {
    margin-top: 6rem;
  }

  .large-trailer-4 {
    margin-bottom: 6rem;
  }

  .large-padding-leader-4 {
    padding-top: 6rem;
  }

  .large-padding-trailer-4 {
    padding-bottom: 6rem;
  }

  .large-leader-5 {
    margin-top: 7.5rem;
  }

  .large-trailer-5 {
    margin-bottom: 7.5rem;
  }

  .large-padding-leader-5 {
    padding-top: 7.5rem;
  }

  .large-padding-trailer-5 {
    padding-bottom: 7.5rem;
  }

  .large-leader-6 {
    margin-top: 9rem;
  }

  .large-trailer-6 {
    margin-bottom: 9rem;
  }

  .large-padding-leader-6 {
    padding-top: 9rem;
  }

  .large-padding-trailer-6 {
    padding-bottom: 9rem;
  }
}

@media screen and (max-width: 859px) {
  .tablet-leader-half {
    margin-top: .75rem;
  }

  .tablet-leader-quarter {
    margin-top: .375rem;
  }

  .tablet-trailer-half {
    margin-bottom: .75rem;
  }

  .tablet-trailer-quarter {
    margin-bottom: .375rem;
  }

  .tablet-padding-leader-half {
    padding-top: .75rem;
  }

  .tablet-padding-leader-quarter {
    padding-top: .375rem;
  }

  .tablet-padding-trailer-half {
    padding-bottom: .75rem;
  }

  .tablet-padding-trailer-quarter {
    padding-bottom: .375rem;
  }

  .tablet-leader-0 {
    margin-top: 0;
  }

  .tablet-trailer-0 {
    margin-bottom: 0;
  }

  .tablet-padding-leader-0 {
    padding-top: 0;
  }

  .tablet-padding-trailer-0 {
    padding-bottom: 0;
  }

  .tablet-leader-1 {
    margin-top: 1.5rem;
  }

  .tablet-trailer-1 {
    margin-bottom: 1.5rem;
  }

  .tablet-padding-leader-1 {
    padding-top: 1.5rem;
  }

  .tablet-padding-trailer-1 {
    padding-bottom: 1.5rem;
  }

  .tablet-leader-2 {
    margin-top: 3rem;
  }

  .tablet-trailer-2 {
    margin-bottom: 3rem;
  }

  .tablet-padding-leader-2 {
    padding-top: 3rem;
  }

  .tablet-padding-trailer-2 {
    padding-bottom: 3rem;
  }

  .tablet-leader-3 {
    margin-top: 4.5rem;
  }

  .tablet-trailer-3 {
    margin-bottom: 4.5rem;
  }

  .tablet-padding-leader-3 {
    padding-top: 4.5rem;
  }

  .tablet-padding-trailer-3 {
    padding-bottom: 4.5rem;
  }

  .tablet-leader-4 {
    margin-top: 6rem;
  }

  .tablet-trailer-4 {
    margin-bottom: 6rem;
  }

  .tablet-padding-leader-4 {
    padding-top: 6rem;
  }

  .tablet-padding-trailer-4 {
    padding-bottom: 6rem;
  }

  .tablet-leader-5 {
    margin-top: 7.5rem;
  }

  .tablet-trailer-5 {
    margin-bottom: 7.5rem;
  }

  .tablet-padding-leader-5 {
    padding-top: 7.5rem;
  }

  .tablet-padding-trailer-5 {
    padding-bottom: 7.5rem;
  }

  .tablet-leader-6 {
    margin-top: 9rem;
  }

  .tablet-trailer-6 {
    margin-bottom: 9rem;
  }

  .tablet-padding-leader-6 {
    padding-top: 9rem;
  }

  .tablet-padding-trailer-6 {
    padding-bottom: 9rem;
  }
}

@media screen and (max-width: 479px) {
  .phone-leader-half {
    margin-top: .75rem;
  }

  .phone-leader-quarter {
    margin-top: .375rem;
  }

  .phone-trailer-half {
    margin-bottom: .75rem;
  }

  .phone-trailer-quarter {
    margin-bottom: .375rem;
  }

  .phone-padding-leader-half {
    padding-top: .75rem;
  }

  .phone-padding-leader-quarter {
    padding-top: .375rem;
  }

  .phone-padding-trailer-half {
    padding-bottom: .75rem;
  }

  .phone-padding-trailer-quarter {
    padding-bottom: .375rem;
  }

  .phone-leader-0 {
    margin-top: 0;
  }

  .phone-trailer-0 {
    margin-bottom: 0;
  }

  .phone-padding-leader-0 {
    padding-top: 0;
  }

  .phone-padding-trailer-0 {
    padding-bottom: 0;
  }

  .phone-leader-1 {
    margin-top: 1.5rem;
  }

  .phone-trailer-1 {
    margin-bottom: 1.5rem;
  }

  .phone-padding-leader-1 {
    padding-top: 1.5rem;
  }

  .phone-padding-trailer-1 {
    padding-bottom: 1.5rem;
  }

  .phone-leader-2 {
    margin-top: 3rem;
  }

  .phone-trailer-2 {
    margin-bottom: 3rem;
  }

  .phone-padding-leader-2 {
    padding-top: 3rem;
  }

  .phone-padding-trailer-2 {
    padding-bottom: 3rem;
  }

  .phone-leader-3 {
    margin-top: 4.5rem;
  }

  .phone-trailer-3 {
    margin-bottom: 4.5rem;
  }

  .phone-padding-leader-3 {
    padding-top: 4.5rem;
  }

  .phone-padding-trailer-3 {
    padding-bottom: 4.5rem;
  }

  .phone-leader-4 {
    margin-top: 6rem;
  }

  .phone-trailer-4 {
    margin-bottom: 6rem;
  }

  .phone-padding-leader-4 {
    padding-top: 6rem;
  }

  .phone-padding-trailer-4 {
    padding-bottom: 6rem;
  }

  .phone-leader-5 {
    margin-top: 7.5rem;
  }

  .phone-trailer-5 {
    margin-bottom: 7.5rem;
  }

  .phone-padding-leader-5 {
    padding-top: 7.5rem;
  }

  .phone-padding-trailer-5 {
    padding-bottom: 7.5rem;
  }

  .phone-leader-6 {
    margin-top: 9rem;
  }

  .phone-trailer-6 {
    margin-bottom: 9rem;
  }

  .phone-padding-leader-6 {
    padding-top: 9rem;
  }

  .phone-padding-trailer-6 {
    padding-bottom: 9rem;
  }
}

html:not([dir="rtl"]) .padding-left-quarter {
  padding-left: .25rem;
}

html[dir="rtl"] .padding-left-quarter, html:not([dir="rtl"]) .padding-right-quarter {
  padding-right: .25rem;
}

html[dir="rtl"] .padding-right-quarter {
  padding-left: .25rem;
}

html:not([dir="rtl"]) .padding-left-third {
  padding-left: .33333rem;
}

html[dir="rtl"] .padding-left-third, html:not([dir="rtl"]) .padding-right-third {
  padding-right: .33333rem;
}

html[dir="rtl"] .padding-right-third {
  padding-left: .33333rem;
}

html:not([dir="rtl"]) .padding-left-half {
  padding-left: .5rem;
}

html[dir="rtl"] .padding-left-half, html:not([dir="rtl"]) .padding-right-half {
  padding-right: .5rem;
}

html[dir="rtl"] .padding-right-half {
  padding-left: .5rem;
}

html:not([dir="rtl"]) .padding-left-0 {
  padding-left: 0;
}

html[dir="rtl"] .padding-left-0, html:not([dir="rtl"]) .padding-right-0 {
  padding-right: 0;
}

html[dir="rtl"] .padding-right-0 {
  padding-left: 0;
}

html:not([dir="rtl"]) .padding-left-1 {
  padding-left: 1rem;
}

html[dir="rtl"] .padding-left-1, html:not([dir="rtl"]) .padding-right-1 {
  padding-right: 1rem;
}

html[dir="rtl"] .padding-right-1 {
  padding-left: 1rem;
}

html:not([dir="rtl"]) .padding-left-2 {
  padding-left: 2rem;
}

html[dir="rtl"] .padding-left-2, html:not([dir="rtl"]) .padding-right-2 {
  padding-right: 2rem;
}

html[dir="rtl"] .padding-right-2 {
  padding-left: 2rem;
}

html:not([dir="rtl"]) .padding-left-3 {
  padding-left: 3rem;
}

html[dir="rtl"] .padding-left-3, html:not([dir="rtl"]) .padding-right-3 {
  padding-right: 3rem;
}

html[dir="rtl"] .padding-right-3 {
  padding-left: 3rem;
}

html:not([dir="rtl"]) .padding-left-4 {
  padding-left: 4rem;
}

html[dir="rtl"] .padding-left-4, html:not([dir="rtl"]) .padding-right-4 {
  padding-right: 4rem;
}

html[dir="rtl"] .padding-right-4 {
  padding-left: 4rem;
}

html:not([dir="rtl"]) .margin-left-quarter {
  margin-left: .25rem;
}

html[dir="rtl"] .margin-left-quarter, html:not([dir="rtl"]) .margin-right-quarter {
  margin-right: .25rem;
}

html[dir="rtl"] .margin-right-quarter {
  margin-left: .25rem;
}

html:not([dir="rtl"]) .margin-left-third {
  margin-left: .33333rem;
}

html[dir="rtl"] .margin-left-third, html:not([dir="rtl"]) .margin-right-third {
  margin-right: .33333rem;
}

html[dir="rtl"] .margin-right-third {
  margin-left: .33333rem;
}

html:not([dir="rtl"]) .margin-left-half {
  margin-left: .5rem;
}

html[dir="rtl"] .margin-left-half, html:not([dir="rtl"]) .margin-right-half {
  margin-right: .5rem;
}

html[dir="rtl"] .margin-right-half {
  margin-left: .5rem;
}

html:not([dir="rtl"]) .margin-left-0 {
  margin-left: 0;
}

html[dir="rtl"] .margin-left-0, html:not([dir="rtl"]) .margin-right-0 {
  margin-right: 0;
}

html[dir="rtl"] .margin-right-0 {
  margin-left: 0;
}

html:not([dir="rtl"]) .margin-left-1 {
  margin-left: 1rem;
}

html[dir="rtl"] .margin-left-1, html:not([dir="rtl"]) .margin-right-1 {
  margin-right: 1rem;
}

html[dir="rtl"] .margin-right-1 {
  margin-left: 1rem;
}

html:not([dir="rtl"]) .margin-left-2 {
  margin-left: 2rem;
}

html[dir="rtl"] .margin-left-2, html:not([dir="rtl"]) .margin-right-2 {
  margin-right: 2rem;
}

html[dir="rtl"] .margin-right-2 {
  margin-left: 2rem;
}

html:not([dir="rtl"]) .margin-left-3 {
  margin-left: 3rem;
}

html[dir="rtl"] .margin-left-3, html:not([dir="rtl"]) .margin-right-3 {
  margin-right: 3rem;
}

html[dir="rtl"] .margin-right-3 {
  margin-left: 3rem;
}

html:not([dir="rtl"]) .margin-left-4 {
  margin-left: 4rem;
}

html[dir="rtl"] .margin-left-4, html:not([dir="rtl"]) .margin-right-4 {
  margin-right: 4rem;
}

html[dir="rtl"] .margin-right-4 {
  margin-left: 4rem;
}

@media screen and (max-width: 859px) {
  html:not([dir="rtl"]) .tablet-gutter-left-0 {
    padding-left: 0;
  }

  html[dir="rtl"] .tablet-gutter-left-0, html:not([dir="rtl"]) .tablet-gutter-right-0 {
    padding-right: 0;
  }

  html[dir="rtl"] .tablet-gutter-right-0 {
    padding-left: 0;
  }

  html:not([dir="rtl"]) .tablet-gutter-left-1 {
    padding-left: 1rem;
  }

  html[dir="rtl"] .tablet-gutter-left-1, html:not([dir="rtl"]) .tablet-gutter-right-1 {
    padding-right: 1rem;
  }

  html[dir="rtl"] .tablet-gutter-right-1 {
    padding-left: 1rem;
  }

  html:not([dir="rtl"]) .tablet-gutter-left-2 {
    padding-left: 2rem;
  }

  html[dir="rtl"] .tablet-gutter-left-2, html:not([dir="rtl"]) .tablet-gutter-right-2 {
    padding-right: 2rem;
  }

  html[dir="rtl"] .tablet-gutter-right-2 {
    padding-left: 2rem;
  }

  html:not([dir="rtl"]) .tablet-gutter-left-3 {
    padding-left: 3rem;
  }

  html[dir="rtl"] .tablet-gutter-left-3, html:not([dir="rtl"]) .tablet-gutter-right-3 {
    padding-right: 3rem;
  }

  html[dir="rtl"] .tablet-gutter-right-3 {
    padding-left: 3rem;
  }

  html:not([dir="rtl"]) .tablet-gutter-left-4 {
    padding-left: 4rem;
  }

  html[dir="rtl"] .tablet-gutter-left-4, html:not([dir="rtl"]) .tablet-gutter-right-4 {
    padding-right: 4rem;
  }

  html[dir="rtl"] .tablet-gutter-right-4 {
    padding-left: 4rem;
  }
}

@media screen and (max-width: 479px) {
  html:not([dir="rtl"]) .phone-gutter-left-0 {
    padding-left: 0;
  }

  html[dir="rtl"] .phone-gutter-left-0, html:not([dir="rtl"]) .phone-gutter-right-0 {
    padding-right: 0;
  }

  html[dir="rtl"] .phone-gutter-right-0 {
    padding-left: 0;
  }

  html:not([dir="rtl"]) .phone-gutter-left-1 {
    padding-left: 1rem;
  }

  html[dir="rtl"] .phone-gutter-left-1, html:not([dir="rtl"]) .phone-gutter-right-1 {
    padding-right: 1rem;
  }

  html[dir="rtl"] .phone-gutter-right-1 {
    padding-left: 1rem;
  }

  html:not([dir="rtl"]) .phone-gutter-left-2 {
    padding-left: 2rem;
  }

  html[dir="rtl"] .phone-gutter-left-2, html:not([dir="rtl"]) .phone-gutter-right-2 {
    padding-right: 2rem;
  }

  html[dir="rtl"] .phone-gutter-right-2 {
    padding-left: 2rem;
  }

  html:not([dir="rtl"]) .phone-gutter-left-3 {
    padding-left: 3rem;
  }

  html[dir="rtl"] .phone-gutter-left-3, html:not([dir="rtl"]) .phone-gutter-right-3 {
    padding-right: 3rem;
  }

  html[dir="rtl"] .phone-gutter-right-3 {
    padding-left: 3rem;
  }

  html:not([dir="rtl"]) .phone-gutter-left-4 {
    padding-left: 4rem;
  }

  html[dir="rtl"] .phone-gutter-left-4, html:not([dir="rtl"]) .phone-gutter-right-4 {
    padding-right: 4rem;
  }

  html[dir="rtl"] .phone-gutter-right-4 {
    padding-left: 4rem;
  }
}

.headerText {
  font-size: calc(var(--apta-text-size) * 2);
  font-weight: calc(var(--apta-font-weight) * 3);
  margin: 0;
}

@media screen and (min-width: 768px) {
  .gm-border-right {
    border-right: 1px solid #ccc;
  }

  .gm-border-left {
    border-left: 1px solid #ccc;
  }
}

.text-blue {
  color: #36a2eb !important;
}

.gm-home-page-details-bold {
  font-weight: calc(var(--apta-font-weight) * 6);
}

.gm-home-page-details-unbold {
  font-weight: calc(var(--apta-font-weight) * 3);
}

.gm-homepage-font-size-small {
  font-size: calc(var(--apta-text-size) * 1);
}

.doughnut-title {
  font-weight: calc(var(--apta-font-weight) * 3);
  margin-bottom: 15px;
}

.gm-home-flex {
  justify-content: center;
  align-items: center;
  display: flex;
}

.gm-full-fill {
  height: 100%;
}

.usMapImg {
  height: 340px !important;
  margin-top: 40px !important;
}

.slick-prev {
  left: 50px;
}

.slick-next {
  right: 50px;
}

.slick-dots {
  z-index: 1;
  bottom: 10px;
}

.slick-arrow {
  z-index: 1;
}

.slick-slider {
  height: 100%;
}

.slick-slide {
  height: 100%;
  margin: 0;
}

.slick-list {
  background-color: #828282;
  height: 100%;
  margin: 0 -1px;
}

.slick-dots li button:before {
  color: #fff;
  opacity: 1;
  font-size: 20px;
}

.slick-dots li.slick-active button:before {
  color: #f2f2f2;
  opacity: .75;
}

.mainSlider {
  float: left;
  width: 100%;
  height: 635px;
  margin: 0;
  padding: 0;
}

.gm-multiSlideshow {
  height: 300px;
}

.gm-multiSlideshow div {
  margin-left: 5px !important;
  margin-right: 5px !important;
}

.fcolor {
  color: #000 !important;
}

.div-color {
  background-color: #add8e6;
  height: 300px !important;
}

.div-color2 {
  background-color: coral;
  height: 300px !important;
}

.footer {
  background: #f2f2f2;
  width: 100% !important;
}

.footerText {
  color: #555 !important;
}

.gm-section2 {
  background: #09162a;
}

.slide-img {
  object-position: 0px -300px;
  height: auto;
  width: 100% !important;
  position: relative !important;
}

.carousel .carousel-indicators li {
  width: 10px;
  height: 10px;
  border-radius: 100% !important;
}

.gm-text-carousel-container {
  opacity: .8;
  width: 100%;
  top: 25%;
  background-color: #201c1c !important;
  position: absolute !important;
}

.gm-text-carousel-container h2 {
  font-size: 25px;
}

.text-white {
  color: #fff !important;
}

.text-dark-blue {
  color: #0079c1;
}

.gm-section-2-card-container .outline-card {
  box-shadow: 0 0;
}

.gm-section-2-card-container .outline-card:nth-child(2), .gm-section-2-card-container-border {
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
}

.gm-section-5-card-container .card {
  box-shadow: 0 0;
}

.gm-section-5-card-container .card:nth-child(2) {
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
}

.gm-sub-search {
  margin-top: 0;
}

.gm-search-menu {
  margin-top: 5px;
}

.gm-member-text-left {
  padding-top: 10px;
  padding-left: 20px;
}

.gm-member-text-right {
  padding-top: 10px;
  padding-right: 15px;
}

.gm-tranSys-text {
  font-size: 80px;
  font-weight: 900;
}

.gm-funding-seb-text {
  font-size: 22px;
}

.gm-accordion-sub-text {
  font-size: 15px !important;
}

.gm-flex-funding-dough {
  flex-direction: row;
  display: flex;
}

@media (max-width: 1500px) {
  .slide-img {
    object-position: 0 -200px;
  }
}

@media (max-width: 1250px) {
  .slide-img {
    object-position: 0 -100px;
  }
}

@media (max-width: 1025px) {
  .gm-tranSys-text {
    font-size: 60px;
    font-weight: 800;
  }

  .gm-funding-head-text {
    font-size: 22px;
  }

  .gm-funding-seb-text {
    font-size: 18px;
  }

  .slide-img {
    object-fit: cover;
    object-position: 0 0;
  }

  .gm-text-carousel-container {
    opacity: .8;
    width: 100%;
    height: auto;
    top: 25%;
    background-color: #201c1c !important;
    position: absolute !important;
  }
}

@media (max-width: 768px) {
  html {
    font-size: 12px;
  }

  .mainSlider {
    height: 300px;
  }

  .gm-text-carousel {
    margin-bottom: 0;
    font-size: 30px;
  }

  .gm-sub-text-carousel {
    margin-bottom: 5px;
    font-size: 12px;
  }

  .slick-dots {
    bottom: 8px;
  }

  .slick-dots li button:before {
    font-size: 12px;
  }

  .gm-text-carousel-container {
    bottom: 34px;
  }

  .slide-img {
    object-fit: fill;
    background-size: 100% 100%;
    height: auto;
    width: 100% !important;
    position: relative !important;
  }

  .gm-tranSys-text {
    font-size: 50px;
    font-weight: 900;
  }

  .gm-funding-head-text {
    font-size: 22px;
  }

  .gm-funding-seb-text {
    font-size: 18px;
  }

  .gm-flex-funding-dough {
    flex-direction: column;
    display: flex;
  }

  .gm-section-2-card-container .card:nth-child(2) {
    border: 1px solid #ccc;
    border-width: 1px 0;
  }
}

@media (max-width: 425px) {
  .mainSlider {
    height: 168px;
  }

  .gm-text-carousel-container {
    height: 80px;
    bottom: 58px;
  }

  .gm-text-carousel {
    font-size: 16px;
  }

  .slide-img {
    object-fit: fill;
    background-size: 100% 100%;
    height: auto;
    width: 100% !important;
    position: relative !important;
  }

  .slick-arrow {
    visibility: hidden !important;
  }

  .gm-member-text-left {
    padding-top: 0;
    padding-left: 0;
  }

  .gm-member-text-right {
    padding-top: 0;
    padding-right: 0;
  }

  .gm-tranSys-text {
    font-size: 20px;
    font-weight: 900;
  }

  .gm-funding-head-text {
    font-size: 22px;
  }

  .gm-funding-seb-text {
    font-size: 18px;
  }

  .gm-border-right {
    border-right: 0;
  }

  .gm-border-left {
    border-left: 0;
  }

  .gm-text-carousel-container {
    opacity: .6;
  }

  .grid-container {
    clear: both !important;
  }

  .gm-flex-funding-dough {
    flex-direction: column;
    display: flex;
  }
}

@media (max-width: 767px) {
  .gm-border-right {
    border-width: 0;
  }
}

.gm-dropdown-search {
  color: #000;
  z-index: 1000;
  text-align: left;
  background-color: #fff;
  width: 100%;
  max-height: 200px;
  margin-top: 10px;
  position: absolute;
  overflow: scroll;
  box-shadow: 0 4px 8px #0003;
}

.gm-dropdown-search ul {
  margin-top: 0;
  padding-left: 0;
  list-style: none;
}

.gm-dropdown-search li {
  margin: 0;
  padding-top: 5px;
  padding-bottom: 5px;
}

.gm-dropdown-search li span {
  margin-left: 25px;
}

.gm-dropdown-search li:nth-child(odd) {
  z-index: 100;
  background-color: #f8f8f8;
  position: relative;
}

.gm-dropdown-search li:hover {
  z-index: 101;
  position: relative;
  box-shadow: 0 1px 4px #000 !important;
}

.gm-loader-parent {
  padding-top: 5px;
}

.gm-transBox-count {
  font-size: calc(var(--apta-text-size) * 5);
  font-weight: calc(var(--apta-font-weight) * 6);
}

@media (max-width: 768px) {
  .gm-transBox-count {
    font-size: calc(var(--apta-text-size) * 3);
  }
}

.text-purple {
  color: #7a003b !important;
}

.text-orange {
  color: #ff5e15 !important;
}

.gm-croppedLabel-table {
  margin: auto;
  padding-top: 10px;
  padding-left: 10px;
}

.gm-croppedLabel-table span {
  padding-top: 2px;
  padding-bottom: 2px;
  font-size: 12px;
}



.gm-side-panel-header-content, .gm-side-panel-header {
  flex-direction: row;
  display: flex;
}

:root {
  --apta-numeric-primary: #36a2eb;
  --apta-blue-text: #36a2eb;
  --apta-side-panel-blue: #003a73;
  --apta-color-primary: black;
  --apta-color-secondary: #6c757d;
  --apta-font-weight: 100;
  --apta-text-size: 1rem;
  --apta-navbar-color: #f4f7f6;
  --apta-navbar-border-color: #d21729;
  --apta-navbar-text-color: #33287c;
  --apta-carousel-text-color: #f4f7f6;
  --apta-carousel-height: 35rem;
  --apta-search-background-home: #36a2eb;
  --apta-search-background: #f4f7f6;
  --apta-red-text: #d21729;
}

.gm-panel-back-wrap {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.gm-side-panel-header {
  z-index: 50;
  background: #e8e8e8;
  flex-shrink: 0;
  align-items: center;
  height: 95px;
}

.gm-side-panel-header-content {
  justify-content: center;
  align-items: center;
  height: 100%;
  margin-left: 12px;
  position: relative;
}

.gm-side-panel-header-icon {
  align-items: center;
  width: 26px;
  height: 100%;
  display: flex;
}

.gm-side-panel-header-icon svg {
  width: 25px;
  height: 25px;
}

.gm-print-icon {
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  max-width: 38px;
  height: auto;
  margin-left: auto;
  display: flex;
}

.gm-print-icon svg {
  width: 25px;
  margin-left: 5px;
}

.gm-side-panel-nav {
  margin-left: 12px;
  margin-right: 12px;
  font-size: 20px;
  line-height: 1.3;
}

.gm-side-panel-nav-link:hover {
  cursor: pointer;
  text-decoration: underline;
}

.gm-atlas-icon {
  background: var(--apta-side-panel-blue);
  flex-direction: column;
  flex-shrink: 0;
  align-items: center;
  width: 75px;
  height: 100%;
  margin-left: auto;
  display: flex;
}

.gm-seperator {
  background-color: #fff;
  width: 100%;
  height: 1px;
}

.gm-atlas-icon img {
  cursor: pointer;
  background: #003a73;
  width: 30px;
  height: 30px;
  margin-top: 5px;
  margin-bottom: 10px;
}

.gm-panel-print-icon {
  cursor: pointer;
  width: 30px;
  height: 30px;
  margin-top: auto;
  margin-bottom: auto;
  position: relative;
}

.gm-panel-print-icon span {
  color: #fff;
  text-align: left;
  min-width: 20px;
  font-size: 12px;
  position: absolute;
  top: -8px;
  right: -20px;
}

.gm-panel-print-icon svg {
  color: #fff;
  fill: #fff;
}

.gm-panel-print-icon-active {
  border-bottom: 2px solid #fff;
  padding-bottom: 7px;
}

.gm-cart-count-panel {
  color: #fff;
  font-size: 10px;
  position: absolute;
  left: 32px;
}

.cart-tooltip {
  display: inline-block;
  position: relative;
}

.cart-tooltip .cart-tooltiptext {
  visibility: hidden;
  color: #fff;
  text-align: center;
  z-index: 1;
  opacity: .7;
  background-color: #000;
  border-radius: 6px;
  width: 220px;
  padding: 5px 0;
  font-size: 12px;
  position: absolute;
  top: -5px;
  right: 105%;
}

.cart-tooltip:hover .cart-tooltiptext {
  visibility: visible;
}

.gm-search-tab-container {
  flex-direction: row;
  display: flex;
}

.gm-search-no-data {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 400px;
  display: flex;
}

.gm-panel-tab {
  flex-direction: row;
  justify-content: center;
  width: 100%;
  padding-top: 10px;
  display: flex;
}

.gm-tab {
  text-align: center;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
}

.gm-tab:hover {
  cursor: pointer;
}

.gm-active {
  color: #fff;
  background-color: #003a72;
}

.two-icons {
  flex-direction: row;
  display: flex;
}

.fed-main {
  flex-direction: column;
  height: 100%;
  display: flex;
}

.fed-main svg {
  width: 50px;
  height: 50px;
  margin: auto auto 20px;
}

.fed-icons-parent {
  flex-direction: column;
  margin-top: 25px;
  display: flex;
}

.fed-icons {
  align-items: space-around;
  flex-direction: row;
  justify-content: space-around;
  display: flex;
}

.fed-icons img {
  width: 50px;
  height: auto;
  margin: auto;
}

.full-page-print-column-height {
  height: 30rem;
}

.under-map-column-height {
  height: 26rem;
}

@page {
  margin-top: .3in;
  margin-bottom: .3in;
  margin-left: .3in;
  margin-right: .3in;
}

.gm-cart-button:hover {
  color: #fff !important;
  background-color: #47318e !important;
  border-color: #47318e !important;
}

.gm-cart-button {
  color: #47318e !important;
  background-color: #fff !important;
  border-width: 2px !important;
  border-color: #47318e !important;
  border-radius: 10px !important;
  padding-left: 75px !important;
  padding-right: 75px !important;
  font-weight: 600 !important;
}

.gm-cart-button-invert {
  color: #fff !important;
  background-color: #47318e !important;
  border-color: #47318e !important;
  border-radius: 10px !important;
  padding-left: 75px !important;
  padding-right: 75px !important;
  font-weight: 600 !important;
}

@media (max-width: 425px) {
  .gm-cart-button {
    color: #47318e !important;
    background-color: #fff !important;
    border-width: 2px !important;
    border-color: #47318e !important;
    border-radius: 10px !important;
    padding-left: 25px !important;
    padding-right: 25px !important;
    font-weight: 600 !important;
  }

  .gm-cart-button-invert {
    color: #fff !important;
    background-color: #47318e !important;
    border-color: #47318e !important;
    border-radius: 10px !important;
    padding-left: 25px !important;
    padding-right: 25px !important;
    font-weight: 600 !important;
  }
}

.gm-format-card {
  cursor: pointer;
  width: 150px;
}

.gm-format-card-active {
  background-color: #36a2eb0d !important;
  border: 2px solid #36a2eb !important;
}

.gm-format-card-body {
  text-align: center;
  flex-direction: column;
  flex-grow: 1;
  display: flex !important;
}

:root {
  --apta-numeric-primary: #36a2eb;
  --apta-blue-text: #36a2eb;
  --apta-side-panel-blue: #003a73;
  --apta-color-primary: black;
  --apta-color-secondary: #6c757d;
  --apta-font-weight: 100;
  --apta-text-size: 1rem;
  --apta-navbar-color: #f4f7f6;
  --apta-navbar-border-color: #d21729;
  --apta-navbar-text-color: #33287c;
  --apta-carousel-text-color: #f4f7f6;
  --apta-carousel-height: 35rem;
  --apta-search-background-home: #36a2eb;
  --apta-search-background: #f4f7f6;
  --apta-red-text: #d21729;
  --print-numeric-primary: var(--apta-numeric-primary);
  --print-blue-text: var(--apta-blue-text);
  --print-side-panel-blue: var(--apta-side-panel-blue);
  --print-color-primary: var(--apta-color-primary);
  --print-color-secondary: var(--apta-color-secondary);
  --print-font-weight: 100;
  --print-text-size: 1em;
  --print-navbar-color: #f4f7f6;
  --print-navbar-border-color-bottom: var(--apta-navbar-border-color);
  --print-navbar-border-color-top: #0661a0;
  --print-navbar-text-color: var(--apta-navbar-text-color);
  --print-carousel-text-color: var(--apta-carousel-text-color);
  --print-carousel-height: 45rem;
  --print-search-background: var(--apta-search-background);
  --print-search-background: var(--apta-search-background);
  --print-red-text: var(--apta-red-text);
}

#gm-cart-header-end, #gm-cart-header h1, #gm-cart-header {
  flex-direction: row;
  display: flex;
}

#gm-cart-parent {
  flex-direction: column;
  display: flex;
}

:root {
  --apta-numeric-primary: #36a2eb;
  --apta-blue-text: #36a2eb;
  --apta-side-panel-blue: #003a73;
  --apta-color-primary: black;
  --apta-color-secondary: #6c757d;
  --apta-font-weight: 100;
  --apta-text-size: 1rem;
  --apta-navbar-color: #f4f7f6;
  --apta-navbar-border-color: #d21729;
  --apta-navbar-text-color: #33287c;
  --apta-carousel-text-color: #f4f7f6;
  --apta-carousel-height: 35rem;
  --apta-search-background-home: #36a2eb;
  --apta-search-background: #f4f7f6;
  --apta-red-text: #d21729;
}

.gm-loading-div-above {
  text-align: center;
  width: 100%;
  height: 25px;
}

.gm-loading-div-above p {
  text-align: center;
  font-weight: bold;
  margin: 0 !important;
  padding: 0 !important;
}

.gm-cart-header p {
  margin-top: 10px;
  margin-bottom: 0;
  padding: 10px;
}

.gm-zip-loading p {
  margin-bottom: 0;
  margin-left: 10px;
}

.gm0 .gm-cart-parent {
  height: 100%;
}

.gm-cart-content {
  background: #fff;
  width: 100%;
}

.gm-cart-content .alert {
  z-index: 1;
}

#gm-cart-parent {
  margin: 0;
  padding-left: 12px;
}

#gm-cart-header {
  margin: 0;
  padding-bottom: 2px;
}

#gm-cart-header p, #gm-cart-header h1 {
  margin: 0;
}

#gm-cart-header-end {
  margin-top: auto;
  margin-bottom: auto;
  margin-left: auto;
  padding-bottom: .75rem;
}

#gm-cart-header-end :hover {
  cursor: pointer;
}

#gm-cart-header-end p {
  margin: 0 10px;
}

.gm-cart-buttons {
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  margin-top: 15px;
  margin-bottom: 15px;
  display: flex;
}

.gm-cart-buttons button {
  margin-left: 5px;
}

.gm-button-div {
  height: 100%;
  margin-left: auto;
  display: flex;
}

#gm-cart-header-svg {
  width: 25px;
  height: 25px;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: auto;
}

.gm-layer-section {
  overflow: wrap;
}

.gm-layer-list {
  flex-flow: wrap;
  flex-grow: 1;
  align-items: flex-start;
  display: flex;
}

.gm-layer-toggle {
  background-color: #efefef;
  border: 1px solid #ccc;
  justify-content: flex-start;
  align-items: center;
  min-width: 250px;
  margin-top: 10px;
  margin-left: 10px;
  display: flex;
}

.gm-back-button-svg {
  width: 35px;
  height: 35px;
}

.gm-map-layers-container {
  margin-top: 10px;
  margin-bottom: 10px;
}

.gm-map-layers-container :hover {
  cursor: pointer;
  background: #efefef;
}

.gm-map-layers-header {
  flex-direction: row;
  align-items: center;
  display: flex;
}

.gm-map-layers-header h1 {
  margin: 0;
  font-size: 24px;
}

.gm-map-layers-header p {
  margin: 0 auto 0 0;
}

.gm-map-header-left {
  flex-direction: row;
  align-items: center;
  margin-left: auto;
  display: flex;
}

.gm-map-header-left h1 {
  margin: 0;
  font-size: 24px;
}

.gm-map-header-left span {
  margin-right: 15px;
  font-size: 12px;
}

.gm-map-layers-svg {
  width: 25px;
  height: 25px;
  margin-left: auto;
}

.gm-full-nav-links {
  text-transform: uppercase;
  border-bottom: 4px solid #0000;
  position: relative;
  color: var(--apta-navbar-text-color) !important;
  font-weight: calc(var(--apta-font-weight) * 5) !important;
  font-size: calc(var(--apta-text-size) * 1) !important;
  margin-left: 20px !important;
}

@media screen and (max-width: 1075px) {
  .gm-full-nav-links {
    font-size: 12px;
  }
}

@media screen and (max-width: 873px) {
  .gm-full-nav-links {
    font-size: 11px;
  }
}

.gm-navbar-hamburger {
  font-size: calc(var(--apta-text-size) * 2) !important;
  color: var(--apta-navbar-text-color) !important;
}

.apta-logo-white {
  height: calc(var(--apta-text-size) * 4) !important;
}

.navbar-text {
  color: var(--apta-navbar-text-color) !important;
}

.navbar-dark .nav-link:hover, .navbar-dark .nav-link:focus {
  border-bottom: 4px solid var(--apta-navbar-text-color);
}

.gm-main-header {
  background: var(--apta-navbar-color);
  border-bottom: 4px solid var(--apta-navbar-border-color);
  width: 100%;
}

.nav-item:hover {
  cursor: pointer;
}

@media screen and (max-width: 992px) {
  .gm-header-dropdown {
    z-index: 101;
    background-color: #003a73;
    flex-direction: column;
    width: 300px;
    height: 300px;
    padding: 10px;
    position: absolute;
    top: 50px;
    left: calc(50% - 300px) !important;
  }
}

@media screen and (min-width: 993px) {
  .gm-header-dropdown {
    top: 50px;
    left: -200px;
  }
}

.gm-header-dropdown {
  z-index: 101;
  background-color: #003a73;
  border-radius: 10px;
  flex-direction: column;
  width: 300px;
  height: 300px;
  padding: 10px;
  display: flex;
  position: absolute;
}

.gm-header-dropdown h6 {
  text-align: center;
  color: #fff;
  margin-top: 10px;
  margin-bottom: 6px;
  font-size: 1.2rem;
  font-weight: 700;
}

.gm-header-dropdown p {
  text-align: center;
  text-transform: none;
  color: #fff;
  letter-spacing: -1px;
  margin-bottom: .5rem;
  padding-left: 9.8px;
  padding-right: 9.8px;
  font-size: .8rem;
  font-weight: 200;
}

.gm-header-dropdown input {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  width: 90%;
  margin-top: 0;
  margin-left: 10px;
}

.gm-clipboard {
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.gm-clipboard input {
  width: 70%;
  margin-bottom: 5px;
  margin-right: 8px;
}

.gm-clipboard svg {
  color: #fff;
  fill: #fff;
  width: 25px;
  height: 25px;
}

.gm-copy-group {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
}

.gm-copy-group svg {
  color: #005e95;
  width: 45px;
  height: 25px;
}

@media print {
  .gm-header-div {
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
    display: flex;
  }

  .gm-print-NONE {
    display: none;
  }
}

@media screen and (max-width: 380px) {
  .tablet-apta-logo {
    height: 50px;
  }

  .hamburger-link {
    font-size: 20px;
  }
}

.gm-person-list-elem-img {
  border-radius: 50%;
  flex-shrink: 0;
  width: 75px;
  height: 75px;
}

.gm-district-span {
  flex-direction: row;
  margin-top: auto;
  margin-left: auto;
  display: flex;
}

.gm-person-list {
  width: 100%;
  padding: 0;
}

.gm-button-list-elem {
  flex-direction: row;
  width: 100%;
  margin-top: 0;
  list-style: none;
  display: flex;
}

.gm-button-list-elem-rest {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  display: flex;
}

.gm-button-list-elem-img {
  width: 75px;
  height: auto;
  margin-left: 10px;
  margin-right: 15px;
}

.gm-person-list-elem {
  flex-direction: column;
  width: 100%;
  margin-top: 0;
  list-style: none;
  display: flex;
  position: relative;
}

.gm-person-list-elem hr {
  margin: 0;
}

.gm-person-list-elem-content {
  flex-direction: row;
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
}

.gm-person-list-elem-img {
  object-fit: cover;
  border-radius: 50%;
  flex-shrink: 0;
  width: 75px;
  height: 75px;
  overflow: hidden;
}

.gm-person-list-elem-details {
  flex-direction: column;
  display: flex;
}

.gm-person-list-toggles {
  flex-direction: column;
  margin-left: auto;
  margin-right: 5px;
  display: flex;
}

.gm-person-list-toggles svg {
  margin-left: auto;
}

.gm-person-list-toggles .gm-printer-icon {
  flex-direction: row;
  margin-left: auto;
  display: flex;
}

.gm-person-list-toggles .gm-printer-icon p {
  margin-right: 5px;
}

.gm-district-span {
  flex-direction: row;
  max-width: 225px;
  margin-top: auto;
  margin-left: auto;
  display: flex;
}

.gm-unset-height {
  height: 75px !important;
}

.spinner {
  text-align: center;
  width: 70px;
  height: 40px;
  margin: 100px auto;
  font-size: 10px;
}

.loading-spinner-container {
  color: #fff;
  background-color: #7a003c;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 70px;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
}

.spinner > div {
  background-color: #fff;
  width: 6px;
  height: 70%;
  margin: 0 2px;
  animation: 1.2s ease-in-out infinite sk-stretchdelay;
  display: inline-block;
}

.spinner-text {
  color: #fff;
  font-size: 12px;
}

.spinner .rect2 {
  animation-delay: -1.1s;
}

.spinner .rect3 {
  animation-delay: -1s;
}

.spinner .rect4 {
  animation-delay: -.9s;
}

.spinner .rect5 {
  animation-delay: -.8s;
}

@-webkit-keyframes sk-stretchdelay {
  0%, 40%, 100% {
    -webkit-transform: scaleY(.4);
  }

  20% {
    -webkit-transform: scaleY(1);
  }
}

@keyframes sk-stretchdelay {
  0%, 40%, 100% {
    transform: scaleY(.4);
  }

  20% {
    transform: scaleY(1);
  }
}

.gm-slide-container {
  height: var(--apta-carousel-height);
}

@media screen and (max-width: 600px) {
  .gm-slide-container {
    height: calc(var(--apta-carousel-height) / 2);
  }
}

.gm-slide-text {
  z-index: 10;
  color: var(--apta-carousel-text-color);
  opacity: .8;
  padding: 0 5%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #201c1c !important;
}

.gm-slide-text h1 {
  padding-top: 24px;
  line-height: 1.1;
  font-size: calc(var(--apta-text-size) * 3.5) !important;
  font-weight: calc(var(--apta-font-weight) * 7) !important;
}

.gm-slide-text p {
  margin: 0;
  padding-bottom: 24px;
  font-size: calc(var(--apta-text-size) * 1.5) !important;
  font-weight: calc(var(--apta-font-weight) * 7) !important;
}

@media screen and (max-width: 600px) {
  .gm-slide-text {
    margin-bottom: 12%;
  }

  .gm-slide-text h1 {
    padding-top: 10px;
    font-size: calc(var(--apta-text-size) * 1.8) !important;
    font-weight: calc(var(--apta-font-weight) * 7) !important;
  }

  .gm-slide-text p {
    margin: 0;
    padding-bottom: 10px;
    font-size: calc(var(--apta-text-size) * 1.2) !important;
    font-weight: calc(var(--apta-font-weight) * 7) !important;
  }
}

.slick-dots {
  z-index: 1;
  bottom: 10px;
}

.slick-slider {
  height: 100%;
}

.slick-slide {
  height: 100%;
  margin: 0;
}

.slick-list {
  background-color: #828282;
  height: 100%;
  margin: 0 -1px;
}

.slick-dots li {
  margin: 0;
}

@media screen and (min-width: 992px) {
  .slick-dots li {
    margin: 0 5px;
  }
}

.slick-dots li button:before {
  color: #fff;
  opacity: 1;
  font-size: 10px;
}

@media screen and (min-width: 992px) {
  .slick-dots li button:before {
    font-size: 20px;
  }
}

.slick-dots li.slick-active button:before {
  color: #f2f2f2;
  opacity: .75;
}

.carousel-indicators > li {
  padding: 2px;
  opacity: 1 !important;
  border-radius: 50px !important;
  width: 15px !important;
  height: 15px !important;
}

.carousel-indicators .active {
  opacity: .4 !important;
}

.gm-homepage-carousel-image {
  object-fit: cover;
  min-width: 100%;
  min-height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  overflow: hidden;
  transform: translate(-50%, -50%);
}

.gm-search-parent {
  background-color: var(--apta-search-background-home);
  clear: both;
  height: calc(var(--apta-text-size) * 8);
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
}

.gm-search-parent p {
  color: var(--apta-navbar-text-color);
  margin: 0;
}

.gm-search-bar ::-webkit-input-placeholder {
  color: #1c1c1c;
}

.gm-search-bar :-ms-input-placeholder {
  color: #1c1c1c;
}

.gm-search-bar ::placeholder {
  color: #000;
}

.gm-search-bar .gm-search-clear {
  color: var(--apta-navbar-text-color);
  cursor: pointer;
  padding-right: .75rem;
  position: absolute;
  top: 5px;
  right: 0;
}

.gm-search-bar .gm-input {
  color: #1c1c1c;
  font-weight: calc(var(--apta-font-weight) * 2);
  box-shadow: none;
  background-color: #fff;
  border: none;
  margin: 0;
  font-size: calc(var(--apta-text-size) * 1.75) !important;
}

.gm-search-bar .gm-input::placeholder {
  font-size: 1.4rem;
  position: absolute;
  top: 50%;
  transform: translate(0%, -50%);
}

.gm-search-bar .gm-input:focus {
  color: #1c1c1c;
}

.gm-state-image-container {
  flex-shrink: 0;
  justify-content: center;
  width: 77px;
  height: 65px;
  display: flex;
  overflow: hidden;
}

.gm-state-image {
  object-fit: cover;
  border: 1px solid #000c;
  width: 75px;
  height: auto;
  margin-top: auto;
  margin-bottom: auto;
}

.gm-rhode-island-img {
  width: 65px !important;
}

.gm-auto-height {
  height: auto;
}

.gm-no-border {
  border: none;
}

.gm-person-chevron {
  align-self: flex-end;
  width: 20px;
  height: 20px;
  line-height: 1;
}

.gm-panel-list {
  padding: 0;
}

.gm-panel-list-elem {
  cursor: pointer;
  border-bottom: 1px solid #a7a7a7;
  flex-direction: row;
  align-items: center;
  margin: 0;
  padding: 10px 0;
  list-style: none;
  display: flex;
  position: relative;
}

@media screen and (max-width: 699px) {
  .gm-panel-list-elem {
    width: 100%;
  }
}

.gm-collapsible-header-title h1 {
  font-weight: 300;
}

.gm-panel-list-elem:hover {
  background-color: #a7a7a71a;
}

.gm-panel-list-elem-children {
  flex-direction: row;
  width: 100%;
  margin-left: 15px;
  display: flex;
}

.gm-state-span-icon-wrapper {
  margin-left: auto;
}

.gm-li-state-span {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.gm-li-state-chevron-wrapper {
  margin-top: 5px;
  margin-left: auto;
}

.gm-person-panel-table {
  width: 100%;
}

.gm-person-panel-table tr td:first-of-type {
  width: 35%;
}

.gm-person-panel-table tr td:nth-of-type(2) {
  text-align: middle;
}

.gm-collapsible-header-title {
  cursor: pointer;
}

.gm-collapsible-header-title h1 {
  margin: 0;
  font-size: 21px;
}

.gm-collapsible-header-title div:first-of-type {
  margin: auto 0 auto auto;
}

.gm-search-panel section {
  padding: 10px 0;
}

.gm-search-panel-tabs {
  flex-direction: row;
  display: flex;
}

.gm-search-panel-tab {
  text-align: center;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
}

.gm-search-panel-tab:hover {
  cursor: pointer;
}

.gm-search-panel-tab-active {
  color: #fff;
  background-color: #003a72;
}

.gm-search-panel-results p {
  text-align: center;
}

.gm-apta-member-section {
  margin: 0 15px;
}

.gm-apta-member-section p {
  margin-top: 10px;
  margin-bottom: 15px;
}

.gm-apta-member-section td {
  min-width: 175px;
}

.gm-data-section {
  width: 700px;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (max-width: 699px) {
  .gm-data-section {
    width: 100%;
  }

  .gm-data-section .block-group {
    padding: 5px;
  }
}

.gm-major-grant-section .gm-print-section-full {
  width: 720px;
  overflow-x: hidden;
}

@media screen and (max-width: 719px) {
  .gm-major-grant-section .gm-print-section-full {
    width: 100%;
    height: 650px;
  }

  .gm-major-grant-section .gm-print-section-full h1 {
    width: 350px;
  }
}

.gm-data-summary-section .gm-print-section-full {
  width: 720px;
  height: 340px;
  overflow-x: hidden;
}

@media screen and (max-width: 719px) {
  .gm-data-summary-section .gm-print-section-full {
    width: 100%;
    height: 400px;
  }

  .gm-data-summary-section .gm-print-section-full h1 {
    width: 350px;
  }
}

#mapContainer {
  flex-direction: row;
  display: flex;
  position: relative;
}

#gm-side-panel-parent {
  flex-direction: column;
  flex-shrink: 0;
  width: 500px;
  height: 750px;
  display: flex;
  overflow: hidden;
}

@media screen and (max-width: 499px) {
  #gm-side-panel-parent {
    width: 100%;
    height: 500px;
  }
}

.gm-side-panel-content {
  overflow-y: scroll;
}

.gm-map-section {
  background-color: #1d2223;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 750px;
  display: flex;
  position: relative;
}

@media screen and (max-width: 499px) {
  .gm-map-section {
    height: 500px;
  }
}

.make-display-none {
  display: none !important;
}

.make-this-transparent, .make-this-transparent-inactive {
  z-index: 100;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
}

@media screen and (max-width: 550px) {
  .make-this-transparent-inactive {
    display: none;
  }
}

#viewDiv {
  width: 100%;
  height: 100%;
  position: absolute;
}

.gm-hide {
  width: 0 !important;
}

.gm-slide-toggle {
  z-index: 0;
  cursor: pointer;
  background: #fff;
  border-radius: 2px;
  width: 25px;
  height: 45px;
  padding: 0 0 0 5px;
  line-height: 45px;
  position: absolute;
  top: 381px;
  left: 500px;
  box-shadow: 0 2px 6px #0000003d;
  z-index: 1 !important;
}

@media screen and (max-width: 699px) {
  .gm-slide-toggle {
    left: initial;
    top: 381px;
    right: 0;
  }
}

.gm-slide-toggle-hide-sidePanel {
  z-index: 1;
  cursor: pointer;
  background: #fff;
  border-radius: 2px;
  width: 25px;
  height: 45px;
  padding: 1px 0 0 5px;
  line-height: 45px;
  position: absolute;
  top: 381px;
  left: 0;
  transform: rotate(180deg);
  box-shadow: 0 2px 6px #0000003d;
  z-index: 1000 !important;
}

.gm-cart-select-header {
  cursor: pointer;
  border-bottom: 1px solid gray;
  flex-direction: row;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
}

.gm-cart-select-header h1 {
  margin-bottom: 0;
  font-size: 20px;
  font-weight: 400;
}

.gm-cart-select-header-left {
  margin-left: auto;
}

.gm-cart-select-elem-container {
  flex-flow: wrap;
  padding: 10px;
  display: flex;
}

.gm-cart-select-elem {
  cursor: pointer;
  flex-direction: row;
  margin-bottom: 15px;
  margin-right: 5px;
  padding: 10px;
  display: flex;
}

.gm-cart-select-elem h1 {
  margin: 0;
  font-size: 15px;
}

.gm-cart-select-elem p {
  font-size: 15px;
}

.gm-cart-table table {
  margin: 0;
}

.gm-cart-table thead {
  background-color: #00f;
}

.gm-cart-table td {
  border: 1px solid red;
}

.gm-table-container {
  max-height: 400px;
  overflow-y: scroll;
}

.gm-table-container table {
  margin: 0;
}

.gm-remove-td {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  display: flex;
}

.gm-remove-icon:hover {
  cursor: pointer;
}

.rdg-table-height-1 {
  height: 100px !important;
}

.rdg-table-height-2 {
  height: 152px !important;
}

.rdg-table-height-3 {
  height: 207px !important;
}

.rdg-table-height-4 {
  height: 262px !important;
}

.rdg-table-height-5 {
  height: 317px !important;
}

.rdg-table-height-6 {
  height: 372px !important;
}

.sk-circle {
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  margin: 10px auto;
  display: flex;
  position: relative;
}

.sk-circle .sk-child {
  text-align: center;
  width: 50%;
  height: 50%;
  margin-left: auto;
  margin-right: auto;
  position: absolute;
  left: 0;
  right: 0;
}

.sk-circle .sk-child:before {
  content: "";
  background-color: #0661a0;
  border-radius: 100%;
  width: 20%;
  height: 20%;
  animation: 1.2s ease-in-out infinite both sk-circleBounceDelay;
  display: block;
}

.sk-circle .sk-circle2 {
  transform: rotate(30deg);
}

.sk-circle .sk-circle3 {
  transform: rotate(60deg);
}

.sk-circle .sk-circle4 {
  transform: rotate(90deg);
}

.sk-circle .sk-circle5 {
  transform: rotate(120deg);
}

.sk-circle .sk-circle6 {
  transform: rotate(150deg);
}

.sk-circle .sk-circle7 {
  transform: rotate(180deg);
}

.sk-circle .sk-circle8 {
  transform: rotate(210deg);
}

.sk-circle .sk-circle9 {
  transform: rotate(240deg);
}

.sk-circle .sk-circle10 {
  transform: rotate(270deg);
}

.sk-circle .sk-circle11 {
  transform: rotate(300deg);
}

.sk-circle .sk-circle12, .sk-circle .sk-circle13 {
  transform: rotate(330deg);
}

.sk-circle .sk-circle2:before {
  animation-delay: -1.1s;
}

.sk-circle .sk-circle3:before {
  animation-delay: -1s;
}

.sk-circle .sk-circle4:before {
  animation-delay: -.9s;
}

.sk-circle .sk-circle5:before {
  animation-delay: -.8s;
}

.sk-circle .sk-circle6:before {
  animation-delay: -.7s;
}

.sk-circle .sk-circle7:before {
  animation-delay: -.6s;
}

.sk-circle .sk-circle8:before {
  animation-delay: -.5s;
}

.sk-circle .sk-circle9:before {
  animation-delay: -.4s;
}

.sk-circle .sk-circle10:before {
  animation-delay: -.3s;
}

.sk-circle .sk-circle11:before {
  animation-delay: -.2s;
}

.sk-circle .sk-circle12:before, .sk-circle .sk-circle13:before {
  animation-delay: -.1s;
}

@keyframes sk-circleBounceDelay {
  0%, 80%, 100% {
    transform: scale(0);
  }

  40% {
    transform: scale(1);
  }
}

.gm-remove-icon {
  vertical-align: top;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: inline-flex;
}

.gm-remove-icon svg {
  width: 50%;
  height: 50%;
}

.gm-back-icon {
  width: 70px;
  height: 35px;
}

.gm-back-icon svg {
  width: 100%;
  height: 100%;
}

.gm-cart-header {
  flex-direction: column;
  margin-top: 15px;
  margin-bottom: 15px;
  display: flex;
}

.gm-cart-header h1 {
  margin-bottom: 0;
  font-size: 20px;
  font-weight: 400;
}

.gm-cart-loading {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

body {
  -webkit-print-color-adjust: exact !important;
}

.chart-canvas {
  margin-top: auto;
  margin-bottom: auto;
  width: 100% !important;
}

.gm-section-header, .gm-section-header-full, .gm-section-header-postcard {
  height: "100%";
  border-bottom: 1px solid #000;
}

.gm-subitem-img {
  width: 5rem;
  height: 5rem;
}

.gm-subitem-img-postcard {
  width: 7rem;
  height: 7rem;
}

.gm-subitem {
  width: 0;
  height: 5rem;
}

.gm-subitem-postcard {
  width: 0;
  height: 7rem;
}

.gm-explore-header, .gm-explore-header-full {
  font-size: calc(var(--apta-text-size) * 2);
  font-weight: calc(var(--apta-font-weight) * 3);
  margin: 0;
  color: var(--atpa-color-primary) !important;
  line-height: calc(var(--apta-text-size) * 2) !important;
}

.gm-explore-header-postcard {
  color: var(--atpa-color-primary) !important;
  font-size: calc(var(--apta-text-size) * 3) !important;
  line-height: calc(var(--apta-text-size) * 3) !important;
  font-weight: calc(var(--apta-font-weight) * 3) !important;
}

.gm-explore-numeric, .gm-explore-numeric-full {
  margin: 0;
  color: var(--apta-numeric-primary) !important;
  font-size: calc(var(--apta-text-size) * 2) !important;
  line-height: calc(var(--apta-text-size) * 2) !important;
  font-weight: calc(var(--apta-font-weight) * 5) !important;
}

.gm-explore-numeric-postcard {
  color: var(--apta-numeric-primary) !important;
  font-size: calc(var(--apta-text-size) * 3) !important;
  line-height: calc(var(--apta-text-size) * 3) !important;
  font-weight: calc(var(--apta-font-weight) * 5) !important;
}

.gm-explore-numeric-2, .gm-explore-numeric-2-full {
  margin: 0;
  color: var(--apta-numeric-primary) !important;
  font-size: calc(var(--apta-text-size) * 2) !important;
  line-height: calc(var(--apta-text-size) * 2) !important;
  font-weight: calc(var(--apta-font-weight) * 5) !important;
}

.gm-explore-numeric-2-postcard {
  color: var(--apta-numeric-primary) !important;
  font-size: calc(var(--apta-text-size) * 2) !important;
  line-height: calc(var(--apta-text-size) * 2) !important;
  font-weight: calc(var(--apta-font-weight) * 5) !important;
}

.gm-explore-numeric-3, .gm-explore-numeric-3-full {
  margin: 0;
  color: var(--apta-numeric-primary) !important;
  font-size: calc(var(--apta-text-size) * 1.5) !important;
  line-height: calc(var(--apta-text-size) * 1.5) !important;
  font-weight: calc(var(--apta-font-weight) * 5) !important;
}

.gm-explore-numeric-4, .gm-explore-numeric-4-full {
  margin: 0;
  color: var(--apta-numeric-primary) !important;
  font-size: calc(var(--apta-text-size) * 1) !important;
  line-height: calc(var(--apta-text-size) * 1.5) !important;
  font-weight: calc(var(--apta-font-weight) * 5) !important;
}

.gm-explore-numeric-4-postcard {
  margin: 0;
  color: var(--apta-numeric-primary) !important;
  font-size: calc(var(--apta-text-size) * 1.1) !important;
  line-height: calc(var(--apta-text-size) * 1.5) !important;
  font-weight: calc(var(--apta-font-weight) * 5) !important;
}

.gm-explore-text {
  margin: 0;
  color: var(--atpa-color-primary) !important;
  font-size: calc(var(--apta-text-size) * 1.4) !important;
  line-height: calc(var(--apta-text-size) * 1.5) !important;
  font-weight: calc(var(--apta-font-weight) * 5) !important;
}

.gm-explore-text-full {
  margin: 0;
  color: var(--atpa-color-primary) !important;
  font-size: calc(var(--apta-text-size) * 1.5) !important;
  line-height: calc(var(--apta-text-size) * 1.5) !important;
  font-weight: calc(var(--apta-font-weight) * 5) !important;
}

.gm-explore-text-postcard {
  margin-bottom: 0;
  color: var(--atpa-color-primary) !important;
  font-size: calc(var(--apta-text-size) * 2) !important;
  line-height: calc(var(--apta-text-size) * 2) !important;
  font-weight: calc(var(--apta-font-weight) * 5) !important;
}

.gm-explore-text-2, .gm-explore-text-2-full {
  margin: 0;
  color: var(--atpa-color-primary) !important;
  font-size: calc(var(--apta-text-size) * 1.5) !important;
  line-height: calc(var(--apta-text-size) * 1.5) !important;
  font-weight: calc(var(--apta-font-weight) * 4) !important;
}

.gm-explore-text-2-postcard {
  margin-bottom: 0;
  color: var(--atpa-color-primary) !important;
  font-size: calc(var(--apta-text-size) * 2) !important;
  line-height: calc(var(--apta-text-size) * 2) !important;
  font-weight: calc(var(--apta-font-weight) * 5) !important;
}

.gm-explore-text-3, .gm-explore-text-3-full {
  margin: 0;
  color: var(--atpa-color-primary) !important;
  font-size: calc(var(--apta-text-size) * 1) !important;
  font-weight: calc(var(--apta-font-weight) * 4) !important;
}

.gm-explore-text-3-postcard {
  color: var(--atpa-color-primary) !important;
  font-size: calc(var(--apta-text-size) * 1.2) !important;
  font-weight: calc(var(--apta-font-weight) * 4) !important;
}

.gm-explore-subtext {
  margin: 0;
  color: var(--apta-color-secondary) !important;
  font-size: calc(var(--apta-text-size) * 1.5) !important;
  line-height: calc(var(--apta-text-size) * 2) !important;
  font-weight: calc(var(--apta-font-weight) * 2) !important;
}

.gm-explore-subtext-full {
  margin: 0;
  color: var(--apta-color-secondary) !important;
  font-size: calc(var(--apta-text-size) * 1.5) !important;
  line-height: calc(var(--apta-text-size) * 1.5) !important;
  font-weight: calc(var(--apta-font-weight) * 2) !important;
}

.gm-explore-subtext-legend, .gm-explore-subtext-legend-full {
  margin: 0;
  color: var(--atpa-color-primary) !important;
  font-size: calc(var(--apta-text-size) * 1) !important;
  line-height: calc(var(--apta-text-size) * 1.2) !important;
  font-weight: calc(var(--apta-font-weight) * 2) !important;
}

.gm-explore-image {
  height: 6px;
}

.gm-member-summay-section-text {
  color: var(--apta-numeric-primary);
  font-size: calc(var(--apta-text-size) * 1) !important;
}

.gm-member-summay-text {
  color: var(--apta-color-secondary);
  font-size: calc(var(--apta-text-size) * 1) !important;
}

@media print {
  canvas.chart-canvas {
    max-width: 100%;
    display: flex;
    width: auto !important;
    height: auto !important;
  }
}

.gm-v2-panel-header {
  z-index: 50;
  background: #e8e8e8;
  flex-shrink: 0;
  width: 100%;
  height: 95px;
}

.gm-v2-panel-header-buttons {
  height: 100%;
  margin-left: 0;
  display: flex;
}

@media print {
  * {
    transition: none !important;
  }

  div {
    break-inside: avoid;
  }
}

.gm-standard-print-header {
  border-top: 4px solid var(--print-navbar-border-color-top);
  border-bottom: 4px solid var(--print-navbar-border-color-bottom);
  font-size: var(--print-text-size);
}

.gm-header-person img {
  object-fit: cover;
  width: 5rem !important;
  height: 5rem !important;
}

.gm-header-logo img {
  object-fit: cover;
  width: 3.5rem !important;
  height: 3.5rem !important;
}

.gm-invis {
  display: none;
}

@media screen {
  .gm-print-helper {
    justify-content: center;
    width: 100%;
    height: 100%;
    display: flex;
  }

  .gm-print-page {
    width: 700px;
  }
}

.gm-no-data {
  width: 100%;
}

.gm-no-data p {
  text-align: center;
  color: #36a2eb;
  margin-top: 25px;
  font-size: 28px;
  font-weight: normal;
}

.gm-print-page {
  orphans: 3;
}

.gm-data-summary-item {
  width: 350px;
  margin-bottom: 5px;
  font-weight: bold;
  display: inline-block;
}

.gm-data-summary-item p {
  text-align: center;
}

.gm-data-summary-item p:first-of-type {
  color: #4c4c4c;
  margin: 0 0 3px;
  font-size: 14px;
}

.gm-data-summary-item p:nth-of-type(2) {
  color: #36a2eb;
  margin: 0;
  font-size: 20px;
}

.gm-legend-column h1 {
  font-size: calc(var(--apta-text-size) * 1.5) !important;
  line-height: calc(var(--apta-text-size) * 1.5) !important;
  font-weight: calc(var(--apta-font-weight) * 2) !important;
}

.gm-legend-column h3 {
  font-size: 10px;
}

.gm-map-legend-container {
  flex-wrap: wrap;
  display: flex;
}

.gm-map-legend-layer {
  flex-direction: column;
  flex-grow: 1;
  max-width: 30%;
  display: flex;
}

.gm-map-legend-elem {
  flex-direction: row;
  align-items: center;
  display: flex;
}

.gm-map-legend-elem label {
  margin: 0 0 0 5px;
  display: inline;
}

.gm-map-legend-elem div {
  display: inline;
}

.gm-postcard-print-header {
  border-top: 4px solid var(--print-navbar-border-color-top);
  border-bottom: 4px solid var(--print-navbar-border-color-bottom);
  font-size: var(--print-text-size);
}

.gm-postcard-print-header img {
  object-fit: cover;
  width: 7rem !important;
  height: 7rem !important;
}

html, body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  display: block;
  position: relative;
}

#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  position: relative;
}

#gm-print-view-div {
  height: 500px;
}

.gm-pos-relative {
  height: 100%;
  position: relative;
}

.gm-error-div {
  z-index: 50000;
  background-color: #a9a9a9cc;
  width: 100vw;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.gm-error-message {
  color: #fff;
  text-align: center;
  z-index: 105;
  background-color: #7a003c;
  width: 400px;
  height: 200px;
  padding: 15px;
  font-size: 25px;
  display: flex;
  position: absolute;
  top: 33vh;
  left: 50%;
  transform: translate(-50%, -33%);
}

.gm-error-message p {
  height: 100%;
  margin: 0;
  padding-top: 30px;
  display: flex;
}

.gm-display-none, .esriSignInDialog {
  display: none;
}

.gm-blue-h2 {
  color: #36a2eb;
  font-size: 45px;
  font-weight: 900;
}

.gm-red-text {
  color: var(--apta-red-text) !important;
}

.gm-blue-text {
  color: var(--apta-navbar-text-color);
}

.gm-basic-button {
  border: none;
  border-radius: 30px;
  padding: 5px 25px;
}

.gm-clearfix {
  clear: both;
}

.esri-collapse__icon:before {
  padding: 0 !important;
}

.gm-first-row-header {
  padding-bottom: 20px;
}

.gm-first-row-header2 {
  padding-bottom: 18px;
}

.gm-first-row-header3 {
  padding-bottom: 35px;
}

.gm-rounded {
  border-radius: 50%;
}

#searchInputWrap {
  position: relative;
}

.gm-side-panel-content {
  margin: 0 0 0 20px;
  padding-right: 10px;
}

@media screen and (max-width: 699px) {
  .gm-side-panel-content {
    box-sizing: border-box;
    width: 100%;
    margin: 0;
    padding: 0 10px;
  }
}

.gm-fed-list-elem {
  flex-direction: column;
  width: 100%;
  margin-top: 0;
  list-style: none;
  display: flex;
}

.gm-fed-list-elem-content {
  flex-direction: row;
  margin-bottom: 5px;
  display: flex;
}

.gm-fed-list-elem-img {
  border-radius: 50%;
  width: 75px;
  height: 75px;
  margin-left: 10px;
  margin-right: 15px;
}

.ReactCollapse--collapse {
  transition: height .5s;
}

.gm-overflow {
  overflow: hidden;
}

.esri-legend__layer-caption {
  display: none !important;
}

.dropdown-link:focus {
  outline-style: none;
  border-left: 0 !important;
}

.gm-dropdown-layer-on, .gm-dropdown-layer-off {
  flex-direction: row;
  display: flex;
}

.gm-map-error {
  color: #fff;
  background-color: #7a003c;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 75%;
  padding: 1.5rem 1rem;
  display: flex;
}

.esri-view-height-xsmall .esri-expand .esri-widget--panel {
  max-height: 100%;
}



/*# sourceMappingURL=index.6c568950.css.map */
